<template>
    <ProfileCard title="Расчеты" showLinkAnyway @linkClicked="showSelectClients=true">
      <div>

      </div>
      <SelectClients v-model="showSelectClients" :client="client" @ok="showResults" />

      <ShowProfgramsResult v-model="showCalcComplianceDialog" :clients="clientsForCalc" />

    </ProfileCard>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SelectClients from '@/components/admins/profile/SelectClients';
import ShowProfgramsResult from '@/components/candidates/calc/ShowProfgramsResult';

export default {
  name: 'ProfileAdminCalc',
  components: {
    SelectClients, ShowProfgramsResult
  },
  props: {
  },
  inject: {
    isProfile: {
      default: false
    }
  },
  data: () => ({
    showSelectClients: false,
    showCalcComplianceDialog: false,
    clientsForCalc: [],
  }),
  computed: {
    ...mapGetters({
      client: 'users/clients/client',
    }),
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
    showResults(clients) {
      this.clientsForCalc = clients;
      this.showCalcComplianceDialog = true;
    }
  },
};
</script>

<style lang="scss" scored>
@import "src/assets/styles/variables";

</style>

<template>
  <ProfileCard
    title="Промокоды для реферальной программы"
    :link="isProfile ? '/promocodes' : `/${userType==='CorpClient'?'clients':'partners'}/${$route.params.id}/promocodes`"
  >
      <div v-if="!codes.length">
        Список промокодов пуст
      </div>
      <div v-else class="mb-4">
        <template v-if="userType==='CorpClient'">
          <div class="codes-row">
            <div class="code-title"></div>
            <div class="code-date">Бесплатные тесты</div>
          </div>
          <div v-for="code in codes" :key="code.id" class="codes-row">
            <div class="code-title">{{code.title}}</div>
            <div class="code-date">{{code.tests_for_users}}</div>
          </div>
        </template>
        <template v-else>
          <div class="codes-row">
            <div class="code-title"></div>
            <div class="code-count">Бонусные тесты для корп клиентов</div>
            <div class="code-count">Бонусные тесты для рефералов</div>
          </div>
          <div v-for="code in codes" :key="code.id" class="codes-row">
            <div class="code-title">{{code.title}}</div>
            <div class="code-count">{{code.tests_for_distributors}}</div>
            <div class="code-count">{{code.tests_for_users}}</div>
          </div>
        </template>
      </div>
  </ProfileCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ClientProfileCardCodesUsed',
  components: {
  },
  props: {
  },
  data: () => ({
  }),
  inject: {
    userType: {
      default: ''
    },
    userId: {
      default: 0
    },
    isProfile: {
      default: false
    }
  },
  computed: {
    ...mapGetters({
      codesUsed: 'profile/refs/codesUsed',
      loading: 'profile/refs/loading',
      // user: 'auth/user'
    }),
    codes() {
      return this.codesUsed.slice(0, 3);
    }
  },
  methods: {
    ...mapActions({
      loadCodes: 'profile/refs/loadCodesUsed'
    }),
  },
  async created() {
    if (!this.userType || !this.userId) {
      console.error('userType, userId must be provided');
    }
    await this.loadCodes({ user_type: this.userType, id: this.userId });
  },
};
</script>

<style lang="scss" scored>
@import "src/assets/styles/variables";

.codes-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  border-bottom: #ccc 1px solid;
}

.codes-row:last-child {
  border-bottom: none;
}

.code-title {
  width: 50%;
  padding: 0 5px;
  // margin-right: 10px;
}

.code-count {
  width: 25%;
  padding: 0 5px;
}

</style>

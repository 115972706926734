import api from '@/common/api';
import { cloneDeep } from 'lodash';

/* eslint-disable */

function convert2front(p, state) {
  const allScales = Object.entries(state.scales).map((s) => ({ id: s[0], name: s[1], peak: 0, down: 0, rank: 0, limit: 0, }));
  const allScales2 = Object.entries(state.scales).map((s) => ({ id: s[0], name: s[1], from_value: 0, to_value: 0, norm_min: 0, norm_max: 0, decreasing_value: 0}));

  const allInterests = state.interests.map((s) => ({ id: s.scale.number, name: s.name, peak: 0, rank: 0 }));

  allScales.forEach(scale => {
    let s = p.limitations?.find(el => el.scale == scale.id)
    if (s)
      scale.limit = true

    s = p.peak_scales?.find(el => el.scale == scale.id)
    if (s) {
      scale.peak = true
      scale.down = s.is_peak_down
      scale.rank = s.rank
    }

  })

  const allLogicScales = state.logic_scales.map((s) => ({ id: s[0], name: s[0], min_value: 0, max_value: 0, }));
  allLogicScales.forEach(scale => {
    let s = p.logic_scales?.find(el => el.scale == scale.id)
    if (s) {
      scale.min_value = s.min_value
      scale.max_value = s.max_value
    }
  })

  allInterests.forEach(interest => {
    let s = p.peak_interest_scales?.find(el => el.scale == interest.id)
    if (s) {
      interest.peak = true
      interest.rank = s.rank
    }

  })

  allScales2.forEach(scale => {
    let s = p.scales_limits?.find(el => el.scale == scale.id)
    if (s) {
      scale.from_value = s.from_value;
      scale.to_value = s.to_value;
      scale.norm_min = s.norm_min;
      scale.norm_max = s.norm_max;
      scale.decreasing_value = s.decreasing_value;
    }
  })

  return {
    ...p,
    chapters: p.chapters ? p.chapters.map((ch) => ch.id) : [],
    competences: p.competences ? p.competences.map((c) => c.id) : [],

    interests: allInterests, // p.interests ? p.interests.map((i) => i.id) : [],
    scales: allScales,
    logic_scales: allLogicScales,

    scales2: allScales2,
  };
}

function convert2back(p) {
  const limitations = p.scales.map(s => ({ scale: s.limit ? s.id : ''})).filter(s => s.scale);

  // "peak_scales": [
  //   {
  //     "scale": "L",
  //     "rank": 32767,
  //     "is_peak_down": true
  //   }

  const peak_scales = p.scales.map(s => ({
    scale: s.peak ? s.id : '',
    is_peak_down: s.down,
    rank: s.rank
  })).filter(s => s.scale);

  // {
  //   "scale": "NUMERIC_LOGIC",
  //   "min_value": 32767,
  //   "max_value": 100
  // }
  const logic_scales = p.logic_scales.map(s => ({
    scale: s.max_value||s.min_value  ? s.id : '',
    max_value: s.max_value ?? 0,
    min_value: s.min_value ?? 0
  })).filter(s => s.scale);

  const peak_interest_scales = p.interests.map(s => ({
    scale: s.peak ? s.id : '',
    rank: s.rank
  })).filter(s => s.scale);

  const {id, name, owner, chapters, competences, cost, mmil_weight, interests_weight, logic_weight, interests_number } = p

// "scales_limits": [
// {
// "scale": "D",
// "from_value": 50,
// "to_value": 70,
// "norm_min":45,
// "norm_max": 75,
// "decreasing_value": 10
// }
// ],
  const scales_limits = p.scales2.map(s => ({
    scale: s.from_value || s.to_value || s.norm_min || s.norm_max || s.decreasing_value ? s.id : '',
    from_value: s.from_value,
    to_value: s.to_value,
    norm_min: s.norm_min,
    norm_max: s.norm_max,
    decreasing_value: s.decreasing_value
})).filter(s => s.scale);

  return {
    id, owner, name, chapters, competences, cost, mmil_weight, interests_weight, logic_weight, interests_number,

    limitations,
    peak_scales,
    logic_scales,
    peak_interest_scales,
    scales_limits
  };
}

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    delete: false,
  },
  errors: {
    load: [],
    create: [],
    delete: [],
  },
  count: 0,
  items: [],
  // selected: [],
  scales: [],
  logic_scales: [],
  interests: [],
  editedItem: {
    id: 0,
    name: '',
    // logic_verbal: { min_value: 0, max_value: 0 },
    // logic_digit: { min_value: 0, max_value: 0 },
    // logic_nonverbal: { min_value: 0, max_value: 0 },
    chapters: [],
    interests: [],
    competences: [],
    cost: 0,
    mmil_weight: 0,
    interests_weight: 0,
    logic_weight: 0,
    interests_number: 0,
    owner: 0,
  },
  filter: {
    page: 1,
    name: '',
  },

});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    profs: (state) => state.items,
    scales: (state) => state.scales,
    interests: (state) => state.interests,
    logic_scales: (state) => state.logic_scales,
    chapterProfs: (state) => (chapterId) => state.items.filter((el) => el.chapters.includes(chapterId)),
    // selected: (state) => state.selected,
    editedItem: (state) => state.editedItem,
    filter: (state) => state.filter,
  },
  mutations: {
    loadRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadSuccess(state) {
      state.loading.load = false;
    },
    loadFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    createRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createSuccess(state) {
      state.loading.create = false;
    },
    createFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    deleteRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deleteSuccess(state) {
      state.loading.delete = false;
    },
    deleteFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },
    setCount(state, count) {
      state.count = count;
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setItems(state, items) {
      state.items = items;
    },
    setScales(state, { scales, logic_scales }) {
      state.scales = scales;
      state.logic_scales = logic_scales;
    },
    setInterests(state, interests) {
      state.interests = interests;
    },
    add(state, item) {
      state.items.push(item);
    },
    save(state, item) {
      const ind = state.items.findIndex((el) => el.id === item.id);
      if (ind >= 0) state.items.splice(ind, 1, item);
    },
    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },
    setFilterName(state, name) {
      state.filter.name = name;
    },
    setEditedItem(state, defaultItem) {
      state.editedItem = cloneDeep({ ...initialState().editedItem, ...defaultItem });
    },
    createEditedItem(state, defaultItem) {
      state.editedItem = convert2front(defaultItem, state);
    },
    deleteItem(state, id) {
      const ind = state.items.findIndex((el) => el.id === id);
      if (ind >= 0) state.items.splice(ind, 1);
    },
  },
  actions: {
    async load({ commit, state }) {
      commit('loadRequest');
      // try {
        let responseInterests = await api.get('/professiogram/interest/');
        responseInterests.sort((a, b) => a.name > b.name )
        commit('setInterests', responseInterests);

        let responseScales = await api.get('/professiogram/get-scales/');
        let responseScalesNames = await api.get('/professiogram/get-scales-names/');
        for (const [key, value] of Object.entries(responseScalesNames) )
          responseScalesNames[key] = value.replace('/', ' / ')
        commit('setScales', { scales: responseScalesNames, logic_scales: responseScales.logic_scales } );

        let response = await api.get('/professiogram/all/');
        commit('setItems', response.map((el) => convert2front(el, state)));
        commit('setCount', response.length);
        commit('loadSuccess');
        // } catch (err) {
        // commit('loadFailure', Object.entries(err.data));
      // }
    },
    async save({ commit, rootGetters, state, dispatch }, item) {
      commit('createRequest');
      // const chapters = rootGetters['profgrams/chapters/items'];
      item = convert2back(item);
      try {
        if (item.id) {
          await api.put(`/professiogram/update/${item.id}`, item);
          item = convert2front(item, state);
          commit('save', item);
        } else {
          let ret = await api.post('/professiogram/add/', item);
          ret = convert2front(ret, state);
          commit('add', ret);
        }
        commit('createSuccess');
        dispatch('load');
        return true

      } catch (err) {
        commit('createFailure', Object.entries(err.data));
        return false
      }
    },
    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('load');
    },
    async setFilterName({ commit, dispatch }, name) {
      commit('setFilterName', name);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterAll({ commit, dispatch }, params) {
      params = params || {};
      commit('setFilterPage', parseInt(params.page, 10) || 1);
      commit('setFilterName', params.name ?? '');
      await dispatch('load');
    },

    clearError({ commit }) {
      commit('clearError');
    },
    async delete({ commit }, ids) {
      commit('deleteRequest');
      try {
        const promises = ids.map((id) => api.delete(`/professiogram/delete/${id}`));
        await Promise.all(promises);
        for (const id of ids) {
          commit('deleteItem', id);
        }

        commit('deleteSuccess');
      } catch (err) {
        commit('deleteFailure', Object.entries(err.data));
      }
    },
  },
};

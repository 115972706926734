<template>
  <div>
    <div v-if="$store.getters['auth/isAllowed']('view_fullreport') && results" class="report-item">
      <div class="font-weight-600">
        <a :href="isProfile ? '/reports/main' : `/candidates/${candidate.id}/reports/main`">Полный отчет</a>
      </div>
      <div class="d-flex">
        <PdfButton v-if="$store.getters['auth/isAllowed']('download_fullreport')"
          :loading="loading.pdf.index"  @downloadPdf="loadPdfStd"/>
        <v-btn fab class="ml-4" small text :to="isProfile ? '/reports/main' : `/candidates/${candidate.id}/reports/main`">
          <v-icon large>mdi-arrow-top-right</v-icon>
        </v-btn>

      </div>
    </div>
    <div v-if="$store.getters['auth/isAllowed']('view_shortreport')" class="report-item">
      <div class="font-weight-600">
        <a :href="isProfile ? '/reports/short' : `/candidates/${candidate.id}/reports/short`">Краткий отчет</a>
      </div>
      <div class="d-flex">
        <PdfButton v-if="$store.getters['auth/isAllowed']('download_shortreport')"
            :loading="loading.pdf.short" class="load-pdf" @downloadPdf="loadPdfShort($event)"
        />
        <v-btn fab class="ml-4" small text :to="isProfile ? '/reports/short' : `/candidates/${candidate.id}/reports/short`">
          <v-icon large>mdi-arrow-top-right</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="psExists && $store.getters['auth/isAllowed']('view_psychotypereport')" class="report-item">
      <div class="font-weight-600">
        <a :href="isProfile ? '/reports/psycho' : `/candidates/${candidate.id}/reports/psycho`">Отчет респондента</a>
      </div>
      <div class="d-flex">
        <PdfButton v-if="$store.getters['auth/isAllowed']('download_psychotypereport')"
            :loading="loading.pdf.psycho" class="load-pdf" @downloadPdf="loadPdfPsycho($event)"
        />
        <v-btn fab class="ml-4" small text :to="isProfile ? '/reports/psycho' : `/candidates/${candidate.id}/reports/psycho`">
          <v-icon large>mdi-arrow-top-right</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="$store.getters['auth/isAllowed']('view_recommendations')" class="report-item">
      <div class="font-weight-600">
        <a :href="isProfile ? '/reports/reco' : `/candidates/${candidate.id}/reports/reco`">Отчет с рекомендациями</a>
      </div>
      <div class="d-flex">
        <PdfButton v-if="$store.getters['auth/isAllowed']('download_recommendations')"
            :loading="loading.pdf.reco" class="load-pdf" @downloadPdf="loadPdfReco($event)"
        />
        <v-btn fab class="ml-4" small text :to="isProfile ? '/reports/reco' : `/candidates/${candidate.id}/reports/reco`">
          <v-icon large>mdi-arrow-top-right</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="$store.getters['auth/isAllowed']('view_riskfactor')" class="report-item">
      <div class="font-weight-600">
        <a :href="isProfile ? '/reports/checklist' : `/candidates/${candidate.id}/reports/checklist`">Поведенческие риски</a>
      </div>
      <div class="d-flex">
        <PdfButton v-if="$store.getters['auth/isAllowed']('download_riskfactor')"
            :loading="loading.pdf.checklist" class="load-pdf" @downloadPdf="loadPdfCheckList($event)"
        />
        <v-btn fab class="ml-4" small text :to="isProfile ? '/reports/checklist' : `/candidates/${candidate.id}/reports/checklist`">
          <v-icon large>mdi-arrow-top-right</v-icon>
        </v-btn>
      </div>
    </div>

    <div v-if="$store.getters['auth/isAllowed']('view_constructorreport') && results.constructor_test_results" class="report-item">
      <div class="font-weight-600">
        <a :href="isProfile ? '/reports/constructor' : `/candidates/${candidate.id}/reports/constructor`">Профессиональный навигатор</a>
      </div>
      <div class="d-flex">
        <PdfButton v-if="$store.getters['auth/isAllowed']('download_constructorreport')"
          :loading="loading.pdf.constructor"  @downloadPdf="loadPdfConstructor($event)"/>
        <v-btn fab class="ml-4" small text :to="isProfile ? '/reports/constructor' : `/candidates/${candidate.id}/reports/constructor`">
          <v-icon large>mdi-arrow-top-right</v-icon>
        </v-btn>

      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PdfButton from '@/components/ui/PdfButton';

export default {
  name: 'ProfileData',
  components: {
    PdfButton
  },
  props: {
    user: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
  }),
  inject: {
    isProfile: {
      default: false
    }
  },
  computed: {
    ...mapGetters({
      candidate: 'users/candidate/candidate',
      loading: 'users/candidate/loading',
      results: 'users/candidate/results',
      ps_current: 'users/candidate/resultsPsycho',
      ps_types: 'users/candidate/psychoTypes',
    }),
    isProfile() {
      return this.$route.name === 'profile';
    },
    psExists() {
      return this.ps_current?.main;
    },
    psMain() {
      return this.ps_current?.main ? this.ps_types.find((el) => el.code === this.ps_current.main[0]) : null;
    },

  },
  methods: {
    ...mapActions({
      loadCandidatePdf: 'users/candidate/loadCandidatePdf',
      loadCandidatePsychoPdf: 'users/candidate/loadCandidatePsychoPdf',
      loadCandidateRecomendationsPdf: 'users/candidate/loadCandidateRecomendationsPdf',
      loadCandidateCheckListPdf: 'users/candidate/loadCandidateCheckListPdf',
    }),
    ...mapMutations({
    }),
    downloadFile(fileLink) {
      const link = document.createElement('a');
      link.setAttribute('download', true);
      link.setAttribute('href', fileLink);
      link.setAttribute('target', '_blank');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async loadPdfStd(logo) {
      const resp = await this.loadCandidatePdf({ candidate: this.candidate.id, report_type: 'index', logo });
      console.log(resp);
      if (resp.link) {
        this.downloadFile(resp.link);
      }
    },
    async loadPdfShort(logo) {
      const resp = await this.loadCandidatePdf({ candidate: this.candidate.id, report_type: 'short', logo });
      console.log(resp);
      if (resp.link) {
        this.downloadFile(resp.link);
      }
    },
    async loadPdfConstructor(logo) {
      const resp = await this.loadCandidatePdf({ candidate: this.candidate.id, report_type: 'constructor', logo });
      console.log(resp);
      if (resp.link) {
        this.downloadFile(resp.link);
      }
    },
    async loadPdfPsycho(logo) {
      const resp = await this.loadCandidatePsychoPdf({ candidate: this.candidate.id, ps_code: this.psMain.code, logo });
      if (resp.link) {
        this.downloadFile(resp.link);
      }
    },
    async loadPdfReco(logo) {
      const data = {
        candidate: this.candidate.id,
        report_type: 'original', // editable
        logo,
        target: 1
      };
      const resp = await this.loadCandidateRecomendationsPdf(data);
      if (resp.link) {
        this.downloadFile(resp.link);
      }
    },
    async loadPdfCheckList(logo) {
      const data = {
        candidate: this.candidate.id,
        report_type: 'original', // editable
        target: 1,
        logo
      };
      const resp = await this.loadCandidateCheckListPdf(data);
      if (resp.link) {
        this.downloadFile(resp.link);
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/styles/variables";

.report-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 4px 10px 10px 4px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 2px 0px 4px rgba(0, 0, 0, 0.05), -2px 0px 2px rgba(3, 3, 15, 0.05);
  border-radius: 4px;
}

</style>

<template>
  <div class="auth-card text-center" style="width:800px">
    <h2 class="auth-card__title mb-8">
      К сожалению, произошла ошибка:
        {{ $store.getters['testing/testing/errors'] && $store.getters['testing/testing/errors'].length && $store.getters['testing/testing/errors'][0].length==2 ?
           $store.getters['testing/testing/errors'][0][1] : ' возможно, неверная ссылка' }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'Error',
};
</script>

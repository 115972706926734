import api from '@/common/api';

const initialState = () => ({
  loading: {
    load: false,
    create: false,
  },
  errors: {
    load: [],
    create: [],
  },
  count: 0,
  items: [],
  selected: [],
  // editedChapter: null,
  filter: {
    page: 1,
    name: '',
  },
});

// const mocked = [
//   {
//     id: 1,
//     name: 'Коммуникативность',
//     desc: 'Способность формулировать высказывания таким образом, что весь вкладываемый смысл полностью понимается собеседником.',
//   },
//   {
//     id: 2,
//     name: 'Решительность',
//     desc: ' индивидуальное качество воли человека, связанное со способностью и умением (навыком) самостоятельно и своевременно ',
//   },
//   {
//     id: 3,
//     name: 'Эмпатия',
//     desc: 'осознанное сопереживание текущему эмоциональному состоянию другого человека без потери ощущения происхождения .',
//   },
//   {
//     id: 4,
//     name: 'Смелость отстаивать собстивенную позицию',
//     desc: 'осознанное сопереживание текущему эмоциональному состоянию другого человека без потери ощущения происхождения  .',
//   },
//   {
//     id: 5,
//     name: 'Эмпатия 3',
//     desc: 'осознанное сопереживание текущему эмоциональному состоянию другого человека без потери ощущения происхождения .',
//   },
// ];

export default {
  namespaced: true,
  state: initialState,
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    filter: (state) => state.filter,
    items: (state) => state.items,
    itemsChunk: (state) => (chunk, chunks = 3) => {
      let i = 0;
      const len = Math.ceil(state.items.length / chunks);
      while (i < chunks) {
        if (i !== chunk) i += 1;
        else return state.items.slice(i * len, (i + 1) * len);
      }
    },
    selected: (state) => state.selected,
    // editedManager: (state) => state.editedManager,
  },
  mutations: {
    loadRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadSuccess(state) {
      state.loading.load = false;
    },
    loadFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },
    createRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createSuccess(state) {
      state.loading.create = false;
    },
    createFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },
    setCount(state, count) {
      state.count = count;
    },
    clearSelected(state) {
      state.selected = [];
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setItems(state, items) {
      state.items = items;
    },
    add(state, item) {
      state.items.push(item);
    },
    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },
    setFilterName(state, name) {
      state.filter.name = name;
    },

    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.managers.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    // setEditedManager(state, manager) {
    //   state.editedManager = manager;
    // },
  },
  actions: {
    async load({ commit }) {
      commit('loadRequest');
      try {
        const response = await api.get('/professiogram/competence/');
        commit('setItems', response);
        commit('setCount', response.length);
        commit('loadSuccess');
      } catch (err) {
        commit('loadFailure', Object.entries(err.data));
      }
    },
    async create({ state, commit /* ,dispatch */ }, data) {
      commit('createRequest');
      try {
        // await api.post('/users/managers/', data);
        const chapter = { id: state.items.length + 1, ...data };
        commit('add', chapter);
        commit('createSuccess');
        return chapter;
        // if (getters.filter.page === 1) {
        //   dispatch('load');
        // }
      } catch (err) {
        commit('createFailure', Object.entries(err.data));
      }
    },
    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('load');
    },
    async setFilterName({ commit, dispatch }, name) {
      commit('setFilterName', name);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterAll({ commit, dispatch }, params) {
      params = params || {};
      commit('setFilterPage', parseInt(params.page, 10) || 1);
      commit('setFilterName', params.name ?? '');
      await dispatch('load');
    },

    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
  },
};

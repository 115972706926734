import api from '@/common/api';

/* eslint-disable */

const initialState = () => ({
  loading: {
    load: false,
    save: false,
    search: false,
    delete: false,
  },
  errors: {
    load: [],
    create: [],
    edit: [],
  },
  editedLink: null,
  links: [],
  tests: [],
  dialogEditShow: false,
  user_id: 0,
  user_type: 0,
});

const newLink = {
  id: 0,
  name: "Новая ссылка",
  manager: 0,
  can_view_report: 0,
  tests: [],
}


export default {
  namespaced: true,
  state: initialState,
  getters: {
    loading: (state) => state.loading,
    links: (state) => state.links,
    editedLink: (state) => state.editedLink,
    dialogEditShow: (state) => state.dialogEditShow,
    errors: (state) => state.errors,
    tests: (state) => state.tests,
  },
  mutations: {
    setLinks(state, links) {
      state.links = links;
    },
    setTests(state, links) {
        state.tests = links;
      },
      startLoading(state, field) {
      state.loading[field] = true;
    },
    endLoading(state, field) {
      state.loading[field] = false;
    },
    setEditedLink(state, link) {
      if (link) {
        state.editedLink = { ...link };
      } else {
        state.editedLink = { ...newLink };
      }
      state.errors.edit = [];
    },
    setDialogEditShow(state, bool) {
      state.dialogEditShow = bool;
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setError(state, {type, errors}) {
      state.errors[type] = errors;
    },
    setUser(state, {user_id, user_type}) {
      state.user_id = user_id;
      state.user_type = user_type;
    },

  },
  actions: {
    async loadLinks({ commit, state, getters, rootGetters }, {id, user_type}) {
      commit('setLinks', []);
      commit('startLoading', 'load');
    //   commit('setUser', {user_id: id, user_type} );
      try {
        const tests = await api.get('/stock/nomenclatures/');
        let links = await api.get('/referrals/links/');
        links.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) ) 


        commit('setLinks', links);
        commit('setTests', tests);
      } catch (err) {
      }
      commit('endLoading', 'load');
    },

    async saveLink({ commit, state, dispatch }, link) {
      commit('startLoading', 'save');
      commit('setError', { type: 'edit', errors: [] });

      try {
        let response;
        if (link.id) {
          response = await api.patch(`/referrals/links/${link.id}/`, link)
        } else {
          response = await api.post(`/referrals/links/`, link)
        }
        dispatch('loadLinks', {id: state.user_id, user_type: state.user_type});
        //commit('setCodesUsed', codes);
        return response;
      } catch (err) {
        console.log(err.data);
        commit('setError', { type: 'edit', errors: err.data });
      } finally {
        commit('endLoading', 'save');
      }
    },

    async deleteLink({ commit, dispatch, state }, link) {
        commit('startLoading', 'delete');
        try {
          await api.delete(`/referrals/links/${link.id}/`);
          dispatch('loadLinks', {id: state.user_id, user_type:state.user_type});
        } catch (err) {
        }
        commit('endLoading', 'delete');
    },

  },

};

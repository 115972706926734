import api from '@/common/api';
import Vue from 'vue';
import { cloneDeep } from 'lodash';
/* eslint-disable */

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    edit: false,
    delete: false,
    loadResults: false,
    active: false,
  },
  progress: {
    cur: 0,
    total: 0,
    active: false,
    blockSize: 1,
  },
  stopExport: false,

  errors: {
    load: [],
    create: [],
    delete: [],
    edit: [],
    loadResults: [],
    active: false,
  },
  count: 0,
  candidates: [],
  selected: [],
  filter: {
    pageSize: 20,
    page: 1,
    name: '',
    email: '',
    position: '',
    manager: '',
    company: '',
    testing_status: [],
    is_active: '',
    /**
     * @type {null | string}
     * */
    from_date_joined: null,
    /**
     * @type {null | string}
     * */
    to_date_joined: null,
    comment: null,
  },
  editedCandidate: null,
  dialogCreateShow: false,
  dialogEditShow: false,
  dialogDeleteShow: false,
  stockBalance: null,
});

export default {
  namespaced: true,
  state: cloneDeep(initialState()),
  actions: {
    async loadCandidates({ commit, getters }) {
      commit('loadCandidatesRequest');
      try {
        /**
         *
         * Проверяю если значение фильтра пустое, то не добавляю его в параметры запроса,
         * иначе валится бэк
         */
        const filter = {};
        Object.keys(getters.filter).forEach((key) => {
          if (key === 'page') return;
          if (Array.isArray(getters.filter[key])) {
            if (getters.filter[key].length) {
              filter[key] = getters.filter[key];
            }
            return;
          }
          if (getters.filter[key]) {
            filter[key] = getters.filter[key];
          }
        });
        const response = await api.post(
          `/users/candidates/filter/?page=${getters.filter.page}&page_size=${getters.filter.pageSize}`,
          filter,
        );
        commit('setCandidates', response.results);
        commit('setCount', response.count);
        commit('setStockBalance', response.stock_balance);
        commit('loadCandidatesSuccess');
      } catch (err) {
        commit('loadCandidatesFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadAllCandidates({ commit, getters }) {
      commit('loadCandidatesRequest');
      try {
        const response = await api.post(
          `/users/candidates/filter/?page=1&page_size=10000`,
          {},
        );
        commit('setCandidates', response.results);
        commit('setCount', response.count);
        commit('setStockBalance', response.stock_balance);
        commit('loadCandidatesSuccess');
      } catch (err) {
        commit('loadCandidatesFailure', Object.entries(err.data));
        throw err;
      }
    },
    async createCandidate({ commit, dispatch, getters }, data) {
      commit('createCandidateRequest');
      try {
        await api.post('/users/candidates/', data);
        commit('createCandidateSuccess');
        if (getters.filter.page === 1) {
          dispatch('loadCandidates');
        }
      } catch (err) {
        commit('createCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async saveCandidate({ commit, dispatch, getters }, data) {
      commit('editCandidateRequest');
      try {
        await api.patch(`/users/candidates/${data.id}/`, data);
        commit('editCandidateSuccess');
        if (getters.filter.page === 1) {
          dispatch('loadCandidates');
        }
        dispatch('users/candidate/loadCandidate', data.id, { root: true });
      } catch (err) {
        commit('editCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async createCandidateByRef({ commit }, data) {
      commit('createCandidateRequest');
      try {
        const response = await api.post('/referrals/create-user/', data);
        commit('createCandidateSuccess');
        return response;
      } catch (err) {
        commit('createCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('loadCandidates');
    },
    async setFilterPageSize({ commit, dispatch }, payload) {
      commit('setFilterPage', payload.page);
      commit('setFilterPageSize', payload.pageSize);
      await dispatch('loadCandidates');
    },
    async setFilterName({ commit, dispatch }, name) {
      commit('setFilterName', name);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterEmail({ commit, dispatch }, email) {
      commit('setFilterEmail', email);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    /**
     * @param {any} context
     * @param {string[]} dates
     * */
    async setFilterJoinedDatesRange({ commit, dispatch }, dates) {
      commit('setFilterJoinedDatesRange', dates);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterPosition({ commit, dispatch }, position) {
      commit('setFilterPosition', position);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterActive({ commit, dispatch }, active) {
      commit('setFilterActive', active);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterComment({ commit, dispatch }, comment) {
      commit('setFilterComment', comment);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterCompany({ commit, dispatch }, payload) {
      commit('setFilterCompany', payload);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterManager({ commit, dispatch }, payload) {
      commit('setFilterManager', payload);
      commit('setFilterPage', 1);
      await dispatch('loadCandidates');
    },
    async setFilterTestingStatus({ commit }, statuses) {
      commit('setFilterPage', 1);
      commit('setFilterTestingStatus', statuses);
    },
    async setFilterAll({ commit, dispatch }, params) {
      commit('setFilterPage', parseInt(params.page, 10) || 1);
      commit('setFilterName', params.name ?? '');
      commit('setFilterEmail', params.email ?? '');
      commit('setFilterPosition', params.position ?? '');
      commit('setFilterActive', params.is_active ?? '');
      commit('setFilterCompany', params.company ?? '');
      commit('setFilterManager', params.manager ?? '');
      commit('setFilterComment', params.comment ?? '');
      commit('setFilterTestingStatus', params.testing_status && params.testing_status.length ? params.testing_status.split(',') : []);
      await dispatch('loadCandidates');
    },
    async clearFilterAll({ commit, dispatch }) {
      commit('setFilterName', '');
      commit('setFilterEmail', '');
      commit('setFilterPosition', '');
      commit('setFilterActive', '');
      commit('setFilterCompany', '');
      commit('setFilterTestingStatus', []);
      commit('setFilterManager', '');
      commit('setFilterComment', '');
      commit('setFilterJoinedDatesRange', [null, null]);
      await dispatch('loadCandidates');
    },

    async editCandidateComment({ commit }, { id, text }) {
      commit('editCandidateCommentRequest', id);
      try {
        const response = await api.patch(`/users/candidates/${id}/`, { comment: text });
        commit('editCandidateCommentSuccess', response);
      } catch (err) {
        commit('editCandidateCommentFailure', { id, error: Object.entries(err.data) });
        throw err;
      }
    },
    async editCandidatePermission({ commit }, { candidate, value }) {
      const arrPerm = [ //'view_fullreport' , 'view_shortreport',
        'view_testresultscompliencereport', 'view_testresultsrisks',  'view_testresultsscales', 'view_testresultsreliability',
        'view_testresultsbehaviour',         'view_testresultscompetencies',  'view_testresultsstressresistance', 'view_testresultsmotivatorsanddestructors',
        'view_testresultsteamroles',  'view_testresultsmanagementstyle',  'view_testresultslogic', 'view_testresultsinterestsmap' ];

      console.log(candidate, value);
      try {
        const response = await api.patch(`/users/candidates/${candidate.id}/`, { permissions: value ? arrPerm : [] });
        commit('editCandidatePermissionSuccess', response);
        return response;
      } catch (err) {
        // throw err;
        return false;
      }
    },

    async editCandidatePosition({ commit }, { id, text }) {
      commit('editCandidatePositionRequest', id);
      try {
        const response = await api.patch(`/users/candidates/${id}/`, { position: text });
        commit('editCandidatePositionSuccess', response);
      } catch (err) {
        commit('editCandidatePositionFailure', { id, error: Object.entries(err.data) });
        throw err;
      }
    },

    setCandidateTestingStatus({ commit }, { id, data }) {
      commit('setCandidateTestingStatus', { id, data });
    },

    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
    setDialogCreateShow({ commit }, bool) {
      if (!bool) commit('setEditedCandidate', null);
      commit('setDialogCreateShow', bool);
    },
    setDialogEditShow({ commit }, bool) {
      if (!bool) commit('setEditedCandidate', null);
      commit('setDialogEditShow', bool);
    },
    setDialogDeleteShow({ commit }, bool) {
      if (!bool) commit('setEditedCandidate', null);
      commit('setDialogDeleteShow', bool);
    },
    deleteCandidateDialog({ commit }, candidate) {
      commit('setEditedCandidate', { ...candidate });
      commit('setDialogDeleteShow', true);
    },

    async deleteCandidates({ getters, commit, dispatch }, candidates) {
      if (!(getters.selected.length || candidates.length)) return;
      commit('deleteCandidatesRequest');
      try {
        const data = {
          candidates: getters.selected,
        };
        if (candidates) {
          data.candidates = candidates;
        }
        await api.delete('/users/candidates/bulk-delete/', { data });
        commit('clearSelected');
        dispatch('loadCandidates');
        commit('deleteCandidatesSuccess');
      } catch (err) {
        commit('deleteCandidatesFailure', Object.entries(err.data));
        throw err;
      }
    },

    async inactiveCandidates({ getters, commit, dispatch }) {
      if (!getters.selected.length) return;
      commit('activeCandidatesRequest');
      try {
        const candidates = getters.selected;
        // eslint-disable-next-line
        for (let i = 0; i < candidates.length; i++) {
          // eslint-disable-next-line
          await api.patch(`/users/candidates/${candidates[i]}/`, { is_active: false });
        }
        commit('clearSelected');
        dispatch('loadCandidates');
        commit('activeCandidatesSuccess');
      } catch (err) {
        commit('activeCandidatesFailure', Object.entries(err.data));
        throw err;
      }
    },

    async loadCandidatesResults({ commit, getters }) {
      commit('loadCandidatesResultsRequest');
      const data = {
        candidates: getters.selected,
      };
      try {
        const response = await api.post('/users/candidates/get-test-result/', data);
        commit('loadCandidatesResultsSuccess');
        return response;
      } catch (err) {
        commit('loadCandidatesResultsFailure', Object.entries(err.data));
        throw err;
      }
    },

    async loadCandidatesResultsProgress({ commit, state, getters }) {
      commit('loadCandidatesResultsRequest');

      const idsCandidates = getters.selected;
      console.log(idsCandidates);

      const CALC_BLOCK_SIZE = 10;
      commit('startProgress', { total: idsCandidates.length, blockSize: CALC_BLOCK_SIZE });

      let candidates2Load = [];
      let response = [];
      for (let cur = 0; cur < idsCandidates.length; cur += 1) {
        if (state.stopExport) break;

        commit('setProgressCur', cur + 1);
        candidates2Load.push(idsCandidates[cur]);

        if (candidates2Load.length < CALC_BLOCK_SIZE && cur !== idsCandidates.length - 1) {
          continue;
        }

        try {
          const data = {
            candidates: candidates2Load,
          };
          const responseItem = await api.post('/users/candidates/get-test-result/', data);
          response = response.concat(responseItem)
        } catch (err) {
          // commit('setError', {field: 'calc', error: err.data.error});
          console.log(err)
          commit('loadCandidatesResultsFailure', Object.entries(err.data));
          // break;
        }
        candidates2Load = [];
      }
      commit('loadCandidatesResultsSuccess');
      commit('stopProgress');
      // console.log('candidates', response);
      return response;

    },

  },
  mutations: {
    startProgress(state, {total, blockSize}) {
      state.progress.cur = 0;
      state.progress.total = total;
      state.progress.blockSize = blockSize;
      state.progress.active = true;
    },
    stopProgress(state) {
      state.progress.cur = 0;
      state.progress.total = 0;
      state.progress.active = false;
    },
    setProgressCur(state, total) {
      state.progress.cur = total
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setCount(state, count) {
      state.count = count;
    },
    setStockBalance(state, value) {
      state.stockBalance = value;
    },
    setCandidates(state, candidates) {
      state.candidates = candidates.map((c) => ({
        ...c, loading: {}, errors: {}, tests_results: null,
      }));
    },
    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },
    setFilterPageSize(state, pageSize) {
      state.filter.pageSize = parseInt(pageSize, 10);
    },
    setFilterName(state, name) {
      state.filter.name = name;
    },
    /**
     * @param {object} state
     * @param {string[]} dates
     * */
    setFilterJoinedDatesRange(state, dates) {
      // eslint-disable-next-line prefer-destructuring
      state.filter.from_date_joined = dates[0];
      // eslint-disable-next-line prefer-destructuring
      state.filter.to_date_joined = dates[1];
    },
    setFilterEmail(state, email) {
      state.filter.email = email;
    },
    setFilterPosition(state, position) {
      state.filter.position = position;
    },
    setFilterActive(state, active) {
      state.filter.is_active = active;
    },
    setFilterComment(state, comment) {
      state.filter.comment = comment;
    },
    setFilterCompany(state, payload) {
      state.filter.company = payload;
    },
    setFilterManager(state, payload) {
      state.filter.manager = payload;
    },
    setFilterTestingStatus(state, testingStatus) {
      state.filter.testing_status = testingStatus;
    },
    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.candidates.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    clearSelected(state) {
      state.selected = [];
    },
    setEditedCandidate(state, candidate) {
      state.editedCandidate = candidate;
    },
    setDialogCreateShow(state, bool) {
      state.dialogCreateShow = bool;
    },
    setDialogEditShow(state, bool) {
      state.dialogEditShow = bool;
    },
    setDialogDeleteShow(state, bool) {
      state.dialogDeleteShow = bool;
    },

    loadCandidatesRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadCandidatesSuccess(state) {
      state.loading.load = false;
    },
    loadCandidatesFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    createCandidateRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createCandidateSuccess(state) {
      state.loading.create = false;
    },
    createCandidateFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    editCandidateRequest(state) {
      state.loading.edit = true;
      state.errors.edit = [];
    },
    editCandidateSuccess(state) {
      state.loading.edit = false;
    },
    editCandidateFailure(state, error) {
      state.errors.edit = error;
      state.loading.edit = false;
    },

    deleteCandidatesRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deleteCandidatesSuccess(state) {
      state.loading.delete = false;
    },
    deleteCandidatesFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },

    activeCandidatesRequest(state) {
      state.loading.active = true;
      state.errors.active = [];
    },
    activeCandidatesSuccess(state) {
      state.loading.active = false;
    },
    activeCandidatesFailure(state, error) {
      state.errors.active = error;
      state.loading.active = false;
    },

    loadCandidatesResultsRequest(state) {
      state.loading.loadResults = true;
      state.errors.loadResults = [];
    },
    loadCandidatesResultsSuccess(state) {
      state.loading.loadResults = false;
    },
    loadCandidatesResultsFailure(state, error) {
      state.errors.loadResults = error;
      state.loading.loadResults = false;
    },

    editCandidateCommentRequest(state, id) {
      const candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate.errors.editComment = [];
        candidate.loading.editComment = true;
      }
    },
    editCandidateCommentSuccess(state, newCandidate) {
      let candidate = state.candidates.find((c) => c.id === newCandidate.id);
      if (candidate) {
        candidate.loading.editComment = false;
        candidate = Object.assign(candidate, newCandidate);
      }
    },
    editCandidateCommentFailure(state, { id, error }) {
      const candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate.errors.editComment = error;
        candidate.loading.editComment = false;
      }
    },

    editCandidatePositionRequest(state, id) {
      const candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate.errors.editPosition = [];
        Vue.set(candidate, 'loading', { editPosition: true });
      }
    },
    editCandidatePositionSuccess(state, newCandidate) {
      const candidate = state.candidates.find((c) => c.id === newCandidate.id);
      if (candidate) {
        candidate.loading.editPosition = false;
        candidate.position = newCandidate.position;
      }
    },
    editCandidatePermissionSuccess(state, newCandidate) {
      const candidate = state.candidates.find((c) => c.id === newCandidate.id);
      if (candidate) {
        candidate.permissions = newCandidate.permissions ?? [];
      }
    },
    editCandidatePositionFailure(state, { id, error }) {
      const candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate.errors.editPosition = error;
        candidate.loading.editPosition = false;
      }
    },

    setCandidateTestingStatus(state, { id, data }) {
      let candidate = state.candidates.find((c) => c.id === id);
      if (candidate) {
        candidate = Object.assign(candidate, data);
      }
    },
    reset(state) {
      Object.assign(state, cloneDeep(initialState()));
    }
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    filter: (state) => state.filter,
    candidates: (state) => state.candidates,
    progress: (state) => state.progress,
    selected: (state) => state.selected,
    editedCandidate: (state) => state.editedCandidate,
    dialogCreateShow: (state) => state.dialogCreateShow,
    dialogEditShow: (state) => state.dialogEditShow,
    dialogDeleteShow: (state) => state.dialogDeleteShow,
    isFilterEmpty: (state) => !state.filter.name && !state.filter.email && !state.filter.position
      && !state.filter.manager && !state.filter.company && state.filter.is_active === '' && !state.filter.from_date_joined
      && !state.filter.to_date_joined && !state.filter.comment && (!state.filter.testing_status || state.filter.testing_status.length === 0),
    stockBalance: (state) => state.stockBalance,
  },
};

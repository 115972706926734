<template>
  <div></div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    // window.location = '/welcome/';
  },
};
</script>

<template>
  <v-dialog v-model="dialogShowComputed" max-width="600px">
    <v-card class="pa-8 pa-sm-16">
      <h2>Укажите количество лицензий</h2>
      <div class="mt-6">
        <v-text-field label="" v-model="amount" @input="onChanged()" :error-messages="error" outlined />
      </div>
      <div class="row flex-sm-row-reverse mt-2">
        <div class="col-sm-6 text-center">
          <v-btn @click="onAdd()" class="px-16" color="primary" :loading="loading.add" :disabled="!changed">Начислить</v-btn>
        </div>
        <div class="col-sm-6 pt-0 pa-sm-3 text-center">
          <v-btn @click="$emit('updateDialogShow', false)" class="px-16"  outlined text>Отмена</v-btn>
        </div>
      </div>
    </v-card>
    <MessageDialog v-model="showMessage" :text="message" centerText />

  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MessageDialog from '@/components/ui/MessageDialog.vue';

export default {
  name: 'DialogAddLicenses',
  components: {
    MessageDialog,
  },
  props: {
    dialogShow: {
      type: Boolean,
      required: true,
    },
    toType: {
      type: String,
      required: true,
    },
    toId: {
      type: Number,
      required: true,
    },
  },
  model: {
    prop: 'dialogShow',
    event: 'updateDialogShow',
  },
  data() {
    return {
      amount: 0,
      error: '',
      changed: false,
      showMessage: false,
      message: '',
      success: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'users/counters/loading',
    }),
    dialogShowComputed: {
      get() {
        return this.dialogShow;
      },
      set(val) {
        if (!val) {
          this.$emit('updateDialogShow', false);
        }
      }
    },
  },
  watch: {
    dialogShow(newVal) {
      if (newVal) {
        this.amount = 0;
        this.error = '';
        this.changed = false;
        this.showMessage = false;
        this.success = false;
      }
    },
    showMessage(val) {
      if (!val && this.success) {
        this.$emit('updateDialogShow', false);
        this.$emit('success');
      }
    }

  },
  methods: {
    ...mapActions({
      addLicenses: 'users/counters/addLicenses',
    }),
    onChanged() {
      this.changed = true;
      this.error = '';
    },
    async onAdd() {
      const amount = +this.amount;
      if (Number.isNaN(amount) || amount <= 0 || amount !== Math.round(amount)) {
        this.error = 'Значение должно быть целым положительным числом';
        return;
      }
      const response = await this.addLicenses({ amount, toType: this.toType, toId: this.toId });
      if (response && response.success) {
        this.message = `Счетчик тестов обновлен до ${response.stock_balance}`;
        this.showMessage = true;
        this.success = true;
      } else {
        this.message = response && response.error ? response.error : 'При обновлении счетчика тестов произошла ошибка';
        this.showMessage = true;
        this.$emit('updateDialogShow', false);
      }
    }
  },
};
</script>

<style scoped>

</style>

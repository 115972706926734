/* eslint-disable */
export default [
    {
      "code": "hyper",
      "title": "Гипертимный",
      "motto": "Общение и получение положительных эмоций",
      "people": "Дима Билан, Олег Газманов",
      "people_images": [
        "bilan.png", "gazmanov.png"
      ],
      "desc": "<p>Ваш характер отличается высокой активностью и энергичностью, почти всегда Вы в хорошем настроении.</p><p>В целом, Вы можете демонстрировать уверенное поведение, открытость людям и новым проектам, легкость в общении и установлении социальных контактов. В отдельных ситуациях, при ярко выраженной активности, Вы можете испытывать трудности в ситуациях, которые требуют длительных усилий и демонстрировать непоследовательность, так как ориентированы на ситуативные желания и потребности.</p>",
      "power_sides": [
        "Вы хороший коммуникатор",
        "Умеете легко устанавливать контакты с разными людьми",
        "Легко включаетесь в новые проекты, открыты новому опыту.",
        "Можете мотивировать и увлекать за собой других людей",
        "Вы обладаете высоким уровнем работоспособности и быстрой скоростью восстановления сил после стресса или усталости",

      ],
      "weak_sides": [
        {
          "text": "В процессе деятельности Вы можете проявлять небрежность, незавершенность, грубоватость в обращении с предметами, невнимательность к качеству производимого продукта.",
          "recommend": "Обращать внимание не только на процесс но и на качество выполненной работы",
        },
        {
          "text": "Хаотичность в деятельности, которая проявляется в том, что Вы беретесь за несколько дел сразу, но не доводите до конца, распыляете свои силы на множество малозначительных занятий.",
          "recommend": "делайте акцент на качестве выполнения задачи и доведения до результата, используйте приемы тайм менеджмента (например, планирование не более 3-5 важных дел на день; соблюдать последовательность в выполнении задач, а не браться за все сразу; иметь в команде помощника, который планирует ваш день и напоминает о важных задачах)."
        },
        {
          "text": "Поверхностное общение и потребность в новых контактах может проявляться как невнимательность к коллегам и подчиненным, а прямота высказываний может обижать собеседника",
          "recommend": "говорите не только о себе и про себя, обращайте внимание на собеседника, интересуйтесь вашим собеседником во время общения, развивайте навыки эмпатии"
        },
        {
          "text": "Трудоемкая, монотонная, рутинная деятельность, выполнение работы по жесткому регламенту и шаблону может привести к демотивации и снижению вашей результативности",
          "recommend": "в процессе работы иметь возможность смены деятельности, переключение с одного вида деятельности на другой (например, чередовать общение с сотрудниками"
        },
      ],
      "features": "<p>Основным Вашим качеством в коммуникации является жизнерадостность и приветливость. Энергичная и быстрая речь, которая сопровождается позитивом и улыбкой, живая и непосредственная мимика. Как правило, Вы хорошо чувствуете настроение окружающих и открыто проявляете свои эмоции</p>",
      "features_easy": ["гипертимный"],
      "features_complicated": ["тревожный", "эпилептоидный"],
      "recommendations": [
        {
          "title": "С тревожным типом личности",
          "items": [
            "Оказывайте ему моральную и информационную поддержку, не оставляйте один на один с неизвестными и непредсказуемыми ситуациями, не вовлекайте в рискованные и авантюрные проекты, иначе он сочтет вас человеком, с которым опасно иметь дела",
            "Покажите, что вы человек стабильный, надежный, и рядом с вами не о чем тревожиться",
            "Не требуйте быстрого и сиюминутного решения - дайте время на ответ",
            "Выполняйте договоренности, избегайте резкого изменения поведения и договоренностей в коммуникации, если же изменение условий необходимо для дела, объясните причину изменений и результат."
          ]
        },
        {
          "title": "С эпилептоидным типом личности",
          "items": [
            "Говорите четко, конкретно, без воды",
            "Не пытайтесь в коммуникации продавливать свою позицию, вы встретите отпор и сопротивление",
            "Не перегружайте его информационными потоками, подавайте информцию последовательно, обращая внимание на то, как собеседник ее принимает",
            "Дайте ему понять, что уважаете его мнение и личные границы и при этом дайте четко понять, что вы тоже знаете и умеете защищать свои личные границы",
            "Договоритесь о правилах взаимодействия или он установит их сам, так как эпилептоид не терпит хаос и беспорядок"
          ]
        },
      ],
      "directions": [
        "Деятельность, связанная с коммуникацией с другими людьми и работу в команде (например, продажа и переговоры)",
        "Деятельность, предполагающая инициативу и организацию (например, предпринимательство, маркетинг)",
        "Подойдет деяnельность, связанная с публичными выступлениями, командировками, требующая гибкости, разнонаправленности (например, менеджер по развитию)."
      ],
      "productivity": [
        {
          "title": "Яркие события/новизна",
          "text": "Потребность в интересных, новых и ярких впечатлениях и событиях, возможно, в активной деятельности, которая помогает разнообразить скуку и рутинность."
        }
      ]
    },

    /* Тревожный */
    {
      "code": "alarmic",
      "title": "Тревожный",
      "motto": "Семь раз отмерь, один раз отрежь",
      "people": "Перегрин Тук, Анатолий Ефремович Новосельцев",
      "people_images": [
        "tuk.png", "novoseltsev.png"
      ],
      "desc": "<p>Ваш характер отличается стремлением многократно убедиться в объективной необходимости и продуманности любого нововведения. </p><p>Вам особенно важно работать в условиях четкой определенности и предсказуемости, так как это позволит Вам быть уверенным в результате и спокойно проявлять свои интеллектуальные и другие рабочие компетенции. Вы пунктуальны, благоразумны, прилежны, хорошо предвидите риски. При этом не склонны к переменам, воспринимаете их тяжело, стараетесь сопротивляться новизне, консервации привычных жизненных условий.</p>",
      "power_sides": [
        "Вы аккуратны, надежны и предсказуемы",
        "Вы умеете сохранять накопленный опыт",
        "Вам свойственны осторожность, чувствительность к опасности, предвидение неприятностей",
        "У вас хорошие аналитические способности",
        "Вы исполнительны, прилежны, пунктуальны и благоразумны",
      ],
      "weak_sides": [
        {
          "text": "Вам свойственно стремление к отказу от ответственности.",
          "recommend": " постепенно развивать этот навык, начинать с небольших дел, которые находятся в вашей компетентности. Далее, потихоньку двигаться к более ответственным задачам",
        },
        {
          "text": "Вы сопротивляетесь новизне, любым переменам.",
          "recommend": "учиться принимать перемены как необходимую часть жизни, находить в них что-то для себя интересное и полезное"
        },
        {
          "text": "У Вас есть склонность к сомнениям и колебаниям",
          "recommend": " проанализировать ситуацию, возможно, посоветоваться с кем-то и на основании этих данных принять решение"
        },
      ],
      "features": "<p>Основным вашим качеством в коммуникации является постепенность, ненавязчивость в построении коммуникации. Не торопить события, двигаться шаг за шагом.</p><p>В общении с окружающими Вы также проявляете избирательность и постоянство. Вы долго присматриваетесь к человеку, прежде чем пойти на постепенное сокращение межличностной дистанции. Но тем, кого Вы «подпустили поближе», к кому привыкли, Вы останетесь преданными на долгие-долгие годы, нередко на всю жизнь.</p>",
      "features_easy": ["тревожный", "эпилептоидный"],
      "features_complicated": ["истероидный", "эпилептоидный", "паранойяльный"],
      "recommendations": [
        {
          "title": "С паранойяльным типом личности",
          "items": [
            "Не спорьте, примите любую позицию паранойяла, которую он транслирует. В противном случае вы не будете допущены до ближнего круга общения. Любые споры - это пустая трата времени, особенно если ваша аргументация основана на чувствах, ощущениях или домыслах",
            "Если хотите быть услышаны, готовьте факты, цифры и ссылки на авторитетные источники. Покажите, что вы проделали огромную работу и проанализировали не мало информации, чтобы сформировать свою позицию",
            "Чтобы привлечь внимание паранойяла, говорите с ним о ценностях, миссиях, глобальных преобразованиях мира, социальном реформаторстве",
            "Говорите конкретно, с эмоциональной	сдержанностью, аргументируя свои суждения, но не заходите на территорию личностных убеждений и ценностей, не пытайтесь навязать свою позицию."
          ]
        },
        {
          "title": "С истероидным типом личности",
          "items": [
            "Дайте ему внимание: скажите ему комплимент, хвалите/выделяйте его заслуги шумно, публично и с бурными аплодисментами;",
            "Дайте понять, что вы человек, который заслуживает внимания, и у вас есть статус. Расскажите о ваших достижениях, регалиях, серьезных знакомствах;",
            "Будьте стильны, на пике моды, трендов, в курсе всех новинок, чтобы поддержать интересующую тему истеройда;",
            "Не упускайте возможности поздравить его с праздником, с днем его рождения или любым инфо поводом - ведь для него внимания много не бывает, но вы точно получите его расположение.",
          ]
        },
        {
          "title": "С эпилептоидным типом личности",
          "items": [
            "Говорите четко, конкретно, без воды",
            "Не пытайтесь в коммуникации продавливать свою позицию, вы встретите отпор и сопротивление",
            "Не перегружайте его информационными потоками, подавайте информцию последовательно, обращая внимание на то, как собеседник ее принимает",
            "Дайте ему понять, что уважаете его мнение и личные границы и при этом дайте четко понять, что вы тоже знаете и умеете защищать свои личные границы",
            "Договоритесь о правилах взаимодействия или он установит их сам, так как эпилептоид не терпит хаос и беспорядок"
          ]
        },
      ],
      "directions": [
        "Рутинная и монотонная деятельность (например, делопроизводство, ведение архива и т.д.",
        "Деятельность по шаблону и алгоритму (бухгалтерия и финансы, аналитика и т.д.)",
      ],
      "productivity": [
        {
          "title": "Предсказуемость",
          "text": "Потребность в стабильных, предсказуемых рабочих отношениях и задачах. Важен благоприятный психологический климат в коллективе, отношения с коллегами и руководством."
        }
      ]
    },

    /* Паранойяльный */
    {
      "code": "paranoic",
      "title": "Паранойяльный",
      "motto": "Вижу цель - не вижу препятствий",
      "people": "Владимир Ленин, Маргарет Тэтчер, Грета Тунберг",
      "people_images": [
        "lenin.png", "tetcher.png", "tunberg.png"
      ],
      "desc": "<p>Ваш характер отличается упорством и целеустремленностью в достижении целей, желанием и способностью преобразовать мир. Вы ориентируетесь на получение высоких результатов для удовлетворения своих амбиций. Доводите начатое дело до конца. Также в Вашем характере присутствует целеустремленность, властность, уверенность в себе </p><p>При этом Вы можете испытывать трудности в переключении между видами деятельности, изменении шаблонов выполнения задач. Можете быть излишне требовательны к действиям других, проявлять директивность и предвзятость в оценках. Вам может быть трудно признавать свою неправоту</p>",
      "power_sides": [
        "Вы умеете быть последовательным и упорным в достижении своих целей, ориентированы на результат",
        "Вы проявляете инициативу и работоспособность при выполнении рабочих задач",
        "Вы способны прилагать длительные усилия для достижения целей",
        "Умеете глубоко погружаться в интересующую тему",
        "Стремитесь глубоко проанализировать ситуацию",
        "У Вас широкие познания в области своих интересов",
        "У Вас есть желание добиваться коренных преобразований во всём, за что ни возьмётся"
      ],
      "weak_sides": [
        {
          "text": "Вам сложно в принятии иной точки зрения, склонны отстаивать свое мнение, спорить",
          "recommend": "Давать другим возможность высказаться,  допускать возможность альтернативного вИдения ситуации",
        },
        {
          "text": "Сложно общаться “по душам”",
          "recommend": "Развивать навыки эмпатии, прислушиваться к мнению других, постараться встать на их место"
        },
        {
          "text": "Вы склонны действовать по привычному негибкому шаблону",
          "recommend": "Развивать гибкость, творческость, учиться смотреть на ситуацию по-новому"
        },
        {
          "text": "Вы подозрительно относитесь к людям",
          "recommend": "Учиться доверять, расслабляться, принимать вещи такими, какие они есть, открывать для себя альтернативы"
        },
      ],
      "features": "<p>Основным вашим качеством в коммуникации является способность к эффективной коммуникации при наличии осознанной цели. Вы стремитесь к общению с теми, кто принимает, поддерживает и одобряет Ваши взгляды, ценности, убеждения. Умеете излагать, отстаивать и аргументировать свои идеи, опираясь на развитые аналитические способности, на имеющиеся знания о сути вопросов, проблем. С легкостью способны убеждать других в вопросах, которые Вам кажутся значимыми, и на этой основе привлекать людей, расширять масштабы деятельности, иногда становиться признанным интеллектуальным лидером. Умеете объединять вокруг себя сторонников своих взглядов, идей.</p>",
      "features_easy": ["паранойяльный", "эмотивный", "тревожный"],
      "features_complicated": ["эпилептоидный"],
      "recommendations": [
        {
          "title": "С эпилептоидным типом личности",
          "items": [
            "Говорите четко, конкретно, без воды",
            "Не пытайтесь в коммуникации продавливать свою позицию, вы встретите отпор и сопротивление",
            "Не перегружайте его информационными потоками, подавайте информцию последовательно, обращая внимание на то, как собеседник ее принимает",
            "Дайте ему понять, что уважаете его мнение и личные границы и при этом дайте четко понять, что вы тоже знаете и умеете защищать свои личные границы",
            "Договоритесь о правилах взаимодействия или он установит их сам, так как эпилептоид не терпит хаос и беспорядок"
          ]
        },
      ],
      "directions": [
        "Деятельность, предполагающая выстраивание стратегии и преобразование мира (стратегический менеджмент, сфера экологии; внедрение новых технологий, политика)",
        "Деятельность, где необходимо выстроить систему (например, управление отделом, подразделением, филиалом и т.д.)",
        "Предпринимательская деятельность (например, создание новых бизнесов, стартапов).",
      ],
      "productivity": [
        {
          "title": "Свобода",
          "text": "Мотивация к свободе самовыражения, проявления своих способностей в реальных достижениях. Важно , чтобы среда в которой Вы находитесь учитывала Ваши индивидуальные особенности, желания и потребности."
        }
      ]
    },

    /* Эмотивный */
    {
      "code": "emotive",
      "title": "Эмотивный",
      "motto": "Миром правит доброта и забота",
      "people": "Евгений Леонов, Мать Тереза",
      "people_images": [
        "leonov.png", "tereza.png"
      ],
      "desc": "<p>Ваш характер отличается мягкостью, чувствительностью, повышенным вниманием к деталям, стремлением учитывать все возможные факты, а главное, рассматривать все возможные риски ситуации. В деятельности вы больше стараетесь избежать возможных неприятностей, чем рисковать, добиваясь высоких результатов. Вы стараетесь работать по заранее разработанному собственному четкому шаблону, добросовестно выполняя свои задачи. Вы склонны много учится, ответственны и терпеливы. Вы великолепно прогнозируете ситуацию, понимаете развитие событий, обладаете определенной проницательностью и прозорливостью..</p>",
      "power_sides": [
        "Забота и  высокая потребность помогать другим",
        "Хорошо развитая интуиция",
        "Терпеливость и склонность к сопереживанию",
        "Исполнительность",
      ],
      "weak_sides": [
        {
          "text": "Повышенная чувствительность и ранимость, склонность к выгоранию",
          "recommend": "Научиться дистанцироваться от различных событий, которые захватывают и лишают покоя, душевного равновесия, беречь свои силы, знать свои способы восстановления сил и энергии",
        },
        {
          "text": "Неспособность защититься от обидчика, жертвенность, сложность сказать “НЕТ”, приструнить разболтавшийся коллектив, наладить дисциплину",
          "recommend": "Избегайте ситуаций, которые могут Вас ранить и тревожить (например, не смотрите фильмов, которые пугают и лишают покоя, не общайтесь с людьми, которые специально стремятся вас расстроить или разозлить), обучайтесь навыкам аргументации и  отстаивания своего мнения"
        },
        {
          "text": "Страх нового и низкая стрессоустойчивость",
          "recommend": "Вам важно иметь время на подготовку, в ситуациях требующих физического или эмоционального напряжения, а также в новых ситуациях. Тренируйте свою стрессоустойчивость."
        },
      ],
      "features": "<p>В общении Вы добры, дружелюбны и внимательны. Вы склонны создавать комфортные условия при общении. Вы стараетесь помочь любому, кто к вам обратился, так как забота и сопереживание являются главными чертами вашего характера. Для вас характерна плавная и негромкая речь, а тону голоса свойственна мелодичность. При общении мягкая и плавная жестикуляция, выражающая искренние переживания. Слова выбираются больше позитивные, негативные же сменяются словами извинения. Вы чувствительны к критике и в общении склонны к гармонизации и урегулированию конфликтов. В общении с новыми людьми можете проявлять скромность и застенчивость. В шумных компаниях отдаете предпочтение уединению в узком кругу знакомых, чем нахождению в центре внимания.</p>",
      "features_easy": ["эмотивный", "шизоидный"],
      // "features_easy_codes": ["emotive", "shisoid"],
      "features_complicated": ["эпилептоидный"],
      // "features_complicated_codes": ["epileptic"],
      "recommendations": [
        {
          "title": "С эпилептоидным типом личности",
          "items": [
            "Говорите четко, конкретно, без воды",
            "Не пытайтесь в коммуникации продавливать свою позицию, вы встретите отпор и сопротивление",
            "Не перегружайте его информационными потоками, подавайте информцию последовательно, обращая внимание на то, как собеседник ее принимает",
            "Дайте ему понять, что уважаете его мнение и личные границы и при этом дайте четко понять, что вы тоже знаете и умеете защищать свои личные границы",
            "Договоритесь о правилах взаимодействия или он установит их сам, так как эпилептоид не терпит хаос и беспорядок"
          ]
        },
      ],
      "directions": [
        "Деятельность, связанная с заботой и обслуживанием людей социально-ориентированная (сиделки, семейные врачи, психологи, социальные работники)",
        "Педагогическая деятельность, волонтерство (воспитатели, педагоги дошкольного и младшего школьного образования, волонтеры)",
        "Деятельность связанная с клиентоориентированностью и сервисом (служащие гостиниц, редакторы, оформители, декораторы)"
      ],
      "productivity": [
        {
          "title": "Быть полезным, быть нужным, помогать людям, предсказуемость",
          "text": "Потребность в стабильных, предсказуемых рабочих отношениях и задачах. Важен благоприятный психологический климат в коллективе, отношения с коллегами и руководством."
        },
        {
          "title": "Комфортные отношения",
          "text": "Потребность в ровных, доверительных отношениях с другими людьми. Ориентация в общении и работе на создание теплой рабочей атмосферы, эмоциональную поддержку со стороны коллектива и руководства"
        }

      ]
    },


    /* Эпилептоидный */
    {
      "code": "epileptic",
      "title": "Эпилептоидный",
      "motto": "В мире все должно быть правильно и упорядоченно",
      "people": "Гоша Куценко, Николай Валуев",
      "people_images": [
        "kutsenko.png", "valuev.png"
      ],
      "desc": "<p>Ваш характер отличается независимостью, активностью и энергичностью. Вы целеустремленный, волевой и деловой человек практического склада ума. Вы всегда беретесь за дело, если понимаете, что в этом деле Вам все понятно, и Вы можете выполнить его успешно. Вы стремитесь быть лидером в коллективе, не останавливаться на достигнутом и стремитесь расширить сферу своего влияния, Вам важно все держать под контролем и систематизировать.</p>",
      "power_sides": [
        "Целеустремленность, решительность и напористость (это помогает Вам в достижении поставленных целей)",
        "Умение справляться с рутинной неспешной работой, требующей аккуратности, точности, внимания к мелким деталям",
        "Склонность к систематизации, создании алгоритмов и упорядочиванию",
      ],
      "weak_sides": [
        {
          "text": "Прямолинейность и гиперконтроль могут мешать Вам в общении с людьми",
          "recommend": "Будьте более спонтанным, старайтесь в общении из позиции “сверху” занимать позицию “на равных”",
        },
        {
          "text": "Волевые качества и напористость могут мешать Вам гибко адаптироваться к изменяющимся условиям",
          "recommend": "научиться быть гибким и вариативным"
        },
        {
          "text": "Вы прагматичный человек и не всегда хорошо разбираетесь в эмоциональных состояниях других людей.",
          "recommend": "обращайте внимание на собеседника, интересуйтесь вашим собеседником во время общения, развивайте навыки эмпатии, обращайте внимание, что человек в общении - важнее порядка и контроля.."
        },
      ],
      "features": "<p>Вы просты в общении, но требуете порядка во всем.  Вы хорошо структурируете свои высказывания, разлагает их на простые фразы, не злоупотребляете вводными предложениями и деепричастными оборотами. Логика Вашей речи всегда последовательна и проста. В общении для Вас всегда есть цель, Вы не приемлите пустую болтовню. В разговоре Вы внимательны и замечаете любые мелочи. В начале общения с незнакомыми людьми Вы, скорее всего, будете наблюдателем, внимательно изучающим особенности окружающих людей. В знакомой обстановке стремитесь к лидерству, Вам важно занять доминирующую и контролирующую позицию.</p>",
      "features_easy": ["тревожный", "эпилептоидный"],
      "features_complicated": ["гипертимный", "шизоидный"],
      "recommendations": [
        {
          "title": "С шизоидным типом личности",
          "items": [
            "выстраивайте с ними приятельские отношения, но соблюдая некоторую дистанцию, без посягательства на проникновение в глубины их души",
            "узнайте, что для него интересно, проявляйте уважение к его идеям, даже если они будут казаться не от мира сего",
            "будьте аккуратны с выражениями, оценкой его интересов, высказываниям, имейте ввиду, что шизоид очень раним",
            "при коммуникации с  шизоидом нужно проявлять терпение и принятие. Далеко не все будет получаться сразу, не падайте духом",
            "если хотите, чтобы шизоид Вас услышал, вложите ваши слова в уста значимого для него человека",
            "говорите на языке его интересов, идеи, во что он верит и что изучает с пристрастием; говорите не про простое банальное, и так всем понятное, а про абстрактное, высокие материи, философское, глубинное"
          ]
        },
        {
          "title": "С гипертимным типом личности",
          "items": [
            "поддержите формат дружеского общения с гипертимом, меньше официальности, проявите больше эмоциональности в разговоре",
            "поделитесь позитивными впечатлениями об интересных событиях, ярких эмоциях о мероприятии/ событии/ хобби",
            "гипертиму в общении важен интерес, получение позитивных эмоций, ярких впечатлений. Если ему станет скучно, он переключится на другого собеседника",
            "ускорять и облегчать процесс общения с гипертимом не нужно, он сам с вами первый познакомится",
            "следует принимать его таким, какой он есть, и быть готовым к его активности, скорости и энергии. Будьте готовы совместно отправиться на ряд мероприятий, путешествия, чтобы поддержать активность и увлечения гипертима",
          ]
        },
      ],
      "directions": [
        "Деятельность предполагающая выносливость, смелость, решительность и контроль (руководители по авторитарному стилю руководства, военные, полиция и правоохранительные органы)",
        "Деятельность предполагающая порядок, организацию, системность и управление процессами (руководители административно-хозяйственной частью, управление)",
        "Деятельность, предполагающая коммерцию и конкуренцию (коммерческий директор, предпринимательство)",
        "Деятельность предполагающая экстремальные ситуации (военные, МЧС)"
      ],
      "productivity": [
        {
          "title": "Доминирование и контроль",
          "text": "Мотивация в достижении персональных результатов, желание конкурировать, быть лидером, выделиться на фоне коллектива"
        },
        {
          "title": "Яркие события/яркие эмоции",
          "text": "Потребность в интересных, новых и ярких впечатлениях, событиях и эмоциях (потребность в экстриме)"
        }

      ]
    },

    /* Шизоидный */
    {
      "code": "shisoid",
      "title": "Шизоидный",
      "motto": "Создать новое и креативное",
      "people": "Альберт Эйнштейн, Марк Цукерберг, Анатолий Вассерман",
      "people_images": [
        "einstain.png", "zuckerberg.png", "vasserman.png"
      ],
      "desc": "<p>Ваш характер отличается  склонностью пребывать в своем внутреннем мире в окружении своих идей, которые могут отличаться своеобразием, оригинальностью и творческим подходом. Вы обладаете способностью видеть мир и людей в новом свете и возможностью находить оригинальные способы выстраивания коммуникации. Вам может быть сложно ориентироваться в новых условиях, в новой для себя обстановке.</p>",
      "power_sides": [
        "Вы умеете находить креативные способы решения задач, генерировать идеи",
        "Вы способны размышлять, независимо от влияния внешних факторов",
        "Вы умеете учитывать множества вариантов реализации задачи",
        "Вы самодостаточны, умеете черпать идеи из своего внутреннего мира"
      ],
      "weak_sides": [
        {
          "text": "Вам может быть сложно тонко чувствовать других людей",
          "recommend": "Развивать навыки эмпатии, внимательности к проявлениям других людей, тактичность в высказываниях",
        },
        {
          "text": "Вы склонны дистанцироваться, прятаться от окружения в своем внутреннем оригинальном мире, особенно когда Вас не понимают",
          "recommend": "Стараться чаще быть с людьми, интересоваться тем, что происходит во внешнем мире, помнить и учитывать, что Ваш подход может быть непонятным другим людям -  берите обратную связь, чтобы правильно друг друга понять"
        },
        {
          "text": "Вы склонны к выдвижению новых идей и подходов, которые иногда могут казаться не очень практичными и оторванными от реальности",
          "recommend": "Рассказывать другим о своих идеях и брать обратную связь о применимости и реалистичности предложенных вами вариантов"
        },
        {
          "text": "В процессе деятельности можете проявлять небрежность, незавершенность, бросать дела недоделанными",
          "recommend": "Стремится доводить дело до конца, брать на себя ответственность за качество конечного результата"
        },
        {
          "text": "Может быть сложность в выражении своих идей, мыслей, проведении презентаций",
          "recommend": "Заранее продумывать план разговора/выступления, избегать сложных слов и терминов. Пройти курс ораторского мастерства"
        },
      ],
      "features": "<p>Основным вашим качеством в коммуникации является интерес к предмету разговора, широта интересов и оригинальность суждений, общению с  необычным людям, учеными, профессионалами в своем деле. В своей речи Вы часто используете сложные термины и обороты. Вы можете быть избирательны в общении, вам важно общаться с теми, кто вас понимает</p>",
      "features_easy": ["шизоидный", "эмотивный"],
      "features_complicated": ["эпилептоидный"],
      "recommendations": [
        {
          "title": "С истероидным типом личности",
          "items": [
            "Дайте ему внимание: скажите ему комплимент, хвалите/выделяйте его заслуги шумно, публично и с бурными аплодисментами;",
            "Дайте понять, что вы человек, который заслуживает внимания, и у вас есть статус. Расскажите о ваших достижениях, регалиях, серьезных знакомствах;",
            "Будьте стильны, на пике моды, трендов, в курсе всех новинок, чтобы поддержать интересующую тему истеройда;",
            "Не упускайте возможности поздравить его с праздником, с днем его рождения или любым инфо поводом - ведь для него внимания много не бывает, но вы точно получите его расположение.",
          ]
        },
        {
          "title": "С эпилептоидным типом личности",
          "items": [
            "Говорите четко, конкретно, без воды",
            "Не пытайтесь в коммуникации продавливать свою позицию, вы встретите отпор и сопротивление",
            "Не перегружайте его информационными потоками, подавайте информцию последовательно, обращая внимание на то, как собеседник ее принимает",
            "Дайте ему понять, что уважаете его мнение и личные границы и при этом дайте четко понять, что вы тоже знаете и умеете защищать свои личные границы",
            "Договоритесь о правилах взаимодействия или он установит их сам, так как эпилептоид не терпит хаос и беспорядок"
          ]
        },
      ],
      "directions": [
        "Деятельность связанная с позиционированием, и подачей себя (актеры, певцы, стилистика, визаж, декор)",
        "Деятельность, предполагающая использование самопрезентации,  эффектную речь (теле/ видео/ радио)",
        "Деятельность, направленная на общение и взаимодействие с людьми (организационная деятельность)",
        "Деятельность, связанная с общением и обучением (ораторы, лекторы, преподавательская деятельность)"
      ],
      "productivity": [
        {
          "title": "Внимание окружающих и общение",
          "text": "Потребность в привлечении внимания окружающих людей, желание нравиться и быть в центре внимания"
        },
      ]
    },

    /* Истероидный */
    {
      "code": "isteric",
      "title": "Истероидный",
      "motto": "Главный в жизни - это Я!",
      "people": "Филипп Киркоров, Алла Пугачева, Ани Лорак",
      "people_images": [
        "kirkorov.png", "pugacheva.png", "lorak.png"
      ],
      "desc": "<p>В общении Вы дружелюбны и активны, заинтересованы в собеседнике, но для Вас может быть важнее то впечатление, которое вы производите, а не искренний интерес к собеседнику. Вы умеете быстро привлечь к себе внимание, заинтересовать своей персоной, Вам важно быть в центре внимания (это может проявляться через эпатажное поведение, театральность, яркость, манипулятивное поведение, провокациозность). Для Вас важно внимание, признание, со стороны окружающих, обратная связь, поэтому Вы легко устанавливаете социальные контакты и прекрасно владеете навыками самопрезентации. Рутинная и будничная работа вне общения с людьми и внимания может Вам удаваться с трудом и сопровождаться негативными эмоциями, потерей мотивации. </p>",
      "power_sides": [
        "Вы легко устанавливаете новые контакты, за счет привлечения внимания к себе и навыкам самопрезентации",
        "Вы склонны выступать перед публикой",
        "Вы умеете влиять на окружающих и вести переговоры",
        "Вы проактивны в общении"
      ],
      "weak_sides": [
        {
          "text": "Поверхностность и отсутствие устойчивых принципов, необязательность",
          "recommend": "контролируйте выполнение правил и данных Вами обещаний, иначе Вы получите репутацию “ненадежного человека”",
        },
        {
          "text": "Эгоцентризм, чрезмерное перетягивание внимания на себя (что может послужить фактором конфликта)",
          "recommend": "в порыве “захвата” всеобщего внимания учиться не оттеснять других, владеть техниками выхода из конфликта"
        },
        {
          "text": "При столкновении с трудностями может быть потеря интереса и отказ от выполнения задачи",
          "recommend": "следует держать во внимании данную черту и при столкновении с ситуацией, когда хочется отказаться от цели/задачи сделать паузу и проанализировать причины отказа (например, причиной может быть страх сделать ошибку) и найти новые пути решения или проконсультироваться о решении у более опытного коллеги"
        },
      ],
      "features": "<p>При общении с людьми Вам важно быть в центре внимания, Вы можете быть красноречивы, эмоционалены, легко устанавливаете контакты и заводите новые знакомства. Умеете увлечь людей своей идеей. </p><p>В общении Ваша речь эффектная, яркая, выразительная, может быть широкая тональность голоса, в речи много дополнительных слов, эпатажная и эмоциональная речь сопровождается выразительной мимикой - это все направлено на привлечение внимания к своей персоне.</p><p>В коммуникации Вам важно, чтобы собеседник проявлял интерес и внимание, хвалил, делал комплименты, задавал вопросы - в противном случае он перестанет вам быть интересен. </p>",
      "features_easy": ["эмотивный"],
      "features_complicated": ["истероидный", "эпилептоидный"],
      "recommendations": [
        {
          "title": "С эпилептоидным типом личности",
          "items": [
            "Говорите четко, конкретно, без воды",
            "Не пытайтесь в коммуникации продавливать свою позицию, вы встретите отпор и сопротивление",
            "Не перегружайте его информационными потоками, подавайте информцию последовательно, обращая внимание на то, как собеседник ее принимает",
            "Дайте ему понять, что уважаете его мнение и личные границы и при этом дайте четко понять, что вы тоже знаете и умеете защищать свои личные границы",
            "Договоритесь о правилах взаимодействия или он установит их сам, так как эпилептоид не терпит хаос и беспорядок"
          ]
        },
      ],
      "directions": [
        "Деятельность, связанная с креативностью и созданием нового  (например, копирайтинг, гейм-дизайн)",
        "Деятельность, предполагающая работу с виртуальным пространством (например, программирование, конструирование,  философия)",
        "Подойдет деятельность, связанная творчеством (например, изобразительное искусство, писательство)",
      ],
      "productivity": [
        {
          "title": "Индивидуальность",
          "text": "Мотивация к нестандартной деятельности, которая поможет реализовать незаурядный личностный и профессиональный потенциал"
        },
      ]
    },


  ]

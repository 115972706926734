<template>
  <div class="switch-wrapper">
      <label class="switch-container">
          <input
              class="input"
              type="checkbox"
              :checked="value"
              @change="$emit('input', $event.target.checked)"
          />
          <span
              class="switch-item"
              :class="{ loading: loading, checked: value }"
          >
              <span class="loader" v-if="loading">
                  <span class="spinner"></span>
              </span>
              <span class="switcher"></span>
          </span>
      </label>
      <span class="switch-text" v-if="text">{{text}}</span>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "SwitchItem",
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
      value: Boolean,
      label: {
          type: String,
          default: "ON"
      },
      text: {
          type: String,
          default: ""
      },
      loading: {
          type: Boolean,
          default: false
      }
  },
  // emits: ['update:modelValue'],
};
</script>

<style  lang="scss">

.switch-wrapper {
  display: flex;
  align-items: center;
}

.switch-text {
  margin-left: 8px;
}

.switch-container {
  margin: 0px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  .input {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
  }
  .switch-item {
      --switch-container-width: 36px;
      --switch-radius-delta: 0px;
      --switch-size: 18px;
      --gray: #cbd5e0;
      --dark-gray: #a0aec0;
      --off-color: #CEC9C9;
      --off-color-focus: #2798FA;
      --loading-color: #37365e;
      --switch-bg: #fcf9f5;
      --on-border-color: #2798FA;
      --on-focus-border-color: #2798FA;
      --border-size: 2px;
      --loading-border-size: 4px;
      display: flex;
      align-items: center;
      position: relative;
      height: var(--switch-size);
      width: var(--switch-container-width);
      flex-basis: var(--switch-container-width);
      border-radius: var(--switch-size);
      background-color: var(--off-color);
      flex-shrink: 0;
      transition: background-color 0.25s ease-in-out;
  }
  .switcher {
      position: absolute;
      left: 0;
      height: calc(var(--switch-size) + var(--switch-radius-delta));
      width: calc(var(--switch-size) + var(--switch-radius-delta));
      border-radius: 50%;
      transition: transform 0.3s ease-in-out;
      background: var(--switch-bg);
      border: var(--border-size) solid var(--off-color);
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  }
  .input:checked + .switch-item {
      background: var(--on-border-color);
  }
  .checked + .switch-item.loading {
      background: var(--loading-color);
  }
  .checked .switcher {
      border-color: var(--on-border-color);
      transform: translateX(
          calc(
              var(--switch-container-width) - var(--switch-size) -
                  var(--switch-radius-delta)
          )
      );
  }
  .switch-item.loading.checked .switcher {
      left: calc(var(--border-size));
      border: none;
      box-shadow: none;
  }
  .input:focus + .switch-item::after {
      border-color: var(--off-color-focus);
  }
  .input:focus:checked + .switch-item::after {
      border-color: var(--on-focus-border-color);
  }
  .input:disabled + .switch-item {
      background-color: var(--gray);
  }
  .input:disabled + .switch-item::after {
      background-color: var(--dark-gray);
      border-color: var(--dark-gray);
  }
  .switch-item .loader {
      position: absolute;
      left: 0;
      left: 4px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      z-index: 5;
      transition: left 0.375s ease-in-out;
  }
  .switch-item.checked .loader {
      left: calc(var(--switch-container-width) - 16px);
  }
  .spinner {
      display: block;
      height: 100%;
      border-radius: 50%;
      background: conic-gradient(
          from 180deg at 50% 50%,
          #e33237 0deg,
          rgba(255, 122, 0, 0) 360deg
      );
      transition: transform 0.375s ease-in-out;
      animation: loading-circle 2s linear infinite;
      transform: rotate(180deg);
  }
  @keyframes loading-circle {
      0% {
          transform: rotate(180deg);
      }
      50% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(-180deg);
      }
  }
}


.dark-mode .switch-container .switch-item {
      --off-color: #7977A6;
      --off-color-focus: #7977A6;
      --switch-bg: #fff;
      --on-border-color: var(--color-orange);
      --on-focus-border-color: var(--color-orange);
}
</style>

import tabs from './tabs';
import report from './report';
import refs from './refs';
import links from './links';

export default {
  namespaced: true,
  modules: {
    tabs,
    report,
    refs,
    links
  },
};

<template>
  <div>
    <v-dialog v-model="isDialogVisible" max-width="750px">
      <v-card class="pa-8" ref="refDialog">
        <h2>Выбор клиента</h2>
        <v-btn icon class="button-close ma-4" @click="isDialogVisible = false"><v-icon>mdi-close</v-icon></v-btn>

        <div class="_mx-4">
          <v-data-table
            :headers="headers"
            :items="clients"
            show-select v-model="selectedClients"
            :loading="loading.load" loading-text="Загрузка... Пожалуйста подождите"
            height="300px" fixed-header  hide-default-footer
            :custom-sort="customSort"
            :items-per-page="-1"
          >
            <template v-slot:item.fullname="{ item }">
              <span> {{ getFullName(item) }} </span>
            </template>
            <template v-slot:no-data> Корпоративных клиентов не найдено. </template>
          </v-data-table>
        </div>
        <div class="row flex-sm-row-reverse mt-6 mb-2">
            <v-btn @click="onShowResults()" class="px-16 mr-2" color="primary" :disabled="selectedClients.length==0">Показать результаты</v-btn>
            <v-btn @click="isDialogVisible = false" class="px-16 mr-6"  outlined text>Отмена</v-btn>
        </div>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CalcSetupCompliance',
  components: {
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: Boolean,
  },
  data: () => ({
    selectedClients: [],
    headers: [
      { text: 'Id', value: 'id', width: '10px',},
      { text: 'Фамилия и Имя', value: 'fullname', width: '280px', },
      { text: 'Компания', value: 'company', width: '280px', },
    ]
  }),
  watch: {
    async value(val) {
      if (val) {
        this.selectedClients = []
        await this.loadClients();
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'users/clients/loading',
      clients: 'users/clients/clients',
      user: 'auth/user',
    }),
    isDialogVisible: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit('input', false);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      loadClients: 'users/clients/loadClients',
    }),
    getFullName(item) {
      return item.last_name || item.first_name ? `${item.last_name} ${item.first_name}` : 'Имя не задано';
    },
    customSort(items, [index], [isDesc]) {
      items.sort((a, b) => {
        if (index === 'fullname') {
          const aname = a.last_name || a.first_name ? `${a.last_name} ${a.first_name}` : 'Я';
          const bname = b.last_name || b.first_name ? `${b.last_name} ${b.first_name}` : 'Я';
          return !isDesc ? aname.toLowerCase().localeCompare(bname.toLowerCase()) : bname.toLowerCase().localeCompare(aname.toLowerCase());
        }
        if (!isDesc) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },

    onShowResults() {
      this.isDialogVisible = false
      this.$emit('ok', this.selectedClients.map(el => el.id))
    }

  },
};
</script>

<style scoped lang="scss">

</style>

<template>
    <div v-if="!isProfile" class="d-flex justify-space-between pb-4 mb-2">
      <a @click="$router.go(-1)" :disabled="backDisabled" class="d-flex align-center text-dark-gray font-weight-600">
        <v-icon right class="page-description ml-0 mr-2">mdi-chevron-left</v-icon> Назад
      </a>
      <div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'BackBtn',
  components: {
  },
  props: {
  },
  data: () => ({
  }),
  computed: {
    backDisabled() {
      return window.history.length < 2;
    },
    isProfile() {
      return this.$route.name === 'profile';
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
</style>

<template>
    <v-dialog v-model="dialogEditShow" max-width="700px">
      <v-card class="pa-4 pa-sm-8">
        <v-btn class="button-close ma-4" icon @click="dialogEditShow = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="text-center">Данные корпоративного клиента</h2>
        <form @submit.prevent="editClientConfirm" class="mt-6">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <v-text-field label="Фамилия *"
                    :value="editedItem.last_name"
                    @input="$v.editedItem.last_name.$model = upperFirst($event), onInput('last_name')"
                    :error-messages="lastnameErrors"
                    outlined dense />
              </div>
              <div class="col-md-6">
                <v-text-field label="Имя *"
                    :value="editedItem.first_name"
                    @input="$v.editedItem.first_name.$model = upperFirst($event), onInput('first_name')"
                    :error-messages="firstnameErrors"
                    outlined dense />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <v-text-field label="Компания"
                        type="text"
                        v-model="editedItem.company"
                        outlined dense />
              </div>
              <div class="col-md-6">
                    <v-select  v-model="editedItem.partnerId"
                      :items="partners"
                      label="Партнер"
                      :item-text="(item) => item.first_name || item.last_name ? item.first_name + ' ' + item.last_name : 'имя не задано'"
                      item-value="id"
                      outlined dense
                      :disabled="!$store.getters['auth/isAllowed']('change_partner_of_corpclient')"
                    />
              </div>

            </div>

            <div v-for="(err, ind) in errors.edit" :key="ind" class="error--text">
              <template v-if="!editedItem.hasOwnProperty(err[0])">
                <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
              </template>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6 mx-auto">
              <v-btn type="submit" class="w-100" color="primary" :loading="loading.edit" :disabled="loading.edit">
                Сохранить
              </v-btn>
            </div>
          </div>
        </form>
      </v-card>
    </v-dialog>
</template>

<script>
/*eslint-disable*/

import { mapActions, mapGetters } from 'vuex';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { upperFirst } from 'lodash';
import { firstnameValidation, lastnameValidation } from '@/common/validationHelpers';
import { EMAIL, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';
import { MESSAGES } from '@/common/constants/errorMessages';
import { VueMaskDirective } from 'v-mask';

export default {
  validations: {
    editedItem: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
    },
  },
  directives: {
    mask: VueMaskDirective,
  },
  data: () => ({
    editedItem: {
      id: 0,
      last_name: '',
      first_name: '',
      partnerId: 0,
      company: '',
    },
  }),
  watch: {
    async dialogEditShow(newVal) {
      if (newVal) {
        this.editedItem = (
          ({ id, last_name, first_name, company, supervisor}) =>
          ({ id, last_name, first_name, company, supervisor})
          )(this.editedClient);
        this.$set(this.editedItem, 'partnerId', this.editedClient.supervisor.id ? this.editedClient.supervisor.id : this.editedClient.supervisor)

        // console.log(this.partners?.length, !this.loadingPartners.load)
        if (!this.partners?.length && !this.loadingPartners.load) {
          await this.loadPartners();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'users/clients/loading',
      errors: 'users/clients/errors',
      editedClient: 'users/clients/editedClient',
      partners: 'users/partners/partners',
      loadingPartners: 'users/partners/loading',
    }),
    dialogEditShow: {
      get() {
        return this.$store.getters['users/clients/dialogEditShow'];
      },
      set(val) {
        if (!val) {
          this.$v.$reset();
        }
        this.setDialogEditShow(val);
      },
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.last_name.$dirty) return errors;
      if (!this.$v.editedItem.last_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.editedItem.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.edit.find((err) => err[0] === 'last_name')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.first_name.$dirty) return errors;
      if (!this.$v.editedItem.first_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.editedItem.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.edit.find((err) => err[0] === 'first_name')?.[1] ?? errors;
    },

  },
  methods: {
    ...mapActions({
      clearError: 'users/clients/clearError',
      setDialogEditShow: 'users/clients/setDialogEditShow',
      saveClient: 'users/clients/saveClient',
      loadPartners: 'users/partners/loadPartners',
    }),
    onInput() {
      if (this.errors.edit.length) this.clearError();
    },
    editClientConfirm() {
      if (this.loading.edit) return;
      this.$v.$touch();
      if (this.editedItem && !this.$v.$invalid) {
        const form = {
          ...this.editedItem,
          supervisor: this.editedItem.partnerId
        };
        this.saveClient(form).then(() => {
          this.dialogEditShow = false;
        });
      }
    },
    upperFirst,
  },
};

</script>

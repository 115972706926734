<template>
  <div>
    <div v-if="loading.manager" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </div>

    <BackBtn />
    <BreadCrumbs :items="breadcrumbs" />

    <ProfileTopPanel v-if="manager"
      userTypeName="Менеджер"
      :name="manager.last_name + ' ' + manager.first_name"
      :city="manager.city"
      :phone="manager.phone"
      :email="manager.email"
      :company="manager.company"
      permssionEdit="edit_manager"
      permssionDelete="пока уберем кнопку delete_manager"
      whoDelete="менеджера"
      @delete="onDelete()" @edit="onEdit()"
    >
      <div class="profile-header-item" style="margin-top:0">
        <v-switch v-if="!isProfile && $store.getters['auth/isAllowed']('edit_manager')"
          :input-value="manager.send_mail" @change="changeSendMail" @loading="loading.editMail"
          class="v-input--reverse profile-switch ml-4" hide-details :color="manager.send_mail ? 'success' : 'error'"
        >
          <div slot="label" :class="manager.send_mail ? 'success--text' : 'error--text'">Почта</div>
        </v-switch>
      </div>
    </ProfileTopPanel>

    <div class="row">
      <div class="col-12 col-md-6">
        <ClientProfileCardCounters
          testType="MMPI"
          v-if="manager && ( $store.getters['auth/isAllowed']('view_stockamount_manager') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="manager"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('manager') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_manager') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
        <ClientProfileCardCounters
          testType="MINIMULT"
          v-if="manager && ( $store.getters['auth/isAllowed']('view_stockamount_manager') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="manager"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('manager') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_manager') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
      </div>
      <div class="col-12 col-md-6">
        <ClientProfileCardCounters
          testType="CONSTRUCTOR"
          v-if="manager && ( $store.getters['auth/isAllowed']('view_stockamount_manager') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="manager"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('manager') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_manager') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
      </div>
    </div>
    <AddLicensesDialog v-model="dialogAddLicencesShow" :toId="+userId" toType='manager' @success="reload()" />
    <ManagerEditDialog />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ProfileTopPanel from '@/components/common/ProfileTopPanel';
import ManagerEditDialog from '@/components/managers/ManagerEditDialog';
import AddLicensesDialog from '@/components/admins/AddLicensesDialog.vue';
import ClientProfileCardCounters from '@/components/clients/profile/ProfileCardCounters';

export default {
  name: 'ProfileManager',
  components: {
    ManagerEditDialog,
    AddLicensesDialog,
    ProfileTopPanel,
    ClientProfileCardCounters
  },
  props: ['userId'],
  provide() {
    return {
      userType: 'Manager',
      userId: this.userId,
    };
  },
  data() {
    return {
      dialogAddLicencesShow: false,
    };
  },
  inject: {
    isProfile: {
      default: false
    }
  },
  computed: {
    ...mapGetters({
      manager: 'users/managers/manager',
      loading: 'users/managers/loading'
    }),
    breadcrumbs() {
      if (!this.manager) return [];
      return this.isProfile ? [
        { title: 'Личный кабинет', url: '/' },
      ] : [
        { title: 'Менеджеры', url: '/managers/' },
        { title: `${this.manager.last_name} ${this.manager.first_name}`, url: `/managers/${this.manager.id}` },
      ];
    },
  },
  methods: {
    ...mapActions({
      loadManager: 'users/managers/loadManager',
      setDialogEditShow: 'users/managers/setDialogEditShow',
      setManagerSendMail: 'users/managers/setManagerSendMail',
    }),
    ...mapMutations({
      setEditedManager: 'users/managers/setEditedManager'
    }),
    onEdit() {
      this.setEditedManager({ ...this.manager });
      this.setDialogEditShow(true);
    },
    onDelete() {
    },
    reload() {
      this.loadManager(this.userId);
    },
    changeSendMail(value) {
      this.setManagerSendMail({ send_mail: !!value, id: this.manager.id });
    },

  },
  created() {
    this.loadManager(this.userId);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
</style>

<template>
  <div>
    <div v-if="loading.candidate" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </div>

    <BackBtn />
    <BreadCrumbs :items="breadcrumbs" />

    <ProfileTopPanel v-if="candidate"
      userTypeName="Кандидат"
      :name="candidate.last_name + ' ' + candidate.first_name"
      :city="candidate.city"
      :age="age"
      :phone="candidate.phone"
      :email="candidate.email"
      :position="candidate.position"
      :manager="candidate.manager ? candidate.manager.last_name + ' ' + candidate.manager.first_name :''"
      permssionEdit="edit_candidate"
      permssionDelete="delete_candidate"
      whoDelete="кандидата"
      @delete="onDelete()" @edit="onEdit()"
    >
      <div class="profile-header-item" style="margin-top:0">
        <v-switch v-if="!isProfile && $store.getters['auth/isAllowed']('edit_candidate')"
          :input-value="candidate.is_active" @change="changeActive" :loading="loading.edit" class="v-input--reverse profile-switch"
          hide-details :color="candidate.is_active ? 'success' : 'error'"
        >
          <div slot="label" :class="candidate.is_active ? 'success--text' : 'error--text'">{{candidate.is_active ? 'Активен' : 'Не активен'}}</div>
        </v-switch>
        <v-switch v-if="!isProfile && $store.getters['auth/isAllowed']('edit_candidate')"
          :input-value="candidate.send_mail" @change="changeSendMail" @_loading="loading.editMail"
          class="v-input--reverse profile-switch ml-4" hide-details :color="candidate.send_mail ? 'success' : 'error'"
        >
          <div slot="label" :class="candidate.send_mail ? 'success--text' : 'error--text'">Почта</div>
        </v-switch>
      </div>
    </ProfileTopPanel>

    <ProfileEditDialog />

    <div class="row">
      <div class="col-12 col-lg-8">
        <div v-if="candidate" class="profile-board profile-card mb-5">
          <h3 class="mt-1 mb-3">Общая информация</h3>
          <v-btn :outlined="mainTab!==0" color="primary" rounded @click="setTab(0)" class="mr-3">Статус тестирования</v-btn>
          <v-btn :outlined="mainTab!==1" color="primary" rounded @click="setTab(1)">Отчеты</v-btn>

          <v-tabs-items :value="mainTab" class="mt-5">
            <v-tab-item key="0" >
              <ProfileTabGeneral />
            </v-tab-item>
            <v-tab-item key="1" >
              <ProfileTabReports />
            </v-tab-item>
          </v-tabs-items>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileEditDialog from '@/components/profile/ProfileEditDialog';
import BreadCrumbs from '@/components/ui/BreadCrumbs';
import ProfileTopPanel from '@/components/common/ProfileTopPanel';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { TESTING_STATUSES } from '@/common/constants/testingStatuses';
import ProfileTabGeneral from './ProfileTabGeneral';
import ProfileTabReports from './ProfileTabReports';

export default {
  name: 'ProfileData',
  components: {
    ProfileEditDialog, ProfileTopPanel, ProfileTabGeneral, ProfileTabReports, BreadCrumbs
  },
  props: ['userId'],
  data: () => ({
    TESTING_STATUSES,
    mainTab: 0,
  }),
  provide() {
    return {
      userType: 'Candidate',
      userId: this.userId,
    };
  },
  inject: {
    isProfile: {
      default: false
    }
  },
  computed: {
    ...mapGetters({
      candidate: 'users/candidate/candidate',
      errors: 'users/candidate/errors',
      loading: 'users/candidate/loading',
    }),
    breadcrumbs() {
      if (!this.candidate) return [];
      return this.isProfile ? [
        { title: 'Личный кабинет', url: '/' },
      ] : [
        { title: 'Кандидаты', url: '/candidates/' },
        { title: `${this.candidate.last_name} ${this.candidate.first_name}`, url: `/candidates/${this.candidate.id}` },
      ];
    },
    age() {
      if (this.candidate.date_of_birth) {
        const years = this.$moment().diff(this.candidate.date_of_birth, 'years');
        if (years) {
          return `${years} ${this.$declOfNum(years, 'год', 'года', 'лет')}`;
        }
      }
      return '';
    },
  },
  methods: {
    ...mapActions({
      deleteCandidate: 'users/candidate/deleteCandidate',
      setDialogEditShow: 'users/candidates/setDialogEditShow',
      setCandidateActive: 'users/candidate/setCandidateActive',
      setCandidateSendMail: 'users/candidate/setCandidateSendMail',
      loadCandidate: 'users/candidate/loadCandidate',
      loadCandidateResults: 'users/candidate/loadCandidateResults',
      resetStateCandidate: 'users/candidate/resetState',
      resetStateTabs: 'profile/tabs/resetState',
    }),
    ...mapMutations({
      setEditedCandidate: 'users/candidates/setEditedCandidate',
    }),
    onDelete() {
      this.deleteCandidate().then(() => {
        this.$router.replace({ name: 'candidates' });
      });
    },
    onEdit() {
      this.setEditedCandidate({ ...this.candidate });
      this.setDialogEditShow(true);
    },
    changeActive(value) {
      this.setCandidateActive({ is_active: !!value, id: this.candidate.id });
    },
    changeSendMail(value) {
      this.setCandidateSendMail({ send_mail: !!value, id: this.candidate.id });
    },
    setTab(tab) {
      this.mainTab = tab;
    },
  },
  created() {
    this.loadCandidate(this.userId);
    this.loadCandidateResults(this.userId);
  },
  beforeDestroy() {
    this.resetStateCandidate();
    this.resetStateTabs();
  },

};
</script>

<style lang="scss">
@import "src/assets/styles/variables";
</style>

import managers from './managers';
import candidates from './candidates';
import clients from './clients';
import partners from './partners';
import admins from './admins';
import candidate from './candidate';
import counters from './counters';

export default {
  namespaced: true,
  modules: {
    managers,
    clients,
    candidates,
    candidate,
    partners,
    admins,
    counters,
  },
};

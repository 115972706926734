import Vue from 'vue';

/*eslint-disable */
export function loadColumnsSetup(columnsKey, columns) {
  const str = localStorage.getItem(columnsKey);
  const columnsDataJson = JSON.parse(str);
  const columnsData = {};

  for (const item of columns) {
    Vue.set(columnsData, item.key, columnsDataJson && columnsDataJson[item.key] === false ? false : true);
    if (item.children)
      for (const child of item.children) {
        Vue.set(columnsData, child.key, columnsDataJson && columnsDataJson[child.key] === false ? false : true);
      }
    }
    return columnsData;
}

export function saveColumnsSetup(columnsKey, columnsData) {
  localStorage.setItem(columnsKey, JSON.stringify(columnsData));
}

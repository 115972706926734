<template>
  <div class="breadcrumbs" v-if="items">
    <div v-for="(item, i) in items" :key="i">
      <v-icon class="mx-1" x-small v-if="i">mdi-arrow-right</v-icon>
      <a v-if="item.url" :href="item.url">{{item.title}}</a>
      <span v-else>{{item.title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'bradCrumbs',

  data: () => ({
  }),
  props: ['items']
};

</script>

<style scoped>

.breadcrumbs {
  color: #000;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 20px;
}

.breadcrumbs a {
  color: #777;
  text-decoration: none;
}

</style>

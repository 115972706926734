import api from '@/common/api';
import moment from 'moment';

/* eslint-disable */



const initialState = () => ({
  loading: {
    codes: false,
    codesUsed: false,
    activate: false,
    create: false,
    delete: false,
    deactivate: false,
    save: false,
    search: false,
  },
  errors: {
    load: [],
    create: [],
    edit: [],
  },
  editedPromocode: null,
  codes: [],
  codesUsed: [],
  defaultRef: null,
  dialogEditShow: false,
  user_id: 0,
  user_type: 0,
});

const newPromocode = {
  id: 0,
  package_size: '',
  tests_for_distributors: '',
  tests_for_users: '',
  managed_by: 0,
  // необязательные поля
  title: '',
  promocode: '',
  date_from: '',
  date_to: '',
  url: "",
  usage: '',
  description: "",
  is_default: false
}


export default {
  namespaced: true,
  state: initialState,
  getters: {
    loading: (state) => state.loading,
    codes: (state) => state.codes,
    codesUsed: (state) => state.codesUsed,
    defaultRef: (state) => state.defaultRef,
    editedPromocode: (state) => state.editedPromocode,
    dialogEditShow: (state) => state.dialogEditShow,
    errors: (state) => state.errors,
    // isCodeActive: (state) => (code) => !code.date_to,
  },
  mutations: {
    setCodes(state, codes) {
      state.codes = codes;
      state.defaultRef = codes.find(el => el.is_default)
    },
    setCodesUsed(state, codes) {
      state.codesUsed = codes;
    },
    startLoading(state, field) {
      state.loading[field] = true;
    },
    endLoading(state, field) {
      state.loading[field] = false;
    },
    setEditedPromocode(state, code) {
      if (code) {
        state.editedPromocode = { ...code };
      } else {
        state.editedPromocode = { ...newPromocode };
      }
      state.errors.edit = [];
    },
    setDialogEditShow(state, bool) {
      state.dialogEditShow = bool;
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setError(state, {type, errors}) {
      state.errors[type] = errors;
    },
    setUser(state, {user_id, user_type}) {
      state.user_id = user_id;
      state.user_type = user_type;
    },

  },
  actions: {
    async loadCodes({ commit, state, getters, rootGetters }, {id, user_type}) {
      commit('setCodes', []);
      commit('startLoading', 'codes');
      commit('setUser', {user_id: id, user_type} );
      try {
        let codes = [];
        if (user_type==='CorpClient') codes = await api.get(`/users/corp_clients/${id}/promocodes/`)
        else if (user_type==='Partner') codes = await api.post(`/users/partners/${id}/get-promocodes/`);
        else codes = await api.get('/referrals/promocodes/');

        commit('setCodes', codes);
      } catch (err) {
      }
      commit('endLoading', 'codes');
    },

    async loadCodesUsed({ commit }, {id, user_type}) {
      commit('setCodesUsed', []);
      commit('startLoading', 'codesUsed');

      try {
        const codes = user_type==='CorpClient' ? await api.get(`/users/corp_clients/${id}/promocodes-used/`) : await api.post(`/users/partners/${id}/get-promocodes/`);

        codes.sort((a,b) => {
          if (a.status === 'valid' && b.status !== 'valid') return 1;
          if (a.status !== 'valid' && b.status === 'valid') return -1;
          if (a.is_default && !b.is_default) return 1;
          if (!a.is_default && b.is_default) return -1;
          return 0;
        })

        commit('setCodesUsed', codes);
      } catch (err) {
      }
      commit('endLoading', 'codesUsed');
    },

    async activateClientPromocode({ commit }, { promocode, distributor, client }) {
      //commit('startLoading', 'codesUsed');
      if (distributor == client) {
        return { error: 'Дистрибьютор и пользователь промокода должны быть разными корпоративными клиентами' }
      }

      try {
        const code = await api.post(`/users/corp_clients/${client}/promocodes-used/`, { promocode, distributor })
        // console.log(code);
        return code;
        // commit('setCodesUsed', codes);
      } catch (err) {
        console.log(err);
        return err.data;
      }
      //commit('endLoading', 'codesUsed');
    },

    // тело, обязательные поля:
    // "package_size":10,
    // "tests_for_distributers": 5,
    // "tests_for_users": 1,
    //  "managed_by": 2
    // необязательные поля:
    //   "promocode": "erbrtjh",
    //   "title": "title",
    //   "date_from": "2023-04-01",
    //   "date_to": "2024-04-01",
    //   "url": "https://ya.ru",
    //   "usage": 3,
    //   "description": "description",
    //   "is_default": true

    async savePromocode({ commit, state, dispatch }, code) {
      commit('startLoading', 'save');
      commit('setError', { type: 'edit', errors: [] });

      try {
        let response;
        if (code.id) {
          response = await api.patch(`/referrals/promocodes/${code.id}/`, code)
        } else {
          response = await api.post(`/referrals/promocodes/`, code)
        }
        dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
        //commit('setCodesUsed', codes);
        return response;
      } catch (err) {
        console.log(err.data);
        commit('setError', { type: 'edit', errors: err.data });
      } finally {
        commit('endLoading', 'save');
      }
    },

    async deletePromocode({ commit, dispatch, state }, code) {
        commit('startLoading', 'delete');
        try {
          await api.delete(`/referrals/promocodes/${code.id}/`);
          dispatch('loadCodes', {id: state.user_id, user_type:state.user_type});
        } catch (err) {
        }
        commit('endLoading', 'delete');
    },

    async setCodeActve({ commit }, {code, active}) {
        commit('startLoading', 'activate');
        const param = {
          date_to: active ? null : moment().format('YYYY-MM-DD')
        }
        try {
          let response = await api.patch(`/referrals/promocodes/${code.id}/`, param)
          code.date_to = param.date_to
          //commit('setCodesUsed', codes);
          return response;
        } catch (err) {
          console.log(err.data);
        } finally {
          commit('endLoading', 'activate');
        }
    },

    async search({ commit }, promocode) {
      commit('startLoading', 'search');
      try {
        let response = await api.post(`/referrals/promocodes/find/`, { promocode })
        return response;
      } catch (err) {
        console.log(err.data);
        return err.data;
      } finally {
        commit('endLoading', 'search');
      }
  },


  },

};

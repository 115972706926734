<template>
    <div class="profile-board profile-card">
      <div class="profile-card-top">
        <div class="profile-title">
          {{title}}
        </div>
        <div class="profile-card-actions">
          <slot name="extraTitle"></slot>
          <a v-if="link || showLinkAnyway" :href="link" @click="$emit('linkClicked')"><img src="@/assets/images/profile/arrowGo.svg" /></a>
          <!-- <v-btn fab class="mb-2" small text _outlined @click="$emit('add')">
            <v-icon large>mdi-arrow-top-right</v-icon>
          </v-btn> -->

        </div>
      </div>
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'ProfileCard',
  components: {
  },
  props: {
    title: String,
    link: String,
    showLinkAnyway: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scored>
@import "src/assets/styles/variables";
</style>

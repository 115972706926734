<template>
    <v-dialog v-model="dialogEditShow" max-width="700px">
      <v-card class="pa-4 pa-sm-8">
         <v-btn class="button-close ma-4" icon @click="dialogEditShow = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 class="text-center">Данные кандидата</h2>
        <form @submit.prevent="editCandidateConfirm" class="mt-6">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <v-text-field label="Фамилия *"
                    :value="editedItem.last_name"
                    @input="$v.editedItem.last_name.$model = upperFirst($event), onInput('last_name')"
                    :error-messages="lastnameErrors"
                    outlined dense />
              </div>
              <div class="col-md-6">
                <v-text-field label="Имя *"
                    :value="editedItem.first_name"
                    @input="$v.editedItem.first_name.$model = upperFirst($event), onInput('first_name')"
                    :error-messages="firstnameErrors"
                    outlined dense />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <v-menu ref="menuBirth" v-model="menuBirth"
                    :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="birthdateFormatted"
                            label="Дата рождения"
                            append-icon="mdi-calendar"
                            readonly
                            format="DD.MM.YYYY"
                            @change="$v.editedItem.date_of_birth.$touch()"
                            @blur="$v.editedItem.date_of_birth.$touch()"
                            :hide-details="!birthdateErrors.length"
                            :error-messages="birthdateErrors"
                            v-bind="attrs"
                            v-on="on"
                            outlined dense/>
                    </template>
                    <v-date-picker v-model="editedItem.date_of_birth"
                          :active-picker.sync="activePicker"
                           scrollable locale="ru-RU"
                           @input="menuBirth=false" first-day-of-week=1>
                    </v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-6">
                <v-select  v-model="editedItem.gender"
                    :items="genders"
                    label="Пол"
                    outlined dense />
                <!-- <span class="text-left" :class="{'error--text':genderErrors.length}">Пол</span>
                <v-radio-group v-model="editedItem.gender" row
                         @change="$v.editedItem.gender.$touch()"
                         :hide-details="!genderErrors.length"
                         :error-messages="genderErrors">
                    <v-radio label="Муж" :value="GENDERS.MALE" />
                    <v-radio label="Жен" :value="GENDERS.FEMALE" />
                </v-radio-group> -->

              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <v-text-field label="Номер телефона"
                        type="tel"
                        v-model="editedItem.phone"
                        @input="$v.editedItem.phone.$touch()"
                        @blur="$v.editedItem.phone.$touch()"
                        :error-messages="phoneErrors"
                        :rules="[phoneNumberRules]"
                        v-mask="'+7(###)-###-##-##'"
                        outlined dense />
              </div>
              <div class="col-md-6">
                <v-text-field label="Город"
                        v-model="editedItem.city"
                        @input="clearError"
                        outlined dense />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <v-text-field label="Адрес эл. почты *"
                        v-model.trim="editedItem.email"
                        @input="clearError"
                        @blur="$v.editedItem.email.$touch()"
                        :error-messages="emailErrors"
                        outlined dense readonly disabled />
              </div>
              <div class="col-md-6">
                <v-combobox
                  label="Должность"
                  v-model="editedItem.position" :items="positions"
                  :loading="loadingPositions.loadPositions"
                  :disabled="loadingPositions.loadPositions"
                  hide-details dense
                  :return-object="false" outlined>
                  <template slot="no-data">
                    <div class="pl-4">Не найдено</div>
                  </template>
                </v-combobox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <v-select v-model="curCompany"
                      :items="companies"
                      @change="changeCompany"
                      label="Компания"
                      :item-text="(item) => item.name"
                      item-value="id"
                      outlined dense return-object
                      :disabled="!$store.getters['auth/isAllowed']('change_manager_of_candidate')"
                    />
              </div>
              <div class="col-md-6">
                    <v-select  v-model="editedItem.managerId"
                      :items="filterManagers"
                      @change="changeManager"
                      label="Менеджер"
                      :item-text="(item) => item.first_name || item.last_name ? item.first_name + ' ' + item.last_name : 'имя не задано'"
                      item-value="id"
                      outlined dense
                      :disabled="!$store.getters['auth/isAllowed']('change_manager_of_candidate')"
                    />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <v-text-field label="Комментарий"
                        v-model="editedItem.comment"
                        @input="clearError"
                        outlined dense />
              </div>
            </div>

            <div v-for="(err, ind) in errors.edit" :key="ind" class="error--text">
              <template v-if="!editedItem.hasOwnProperty(err[0])">
                <div v-for="(e, i) in err[1]" :key="i">{{e}}</div>
              </template>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-6 mx-auto">
              <v-btn type="submit" class="w-100" color="primary" :loading="loading.edit" :disabled="loading.edit">
                Сохранить
              </v-btn>
            </div>
          </div>
        </form>
      </v-card>
    </v-dialog>
</template>

<script>
/*eslint-disable*/

import { mapActions, mapGetters } from 'vuex';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { upperFirst } from 'lodash';
import { firstnameValidation, lastnameValidation } from '@/common/validationHelpers';
import { EMAIL, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';
import { MESSAGES } from '@/common/constants/errorMessages';
import { GENDERS } from '@/common/constants/genders';
import { phoneValidation } from '@/common/validationHelpers';
import { VueMaskDirective } from 'v-mask';

const noCompany = "--"

export default {
  validations: {
    editedItem: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      date_of_birth: {   },
      email: { required, email, maxLength: maxLength(EMAIL.MAX_LENGTH) },
      phone: { phoneValidation },
      city: {  },
      gender: {  },
    },
  },
  directives: {
    mask: VueMaskDirective,
  },
  data: () => ({
    editedItem: {
      id: 0,
      last_name: '',
      first_name: '',
      date_of_birth: '',
      gender: '',
      phone: '',
      city: '',
      email: '',
      position: '',
      comment: '',
      managerId: 0,
    },
    GENDERS,
    genders: [
      { text: 'М', value: GENDERS.MALE },
      { text: 'Ж', value: GENDERS.FEMALE },
    ],
    menuBirth: false,
    activePicker: null,
    curCompany: null,
  }),
  watch: {
    async dialogEditShow(newVal) {
      // По дефолту в positions есть одна запись по этому < 2
      // По возможности надо заморочиться и найти почему метод срабатывает по 2 раза с одинаковыми состояниями new и old
      if (newVal) {
        // this.editedItem = cloneDeep(this.editedCandidate);
        this.editedItem = (
          ({ id, last_name, first_name, date_of_birth, gender, phone, city, email, position, comment }) =>
          ({ id, last_name, first_name, date_of_birth, gender, phone, city, email, position, comment })
          )(this.editedCandidate);
        this.$set(this.editedItem, 'managerId', this.editedCandidate.manager?.id)
        this.editedItem.managerId = this.editedCandidate.manager?.id
        this.editedItem.phone = this.formatPhoneNumber(this.editedItem.phone)
      }
      if (this.positions?.length < 2 && !this.loadingPositions.loadPositions) {
        await this.loadPositions();
      }
      if (this.managers?.length < 2 && !this.loadingManagers.load) {
        await this.loadManagers();
      }
      const manager = this.managers.find( el => el.id === this.editedItem.managerId)
      this.curCompany = manager && manager.customer ? manager.customer.company  : noCompany
    },
    menuBirth(val) {
      if (val) {
        setTimeout(() => {
          this.activePicker = 'YEAR';
        });
      }
    },

  },
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      errors: 'users/candidates/errors',
      editedCandidate: 'users/candidates/editedCandidate',
      positions: 'positions/positions/positions',
      loadingPositions: 'positions/positions/loading',
      managers: 'users/managers/managers',
      loadingManagers: 'users/managers/loading',
    }),
    dialogEditShow: {
      get() {
        return this.$store.getters['users/candidates/dialogEditShow'];
      },
      set(val) {
        if (!val) {
          this.$v.$reset();
        }
        this.setDialogEditShow(val);
      },
    },
    phoneNumberRules() {
      return [
        value => !!value || 'Номер телефона обязателен для заполнения',
        value => /^\d+$/.test(value) || 'Номер телефона должен содержать только цифры'
      ];
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.last_name.$dirty) return errors;
      if (!this.$v.editedItem.last_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.editedItem.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.edit.find((err) => err[0] === 'last_name')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.first_name.$dirty) return errors;
      if (!this.$v.editedItem.first_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.editedItem.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.edit.find((err) => err[0] === 'first_name')?.[1] ?? errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      if (!this.$v.editedItem.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.editedItem.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.edit.find((err) => err[0] === 'email')?.[1] ?? errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editedItem.phone.$dirty) return errors;
    //   if (!this.$v.editedItem.phone.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.phone.phoneValidation) errors.push(MESSAGES.INCORRECT_PHONE);

      return this.errors.edit.find((err) => err[0] === 'phone')?.[1] ?? errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.editedItem.gender.$dirty) return errors;
      if (!this.$v.editedItem.gender.required) errors.push(MESSAGES.REQUIRED);

      return this.errors.edit.find((err) => err[0] === 'gender')?.[1] ?? errors;
    },
    birthdateErrors() {
      const errors = [];
      if (!this.$v.editedItem.date_of_birth.$dirty) return errors;
      // if (!this.$v.editedItem.date_of_birth.required) errors.push(MESSAGES.REQUIRED);

      return this.errors.edit.find((err) => err[0] === 'date_of_birth')?.[1] ?? errors;
    },
    birthdateFormatted() {
      return this.editedItem.date_of_birth ? this.$moment(this.editedItem.date_of_birth).format('DD.MM.YYYY') : '';
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.editedItem.city.$dirty) return errors;
      if (!this.$v.editedItem.city.required) errors.push(MESSAGES.REQUIRED);

      return this.errors.find((err) => err[0] === 'city')?.[1] ?? errors;
    },
    filterManagers() {
      const arr = this.curCompany === noCompany
        ? this.managers.slice()
        : this.managers.filter( el => el.customer &&  el.customer.company === this.curCompany );

      arr.sort(function (item1, item2) {
        const a = item1.first_name || item1.last_name ? item1.first_name + ' ' + item1.last_name : 'имя не задано';
        const b = item2.first_name || item2.last_name ? item2.first_name + ' ' + item2.last_name : 'имя не задано';
        return a.toLowerCase().localeCompare(b.toLowerCase());
      });
      return arr;
    },
    companies() {
      return this.managers
        .map(item => item.customer ? item.customer.company : null)
        .filter(item => item)
        .concat([noCompany])
        .sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
    }
  },
  methods: {
    ...mapActions({
      clearError: 'users/candidates/clearError',
      setDialogEditShow: 'users/candidates/setDialogEditShow',
      saveCandidate: 'users/candidates/saveCandidate',
      loadPositions: 'positions/positions/loadPositions',
      loadManagers: 'users/managers/loadManagers',
    }),
    onInput() {
      if (this.errors.edit.length) this.clearError();
    },
    formatPhoneNumber(number) {
      // Преобразование числа в строку и добавление нужного формата
      return `+7(${number.toString().slice(1, 4)})-${number.toString().slice(4, 7)}-${number.toString().slice(7, 9)}-${number.toString().slice(9)}`;
    },
    editCandidateConfirm() {
      if (this.loading.edit) return;
      this.$v.$touch();
      if (this.editedItem && !this.$v.$invalid) {
        const form = {
          ...this.editedItem,
          phone: this.editedItem.phone ? this.editedItem.phone.match(/\d+/g).join([]) : '',
          position: this.editedItem.position,
          manager: this.editedItem.managerId,
        };
        this.saveCandidate(form).then(() => {
          this.dialogEditShow = false;
        });
      }
    },
    changeCompany() {
      const manager = this.filterManagers.find(el => el.id === this.editedItem.managerId)
      if (!manager)
        this.editedItem.managerId = 0
    },
    changeManager()  {
      if (this.editedItem.managerId) {
        const manager = this.managers.find( el => el.id === this.editedItem.managerId)
        this.curCompany = manager && manager.customer ? manager.customer.company  : noCompany
      }
    },
    upperFirst,
  },
};

</script>

import api from '@/common/api';
import Vue from 'vue';
import { cloneDeep } from 'lodash';

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    edit: false,
    loadResults: false,
    manager: false,
    editMail: false,
  },
  errors: {
    load: [],
    create: [],
    loadResults: [],
    manager: [],
    edit: [],
  },
  count: 0,
  managers: [],
  manager: null,
  selected: [],
  editedManager: null,
  dialogCreateShow: false,
  dialogEditShow: false,
});

export default {
  namespaced: true,
  state: cloneDeep(initialState()),
  actions: {
    async loadManagers({ commit }) {
      commit('loadManagersRequest');
      try {
        const response = await api.get('/users/managers/');
        commit('setManagers', response);
        commit('setCount', response.length);
        commit('loadManagersSuccess');
      } catch (err) {
        commit('loadManagersFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadManagersResults({ commit, getters }) {
      commit('loadManagersResultsRequest');
      const data = {
        candidates: getters.selected,
      };
      try {
        const response = await api.post('/users/candidates/get-test-result/', data);
        commit('loadManagersResultsSuccess');
        return response;
      } catch (err) {
        commit('loadManagersResultsFailure', Object.entries(err.data));
        throw err;
      }
    },

    async createManager({ commit, dispatch }, data) {
      commit('createManagerRequest');
      try {
        await api.post('/users/managers/', data);
        commit('createManagerSuccess');
        dispatch('loadManagers');
      } catch (err) {
        commit('createManagerFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadManager({ commit }, id) {
      commit('loadManagerRequest');
      let manager = null;
      const counters = null;
      try {
        manager = await api.get(`/users/managers/${id}/`);
      } catch (err) {
        commit('loadManagerFailure', Object.entries(err.data));
        throw err;
      }
      // try {
      //   counters = await api.get(`/test-amount/${id}/`);
      // } catch (err) {
      //   console.log(err);
      // }
      commit('loadManagerSuccess', { manager, counters });
    },
    async saveManager({ commit, dispatch }, data) {
      commit('editManagerRequest');
      try {
        console.log(data);
        await api.patch(`/users/managers/${data.id}/`, data);
        commit('editManagerSuccess');
        dispatch('loadManagers');
        dispatch('loadManager', data.id);
      } catch (err) {
        commit('editManagerFailure', Object.entries(err.data));
        throw err;
      }
    },
    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
    setDialogCreateShow({ commit }, bool) {
      if (!bool) commit('setEditedManager', null);
      commit('setDialogCreateShow', bool);
    },
    setDialogEditShow({ commit }, bool) {
      if (!bool) commit('setEditedManager', null);
      commit('setDialogEditShow', bool);
    },
    async setManagerSendMail({ commit }, data) {
      commit('editMailRequest');
      try {
        const response = await api.patch(`/users/managers/${data.id}/`, data);
        commit('editMailSuccess');
        commit('setSendMail', response);
      } catch (err) {
        commit('editMailFailure', Object.entries(err.data));
        throw err;
      }
    },

  },
  mutations: {
    loadManagersRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadManagersSuccess(state) {
      state.loading.load = false;
    },
    loadManagersFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    loadManagersResultsRequest(state) {
      state.loading.loadResults = true;
      state.errors.loadResults = [];
    },
    loadManagersResultsSuccess(state) {
      state.loading.loadResults = false;
    },
    loadManagersResultsFailure(state, error) {
      state.errors.loadResults = error;
      state.loading.loadResults = false;
    },

    createManagerRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createManagerSuccess(state) {
      state.loading.create = false;
    },
    createManagerFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    loadManagerRequest(state) {
      state.loading.manager = true;
      state.errors.manager = [];
    },

    loadManagerSuccess(state, { manager, counters }) {
      Vue.set(manager, 'counters', counters);
      state.manager = manager;
      state.loading.manager = false;
    },

    loadManagerFailure(state, error) {
      state.errors.manager = error;
      state.loading.manager = false;
    },

    editManagerRequest(state) {
      state.loading.edit = true;
      state.errors.edit = [];
    },
    editManagerSuccess(state) {
      state.loading.edit = false;
    },
    editManagerFailure(state, error) {
      state.errors.edit = error;
      state.loading.edit = false;
    },

    setCount(state, count) {
      state.count = count;
    },
    clearSelected(state) {
      state.selected = [];
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setManagers(state, managers) {
      state.managers = managers;
    },

    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.managers.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    setEditedManager(state, manager) {
      state.editedManager = manager;
    },
    setDialogCreateShow(state, bool) {
      state.dialogCreateShow = bool;
    },
    setDialogEditShow(state, bool) {
      state.dialogEditShow = bool;
    },

    setSendMail(state, data) {
      state.manager.send_mail = data.send_mail;
    },
    editMailRequest(state) {
      state.loading.editMail = true;
      state.errors.edit = [];
    },
    editMailSuccess(state) {
      state.loading.editMail = false;
    },
    editMailFailure(state, error) {
      state.errors.edit = error;
      state.loading.editMail = false;
    },
    reset(state) {
      // console.log('state1', state);
      // console.log('initialState()', initialState());
      Object.assign(state, initialState());
      // console.log('state2', state);
      // console.log('state2', this);
    }

  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    managers: (state) => state.managers,
    manager: (state) => state.manager,
    selected: (state) => state.selected,
    editedManager: (state) => state.editedManager,
    dialogCreateShow: (state) => state.dialogCreateShow,
    dialogEditShow: (state) => state.dialogEditShow,
  },
};

<template>
    <div class="test-from-editor">
        <!-- {{testData}} -->
        <h2 class="question__title mb-6" :class="{'question__compact': question.length>200}" v-html="question"></h2>
        <div class="d-flex flex-column">
            <form @submit.prevent="onClickAnswer">
                <v-select v-model="answer"  label="Выберите ответ"
                    :items="testData.answer_options" item-text="text" item-value="id"
                    @change="onChange" outlined return-object
                />
                <v-btn class="btn-disabled-primary mb-4 mx-auto" type="submit" x-large color="primary" :disabled="!answer">
                    Продолжить
                </v-btn>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TestSimpleQuestion',
    data() {
        return {
            answer: 0
        };
    },
    computed: {
        ...mapGetters({
            testData: 'testing/testing/testData',
            loading: 'testing/testing/loading',
            selectedAnswer: 'testing/testing/selectedAnswer',
        }),
        question() {
            return `<span>${this.testData.question.text}<span>`;
        },
    },
    methods: {
        ...mapActions({
            sendAnswer: 'testing/testing/sendAnswer',
            setSelectedAnswer: 'testing/testing/setSelectedAnswer',
        }),
        onChange(answer) {
            console.log(answer);
            this.setSelectedAnswer(answer);
        },
        onClickAnswer() {
            this.sendAnswer();
        },
    },
};
</script>

<style lang="scss">
</style>

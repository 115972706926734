<template>
  <div class="bg-main d-flex flex-column h-100">
    <HeaderAuth />
    <div class="d-flex justify-center">
      <div class="auth-card text-center">
        <h2 class="auth-card__title mb-8">Авторизация</h2>
        <form @submit.prevent="submit">
          <v-text-field label="Логин"
                        v-model="username"
                        @input="clearError"
                        @blur="$v.username.$touch()"
                        :error-messages="usernameErrors"
                        :error="!!errors.find((err) => err[0] === 'non_field_errors')"
                        outlined />
          <v-text-field label="Пароль"
                        v-model="password"
                        @input="onInput('password')"
                        @blur="$v.password.$touch()"
                        :error-messages="passwordErrors"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPass ? 'text' : 'password'"
                        :hide-details="!passwordErrors.length"
                        @click:append="showPass = !showPass"
                        :error="!!errors.find((err) => err[0] === 'non_field_errors')"
                        outlined />

          <div class="text-right mt-2 mb-6">
            <router-link :to="{name: 'reset-password'}" class="text-decoration-none font-sm text-light">
              Забыли пароль?
            </router-link>
          </div>

          <div v-for="(err, ind) in errors" :key="ind" class="error--text mt-5">
            <template v-if="Array.isArray(err[1])">
              <div v-for="(e, i) in err[1]" :key="i" class="font-xs">{{e}}</div>
            </template>
          </div>

          <v-btn type="submit" class="btn-disabled-primary mt-5 w-100"
                 x-large color="primary" :loading="loading"
                 :outlined="loading || $v.$invalid" :disabled="loading || $v.$invalid">
            Продолжить
          </v-btn>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required, email, maxLength,
} from 'vuelidate/lib/validators';
import { mapActions, mapGetters } from 'vuex';
import { MESSAGES } from '@/common/constants/errorMessages';
import HeaderAuth from '@/components/headers/HeaderAuth';
// import { ADMIN, MANAGER } from '@/common/constants/userTypes';
import { EMAIL } from '@/common/constants/validations';
import { startSpacesValidation } from '@/common/validationHelpers';

export default {
  name: 'Login',
  metaInfo: {
    title: 'Авторизация',
  },
  components: {
    HeaderAuth,
  },
  validations: {
    username: {
      required, email, maxLength: maxLength(EMAIL.MAX_LENGTH),
    },
    password: { required, startSpacesValidation },
  },
  data: () => ({
    showPass: false,
    username: '',
    password: '',
  }),
  computed: {
    ...mapGetters({
      user: 'auth/user',
      loggedIn: 'auth/loggedIn',
      loading: 'auth/loading',
      errors: 'auth/errors',
    }),
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;
      if (!this.$v.username.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.username.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.username.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.startSpacesValidation) errors.push(MESSAGES.START_SPACES);
      if (!this.$v.password.required) errors.push(MESSAGES.REQUIRED);
      return errors;
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      clearError: 'auth/clearError',
    }),
    onInput(field) {
      this.$v[field].$touch();
      if (this.errors.length) this.clearError();
    },
    async submit() {
      if (this.loading) return;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.login({ username: this.username, password: this.password });
        this.$router.push({ name: 'profile' }).catch(() => {});
      }
    },
  },
  watch: {
    user() {
      // if (this.user.user_type === ADMIN) {
      //   return this.$router.push({ name: 'managers' }).catch(() => {});
      // }
      // if (this.user.user_type === MANAGER) {
      //   return this.$router.push({ name: 'candidates' }).catch(() => {});
      // }
      // this.$router.push({ name: 'profile' }).catch(() => {});
    },
  },
  beforeDestroy() {
    this.clearError();
  },
};
</script>

<template>
  <div>
    <div v-if="loading.partner" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </div>

    <BackBtn />
    <BreadCrumbs :items="breadcrumbs" />

    <ProfileTopPanel v-if="partner"
      userTypeName="Партнер"
      :name="partner.last_name + ' ' + partner.first_name"
      :city="partner.city"
      :phone="partner.phone"
      :email="partner.email"
      :company="partner.company"
      permssionEdit="edit_partner"
      permssionDelete="delete_partner"
      whoDelete="партнера"
      @delete="onDelete()" @edit="onEdit()"
    >
      <div class="profile-header-item" style="margin-top:0">
        <v-switch v-if="!isProfile && $store.getters['auth/isAllowed']('edit_partner')"
          :input-value="partner.send_mail" @change="changeSendMail" @loading="loading.editMail"
          class="v-input--reverse profile-switch ml-4" hide-details :color="partner.send_mail ? 'success' : 'error'"
        >
          <div slot="label" :class="partner.send_mail ? 'success--text' : 'error--text'">Почта</div>
        </v-switch>
      </div>

    </ProfileTopPanel>
    <div class="row">
      <div class="col-12 col-md-6">
        <ClientProfileCardRef class="mb-5" />
        <ClientProfileCardCodesUsed class="mb-5" />
        <ProfileCardSearch class="mb-5" />
        <ProfileCardCalc />
      </div>
      <div class="col-12 col-md-6">
        <ClientProfileCardCounters
          testType="MMPI"
          class="mb-5"
          v-if="partner && ( $store.getters['auth/isAllowed']('view_stockamount_partner') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="partner"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('partner') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_partner') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
        <ClientProfileCardCounters
          testType="MINIMULT"
          class="mb-5"
          v-if="partner && ( $store.getters['auth/isAllowed']('view_stockamount_partner') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="partner"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('partner') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_partner') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
        <ClientProfileCardCounters
          testType="CONSTRUCTOR"
          class="mb-5"
          v-if="partner && ( $store.getters['auth/isAllowed']('view_stockamount_partner') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="partner"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('partner') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_partner') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />

      </div>
    </div>
    <PartnerEditDialog />
    <AddLicensesDialog v-model="dialogAddLicencesShow" :toId="+userId" toType='partner' @success="reload()" />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ProfileTopPanel from '@/components/common/ProfileTopPanel';
import PartnerEditDialog from '@/components/partners/PartnerEditDialog';
import AddLicensesDialog from '@/components/admins/AddLicensesDialog.vue';
import ClientProfileCardRef from '@/components/clients/profile/ProfileCardRef';
import ClientProfileCardCodesUsed from '@/components/clients/profile/ProfileCardCodesUsed';
import ClientProfileCardCounters from '@/components/clients/profile/ProfileCardCounters';
import ProfileCardSearch from '@/components/partners/profile/ProfileCardSearch';
import ProfileCardCalc from '@/components/admins/profile/ProfileCardCalc';

export default {
  name: 'ProfilePartner',
  components: {
    PartnerEditDialog,
    AddLicensesDialog,
    ClientProfileCardRef,
    ClientProfileCardCodesUsed,
    ProfileTopPanel,
    ClientProfileCardCounters,
    ProfileCardSearch,
    ProfileCardCalc
  },
  props: ['userId'],
  provide() {
    return {
      userType: 'Partner',
      userId: this.userId,
    };
  },
  inject: {
    isProfile: {
      default: false
    }
  },
  data() {
    return {
      dialogAddLicencesShow: false,
    };
  },
  computed: {
    ...mapGetters({
      partner: 'users/partners/partner',
      loading: 'users/partners/loading',
    }),
    breadcrumbs() {
      if (!this.partner) return [];
      return this.isProfile ? [
        { title: 'Личный кабинет', url: '/' },
      ] : [
        { title: 'Партнеры', url: '/partners/' },
        { title: `${this.partner.last_name} ${this.partner.first_name}`, url: `/partners/${this.partner.id}` },
      ];
    },
  },
  methods: {
    ...mapActions({
      loadPartner: 'users/partners/loadPartner',
      setDialogEditShow: 'users/partners/setDialogEditShow',
      setPartnerSendMail: 'users/partners/setPartnerSendMail',
    }),
    ...mapMutations({
      setEditedPartner: 'users/partners/setEditedPartner',
    }),
    onEdit() {
      this.setEditedPartner({ ...this.partner });
      this.setDialogEditShow(true);
    },
    onDelete() {
    },
    reload() {
      this.loadPartner(this.userId);
    },
    changeSendMail(value) {
      this.setPartnerSendMail({ send_mail: !!value, id: this.partner.id, email: this.partner.email });
    },

  },
  created() {
    this.loadPartner(this.userId);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
</style>

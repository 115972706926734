import { render, staticRenderFns } from "./ProfileCandidate.vue?vue&type=template&id=3cb7a8e6"
import script from "./ProfileCandidate.vue?vue&type=script&lang=js"
export * from "./ProfileCandidate.vue?vue&type=script&lang=js"
import style0 from "./ProfileCandidate.vue?vue&type=style&index=0&id=3cb7a8e6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VProgressCircular,VSwitch,VTabItem,VTabsItems})

import { render, staticRenderFns } from "./ProfilePartner.vue?vue&type=template&id=63652cb6&scoped=true"
import script from "./ProfilePartner.vue?vue&type=script&lang=js"
export * from "./ProfilePartner.vue?vue&type=script&lang=js"
import style0 from "./ProfilePartner.vue?vue&type=style&index=0&id=63652cb6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63652cb6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VProgressCircular,VSwitch})

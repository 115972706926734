import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import { ADMIN, CANDIDATE, MANAGER } from '@/common/constants/userTypes';

// Layouts
import AuthLayout from '@/layouts/AuthLayout';
import DashboardLayout from '@/layouts/DashboardLayout';

// Pages
import Home from '@/views/Home';
import NoAccess from '@/views/NoAccess';
import Page404 from '@/views/404';
import Test from '@/views/Test';

import Login from '@/views/Login';
import Profile from '@/views/Profile';

const Managers = () => import('@/views/managers/Managers');
const Manager = () => import('@/views/managers/_id');

const Clients = () => import('@/views/clients/Clients');
const Client = () => import('@/views/clients/_id');
const ClientPromocodesPage = () => import('@/views/clients/ClientPromocodesPage');
const ClientCalcCompliancePage = () => import('@/views/clients/ClientCalcCompliancePage');

const Candidates = () => import('@/views/candidates/Candidates');
const Candidate = () => import('@/views/candidates/_id');
const CandidateReportsPage = () => import('@/views/candidates/CandidateReportsPage');

const Partners = () => import('@/views/partners/Partners');
const Partner = () => import('@/views/partners/_id');
const PartnerPromocodesPage = () => import('@/views/partners/PartnerPromocodesPage');

const Profgrams = () => import('@/views/profgrams');
const Analitics = () => import('@/views/analytics');
const Admins = () => import('@/views/admins/Admins');
const Admin = () => import('@/views/admins/_id');
const Services = () => import('@/views/Services');
const Support = () => import('@/views/Support');
const Access = () => import('@/views/Access');
// const FullTest = () => import('@/views/FullTest');
// const ShortTest = () => import('@/views/ShortTest');
// const PsychoType = () => import('@/views/psycho/PsychoType');
const Reports = () => import('@/views/Reports');

const ResetPassword = () => import('@/views/ResetPassword');
const UpdatePassword = () => import('@/views/UpdatePassword');

const CreateByRef = () => import('@/views/psycho/CreateByRef');

const RefProgram = () => import('@/views/refprogram/RefProgram');
const RefLinks = () => import('@/views/refprogram/RefLinks');
const Promocodes = () => import('@/views/Promocodes');
const CalcCompliancePage = () => import('@/views/CalcCompliancePage');

const EditTests = () => import('@/views/editTests/index.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    // redirect: '/welcome/index.html',
    redirect: { name: 'login' },
  },
  {
    path: '/',
    component: AuthLayout,
    name: 'auth-layout',
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { onlyPublic: true },
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: { onlyPublic: true },
      },
      {
        path: '/update-password',
        name: 'update-password',
        component: UpdatePassword,
        meta: { onlyPublic: true },
      },
      {
        path: '/test/:token',
        name: 'test',
        component: Test,
      },
      {
        path: '/ref/:ref',
        name: 'reflink',
        component: CreateByRef,
      },
    ],
  },
  {
    path: '/',
    name: 'dashboard-layout',
    component: DashboardLayout,
    meta: { allowed: [ADMIN, MANAGER, CANDIDATE] },
    children: [
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
      },
      {
        path: 'managers',
        name: 'managers',
        component: Managers,
        meta: { allowed: [MANAGER, ADMIN], permission: ['bookmark_managers'] },
      },
      {
        path: 'managers/:id',
        name: 'managers-id',
        component: Manager,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_manager'] },
      },

      {
        path: 'clients',
        name: 'clients',
        component: Clients,
        meta: { allowed: [MANAGER, ADMIN], permission: ['bookmark_corporate_clients'] },
      },
      {
        path: 'clients/:id',
        name: 'clients-id',
        component: Client,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_corpclient'] },
      },
      {
        path: 'clients/:id/promocodes',
        name: 'clients-id-promocodes',
        component: ClientPromocodesPage,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_corpclient'] },
      },
      {
        path: 'clients/:id/compliance',
        name: 'clients-id-compliance',
        component: ClientCalcCompliancePage,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_corpclient'] },
      },

      {
        path: 'candidates',
        name: 'candidates',
        component: Candidates,
        meta: { allowed: [ADMIN, MANAGER], permission: ['bookmark_candidates'] },
      },
      {
        path: 'candidates/:id',
        name: 'candidates-id',
        component: Candidate,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_candidate'] },
      },
      {
        path: 'candidates/:id/reports/:reportType',
        name: 'candidates-id-report',
        component: CandidateReportsPage,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_candidate'] },
      },

      {
        path: 'profgrams',
        name: 'profgrams',
        component: Profgrams,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['bookmark_positions_profiles'] },
      },

      {
        path: 'partners',
        name: 'partners',
        component: Partners,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['bookmark_partners'] },
      },
      {
        path: 'partners/:id',
        name: 'partners-id',
        component: Partner,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_partner'] },
      },
      {
        path: 'partners/:id/promocodes',
        name: 'partners-id-promocodes',
        component: PartnerPromocodesPage,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_partner'] },
      },

      {
        path: 'admins',
        name: 'admins',
        component: Admins,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['bookmark_administrators'] },
      },
      {
        path: 'admins/:id',
        name: 'admins-id',
        component: Admin,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['bookmark_administrators'] },
      },
      {
        path: 'services',
        name: 'services',
        component: Services,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['bookmark_services'] },
      },
      {
        path: 'support',
        name: 'support',
        component: Support,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['bookmark_support'] },
      },
      {
        path: 'access',
        name: 'access',
        component: Access,
        meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['bookmark_accesscontrol'] },
      },
      // {
      //   path: '/tests',
      //   name: 'tests',
      //   component: FullTest,
      // },
      {
        path: '/edit-tests',
        name: 'edit-tests',
        component: EditTests,
        meta: { permission: 'view_instrumentset' },
      },
      {
        path: 'refprogram',
        name: 'refprogram',
        component: RefProgram,
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
      },
      {
        path: 'reflinks',
        name: 'reflinks',
        component: RefLinks,
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
      },
      {
        path: 'analitics',
        name: 'analitics',
        component: Analitics,
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
      },
      {
        path: 'promocodes',
        name: 'promocodes',
        component: Promocodes,
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
      },
      {
        path: 'compliance',
        name: 'compliance',
        component: CalcCompliancePage,
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
        // meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_candidate'] },
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
      },
      {
        path: 'reports/:reportType',
        name: 'reports-id',
        component: Reports,
        meta: { allowed: [MANAGER, ADMIN], permission: 'bookmark_personal_cabinet' },
        // meta: { allowed: [ADMIN, MANAGER, CANDIDATE], permission: ['view_candidate'] },
      },
      { path: '/noaccess', name: 'noaccess', component: NoAccess, },
      { path: '*', name: '404', component: Page404, },
    ],
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const token = store.getters['auth/token'];
  let loggedIn = store.getters['auth/loggedIn'];

  //  console.log(token, loggedIn, to);

  if (!loggedIn) {
    if (token) {
      try {
        await store.dispatch('auth/loadMe');
        loggedIn = store.getters['auth/loggedIn'];
      } catch (e) {
        if (to.meta.permission) return next({ name: 'login' });
      } finally {
        store.commit('auth/setInitialized');
      }
    }
  }

  if (!loggedIn && to.meta.permission) return next({ name: 'login' });

  if (loggedIn && (to.meta?.onlyPublic || to.path === '')) return next({ name: 'profile' });

  if (loggedIn) {
    if (!to.meta.permission || store.getters['auth/isAllowed'](to.meta.permission)) {
      return next();
    }
    // console.log(to);
    return next({ name: 'noaccess' });
    // return next({ name: 'dashboard-layout-root' });
  }

  next();
});

export default router;

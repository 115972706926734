<template>
  <div  v-click-outside="closePanel" class="right-panel" :class="{show: drawerShow}">
    <div class="right-panel__title">
      Настройка колонок
    </div>
    <div class="right-panel__subtitle">
      Вы можете скрывать или отображать необходимые колонки таблицы
    </div>
    <div class="right-panel__list-wrapper">
      <v-list>
        <template v-for="item in columns" >
          <v-list-item v-if="!item.children" :key="item.key" class="right-panel__list-item">
            <v-list-item-title class="right-panel__row">
              <div>{{item.title}}</div>
              <v-switch :input-value="isChecked(item.key)" @change="onChange(item.key, $event)"
                hide-details inset class="item-toggle" :ripple="false"
              />
            </v-list-item-title>
          </v-list-item>

          <v-list-group v-if="item.children" :value="false" :key="item.key" _sub-group class="right-panel__list-item" no-ripple>
            <template v-slot:activator>
              <v-list-item-title class="right-panel__row">
                <div>{{item.title}}</div>
                <v-switch :input-value="isChecked(item.key)" @change="onChange(item.key, $event)" @click.stop=""
                  hide-details inset class="item-toggle" :ripple="false"
                />
              </v-list-item-title>
            </template>
            <v-list-item  v-for="child in item.children" :key="child.key" class="right-panel__list-item">
              <v-list-item-title class="right-panel__row ml-4">
                <div>{{child.title}}</div>
                <v-switch :input-value="isChecked(child.key)" @change="onChange(child.key, $event)"
                  hide-details inset class="item-toggle" :ripple="false"
                />
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>

      </v-list>
    </div>

    <div class="right-panel__row mt-7">
      <v-btn class="right-panel__btn" @click="closePanel">Закрыть</v-btn>
      <v-btn class="right-panel__btn" color="primary" @click="save">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapGetters } from 'vuex';
import FlatToggle from '@/components/ui/FlatToggle';
import { loadColumnsSetup, saveColumnsSetup } from '@/common/exportExcel/ExcelColumnsStorage';

export default {
  props: ['columns', 'columnsKey'],
  components: {
    FlatToggle
  },
  data: () => ({
    panelOpened: false,
    columnsData: {},
  }),
  watch: {
    async drawerShow(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.panelOpened = true
        }, 300)
        await this.load();
      }
      else
        this.panelOpened = false
    },
  },

  computed: {
    ...mapGetters({
      showPanelColumns: 'notifications/showPanelColumns',
    }),
    drawerShow: {
      get() {
        return this.showPanelColumns===this.columnsKey;
      },
      set(val) {
        this.showPanelColumns(val);
      }
    },
  },
  methods: {
    ...mapMutations({
      setShowPanelColumns: 'notifications/setShowPanelColumns',
    }),
    closePanel() {
      if (this.panelOpened)
        this.setShowPanelColumns(false)
    },
    isChecked(key) {
      return this.columnsData[key]
    },
    onChange(key, $event) {
      this.columnsData[key] = $event ? true : false;
    },
    load() {
      this.columnsData = loadColumnsSetup(this.columnsKey, this.columns)
    },
    save() {
      saveColumnsSetup(this.columnsKey, this.columnsData);
      this.closePanel();
    }
  }
}
</script>

<style>


.right-panel__row {
  display: flex;
  color: #25325F;
  font-size: 14px !important;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
}

.right-panel__list-item {
  margin: 10px 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.right-panel .v-list-group__header{
  margin: 10px 0;
  padding-left: 0 !important;
  padding-right: 5px !important;
}

.right-panel .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon
{
  min-width: 40px;
  margin-left: 0 !important;
}

.right-panel .item-toggle {
  margin-top: -3px;
  margin-left: 5px;
}

.right-panel .v-list-item--active
{
  background: none;
}

.right-panel__list-wrapper {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

</style>

<template>
    <div class="test-from-editor">
        <!-- {{answers}} -->
        <h2 class="question__title mb-4" :class="{'question__compact': question.length>200}" v-html="question"></h2>
        <div>
            <v-checkbox v-model="answers" :value="answer.id" v-for="answer in testData.answer_options" :key="answer.id"
                :label="answer.text" hide-details
            />
        </div>
        <form @submit.prevent="onClickAnswer">
            <v-btn class="btn-disabled-primary mt-10 mx-auto" type="submit" x-large color="primary" >
                Продолжить
            </v-btn>
        </form>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TestSimpleQuestion',
    data() {
        return {
            answers: []
        };
    },
    computed: {
        ...mapGetters({
            testData: 'testing/testing/testData',
            loading: 'testing/testing/loading',
            selectedAnswer: 'testing/testing/selectedAnswer',
        }),
        question() {
            return `<span>${this.testData.question.text}<span>`;
        },
    },
    methods: {
        ...mapActions({
            sendAnswerCheckbox: 'testing/testing/sendAnswerCheckbox',
            setSelectedAnswer: 'testing/testing/setSelectedAnswer',
        }),
        onClickAnswer() {
            this.setSelectedAnswer(this.answers);
            this.sendAnswerCheckbox();
        },
    },
};
</script>

<style lang="scss">
</style>

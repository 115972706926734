const initialState = () => ({
  notifications: [],
  showPanelColumns: false,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    add({ commit }, payload) {
      commit('add', payload);
    },
    remove({ commit }, payload) {
      commit('remove', payload);
    },
  },
  mutations: {
    add(state, data) {
      state.notifications.push({ ...data, id: Date.now() });
    },
    remove(state, id) {
      state.notifications = state.notifications.filter((n) => n.id !== id);
    },
    setShowPanelColumns(state, data) {
      state.showPanelColumns = data;
    },
  },
  getters: {
    notifications: (state) => state.notifications,
    showPanelColumns: (state) => state.showPanelColumns,
  },
};

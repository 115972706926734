<template>
    <v-dialog v-model="dialogEditShow" max-width="700px">
      <v-card class="pa-4 pa-sm-8">
        <v-btn class="button-close ma-4" icon @click="dialogEditShow=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h2 v-if="title" class="text-center">{{title}}</h2>
        <div :class="{'text-center': centerText}" v-html="text"></div>
        <div class="row mt-3">
            <div class="col-md-6 mx-auto">
              <v-btn class="w-100" color="primary" @click="dialogEditShow=false"> Закрыть </v-btn>
           </div>
         </div>
      </v-card>
    </v-dialog>
</template>
<script>
/*eslint-disable*/
    export default {
    model: {
       prop: 'value',
       event: 'input'
    },
    props: {
      value: Boolean,
      text: String,
      title: String,
      centerText: Boolean,
    },
    data: () => ({
    }),
    watch: {
      async dialogEditShow(newVal) {
        if (newVal) {
        }
      }
    },
    computed: {
      dialogEditShow: {
        get() {
          return this.value
        },
        set(val) {
          this.$emit('input', false);
        }
      },
    },
    methods: {
    }
  };
  </script>

export const MESSAGES = {
  REQUIRED: 'Обязательное поле',
  MIN_VALUE: (v) => `Значение должно быть больше ${v}`,
  MIN_LENGTH: (length) => `Минимум ${length} символов`,
  MAX_LENGTH: (length) => `Максимум ${length} символов`,
  INCORRECT_FIRSTNAME: 'Некорректное имя',
  INCORRECT_LASTNAME: 'Некорректная фамилия',
  INCORRECT_EMAIL: 'Некорректный адрес эл. почты',
  INCORRECT_PHONE: 'Некорректный номер телефона',
  EMAIL_MATCH: 'Не существует пользователя с таким адресом эл. почты',
  START_SPACES: 'Нельзя использовать пробел в начале',
  EMAIL_DOES_NOT_EXIST: 'С указанным адресом электронной почты не связан ни один активный пользователь',
  CHAPTER_ALREADY_EXISTS: 'Раздел с таким именем уже существует',
  PROF_ALREADY_EXISTS: 'Профессия с таким именем уже существует',
  INCORRECT_PERCENT: 'Значение должно быть в диапазоне от 0 до 100',
  PROF_CHAPTERS_EMPTY: 'Выберите хотя бы один раздел',
  PROF_INTERESTS_EMPTY: 'Выберите хотя бы один интерес',
  PROF_COMPETS_EMPTY: 'Выберите хотя бы одну компетенцию',
  PROF_DIA_EMPTY: 'Укажите диапазон значений',
  PROF_SUMM: 'Стоимость должна быть выше 1000',
  PROF_INTERESTS_NUMBER: 'Число интересов в расчете должно быть более 1',
  PROF_WEIGHT: 'Сумма весов должна быть равна 100',
};

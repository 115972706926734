<template>
    <div class="test-from-editor">
        <h2 class="question__title mb-8 ">Неизвестный тип вопроса</h2>
        <div class="row justify-center">
            <form @submit.prevent="onClickAnswer">
                <v-btn class="btn-disabled-primary mb-4" type="submit" x-large color="primary">
                    Продолжить
                </v-btn>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ConstructorTest',
    computed: {
        ...mapGetters({
            testData: 'testing/testing/testData',
            loading: 'testing/testing/loading',
        }),
    },
    methods: {
        ...mapActions({
            sendAnswerStaticMessage: 'testing/testing/sendAnswerStaticMessage',
        }),
        onClickAnswer() {
            this.sendAnswerStaticMessage();
        },
    },
};
</script>

<style scoped lang="scss">

</style>

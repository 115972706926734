<template>
    <!-- <ProfileCard title="Расчеты" :link="isProfile ? '/compliance' : `/clients/${$route.params.id}/compliance`"> -->
    <ProfileCard title="Расчеты" showLinkAnyway @linkClicked="showCalcComplianceDialog=true">
      <div>
      </div>
      <ShowProfgramsResult v-model="showCalcComplianceDialog" :clients="[client? client.id :0]" />

    </ProfileCard>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ShowProfgramsResult from '@/components/candidates/calc/ShowProfgramsResult';

export default {
  name: 'ProfileClientCalc',
  components: {
    ShowProfgramsResult
  },
  props: {
  },
  inject: {
    isProfile: {
      default: false
    }
  },
  data: () => ({
    showCalcComplianceDialog: false,
  }),
  computed: {
    ...mapGetters({
      client: 'users/clients/client',
    }),
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
  },
};
</script>

<style lang="scss" scored>
@import "src/assets/styles/variables";

</style>

/*eslint-disable */


export const columns = [

  { key: 'FIO',            title: 'Фамилия и Имя',  },
  { key: 'EMAIL',          title: 'E-mail',  },
  { key: 'GENDER',         title: 'Пол',  },
  { key: 'AGE',            title: 'Возраст',  },
  { key: 'POSITION',       title: 'Должность',  },


  // Блок Общие данные
  { key: 'TEST_TIME',      title: 'Дата тестирования',  },
  { key: 'TESTING_STATUS', title: 'Статус тестирования',  },
  { key: 'COMPANY', title: 'Компания',  },
  { key: 'TEST_TIME_MMPI', title: 'Время прохождения личностного опросника',  },
  { key: 'TEST_TIME_PROF_INTERESTS', title: 'Время прохождения карты интересов',  },
  { key: 'TEST_TIME_NUMERIC_LOGIC', title: 'Время прохождения числовой логики',  },
  { key: 'TEST_TIME_VERBAL_LOGIC', title: 'Время прохождения вербальной логики',  },
  { key: 'TEST_TIME_NON_VERBAL_LOGIC', title: 'Время прохождения невербальной логики',  },
  { key: 'MANAGER', title: 'Менеджер',  },
  { key: 'COMMENT', title: 'Комментарии',  },


  // Блок Основные данные
  { key: 'RELIABILITY', title: 'Достоверность',  },
  { key: 'BEHAVIOUR', title: 'Поведение',  },
  { key: 'ATTENTION_FACTOR', title: 'Зоны внимания',  },
  { key: 'RISK_FACTORS', title: 'Факторы риска',  },
  { key: 'MANAGEMENT_STYLE', title: 'Стиль управления',  },
  { key: 'STRESS_TOLERANCE', title: 'Стрессоустойчивость',  },
  { key: 'ANXIETY', title: 'Тревожность',  },
  { key: 'MANAGEMENT_POTENTIAL', title: 'Управленческий потенциал',  },
  // Другое
  { key: 'SCALES', title: 'Шкалы',  }, // Шкалы

  // Роли в команде
  { key: 'TEAM_ROLES', title: 'Роли в команде',  },
  // { key: 'TEAM_ROLES_NORMALIZED', title: 'Роли в команде (нормализованные)',  },

  // Структура интеллекта
  { key: 'INTELLECTUAL_ABILITY', title: 'Структура интеллекта',  },

  // Профессиональные интересы
  { key: 'PROF_INTERESTS', title: 'Профессиональные интересы',  },

  // Результаты профнавигатора
  { key: 'PROFNAVIGATOR_RESULTS', title: 'Результаты профнавигатора',  },

  { key: 'MOTIVATORS', title: 'Мотиваторы',  },
  { key: 'DESTRUCTORS', title: 'Деструкторы',  },
  { key: 'COMPETENCES', title: 'Компетенции',  },
  { key: 'COMPLIANCE', title: 'Расчет соответствия  должности',  },

  // Расчет соответствия  должности

  // {
  //   key: 'DEGREE_POSITION_COMPLIANCE',
  //   title: 'Степень соответствия указанной должности',
  //   children: [
  //     {
  //       key: 'FACTORS2321',
  //       title: 'Степень 1',
  //     },
  //     {
  //       key: 'FACTORS232331',
  //       title: 'Степень 2',
  //     },
  //     {
  //       key: 'FACTORS2324',
  //       title: 'Степень 4',
  //     },
  //     {
  //       key: 'FACTORS32422',
  //       title: 'Степень 4',
  //     }
  //   ]
  // },

];

export const columnsKey = 'ExcelColumns2';

<template>
  <ProfileCard title="Счетчики" class="mb-5">

    <template #extraTitle>
      <v-btn fab class="mb-2" small text _outlined @click="$emit('add')" v-if="enableAdd">
        <v-icon >mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
    <div class="counters-table">
        <div class="counters-table-header counters-table-row">
          <div class="counters-table-col0">{{ title }}</div>
          <div class="counters-table-col1">За месяц</div>
          <div class="counters-table-col1">За все время</div>
        </div>
        <div class="counters-table-row">
          <div class="counters-table-col0">Отправлено тестов</div>
          <div class="counters-table-col1">{{ spentMonth }}</div>
          <div class="counters-table-col2">{{ spentTotal }}</div>
        </div>
        <div class="counters-table-row">
          <div class="counters-table-col0">Получено тестов</div>
          <div class="counters-table-col1">{{ receivedMonth }}</div>
          <div class="counters-table-col2">{{ receivedTotal }}</div>
        </div>
        <div class="counters-table-row" v-if="testType=='MMPI'">
          <div class="counters-table-col0">Осталось</div>
          <div class="counters-table-col1"></div>
          <div class="counters-table-col2">{{ rest }}</div>
        </div>
      </div>
    </ProfileCard>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ProfileCardCounters',
  components: {
  },
  props: {
    user: Object,
    enableAdd: Boolean,
    testType: {
      type: String, // MMIL, MINIMULT, CONSTRUCTOR
      detault: 'MMPI'
    }
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      stdTests: 'tests/stdTests',
    }),
    title() {
      if (this.testType === 'MINIMULT') return 'Тесты Минимульт';
      if (this.testType === 'CONSTRUCTOR') return 'Профнавигатор';
      return 'Тесты ММИЛ';
    },

    spentMonth() {
      return this.licData('licenses_spent_last_month');
    },
    spentTotal() {
      return this.licData('licenses_spent');
    },
    receivedMonth() {
      return this.licData('licenses_received_last_month');
    },
    receivedTotal() {
      return this.licData('licenses_received');
    },
    rest() {
      return this.user.stock_balance ? this.user.stock_balance : '0';
    },
    stdTestsIds() {
      return this.stdTests.map(el => el.id);
    }

  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),
    licData(field) {
      if (!this.user.licences_received_spent) {
        return 0;
      }

      let sum = 0;
      if (this.testType === 'CONSTRUCTOR') {
        for (const key in this.user.licences_received_spent) {
          if (!this.stdTestsIds.includes(key)) {
            // console.log(key, this.user.licences_received_spent[key][field]);
            sum += this.user.licences_received_spent[key][field] ?? 0;
          }
        }
        return sum;
      }

      return this.user.licences_received_spent[this.testType] && this.user.licences_received_spent[this.testType][field]
        ? this.user.licences_received_spent[this.testType][field] : 0;
    }

  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.counters-table {
  width: 100%;
  // max-width: 700px;
  // font-size: 13px;
  margin: 0 10px 0px 0px;

  .counters-table-header {
    font-weight: 600;
    padding: 10px 0 !important;
  }
  .counters-table-header .counters-table-col0{
    color: $color-blue;
  }
  .counters-table-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 8px 0;
    border-bottom: 1px solid #ccc;
  }

  .counters-table-row:last-child {
    border-bottom: none;
  }

  .counters-table-col0 {
    width: 40%;
    font-weight: 600;
  }

  .counters-table-col1, .counters-table-col2 {
    width: 30%;
    text-align: right;
  }

}

</style>

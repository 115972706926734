<template>
  <div class="auth-card android text-center mb-8">
    <h2 class="auth-card__title mb-2">Спасибо, Вы прошли тест полностью!</h2>
      <div v-if="testData.send_mail">На Ваш адрес почты отправлено письмо с доступом в Личный кабинет для того, чтобы вы смогли посмотреть результаты теста в отчете.</div>
    <div class="android" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Finish',
  computed: {
    ...mapGetters({
      testData: 'testing/testing/testData',
    }),
  }

};
</script>

<template>
  <div>
    <div v-if="loading.admin" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </div>

    <BackBtn />
    <BreadCrumbs :items="breadcrumbs" />

    <ProfileTopPanel v-if="admin"
      userTypeName="Администратор"
      :name="admin.last_name + ' ' + admin.first_name"
      :city="admin.city"
      :phone="admin.phone"
      :email="admin.email"
      permssionEdit="edit_admin"
      permssionDelete="_delete_admin"
      whoDelete="администратора"
      @delete="onDelete()" @edit="onEdit()"
    />
    <div class="row">
      <div class="col-12 col-md-6">
        <ClientProfileCardCounters
          testType="MMPI"
          v-if="admin && ( $store.getters['auth/isAllowed']('view_stockamount_admin') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="admin"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('admin') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_admin') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
        <ClientProfileCardCounters
          testType="MINIMULT"
          v-if="admin && ( $store.getters['auth/isAllowed']('view_stockamount_admin') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="admin"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('admin') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_admin') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
      </div>
      <div class="col-12 col-md-6">
        <ClientProfileCardCounters
          testType="CONSTRUCTOR"
          v-if="admin && ( $store.getters['auth/isAllowed']('view_stockamount_admin') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="admin"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('admin') &&
                    ( $store.getters['auth/isAllowed']('add_stockamount_admin') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
        <ProfileCardCalc />
      </div>
    </div>
    <AddLicensesDialog v-model="dialogAddLicencesShow" :toId="+userId" toType='admin' @success="reload()" />
    <!-- <ManagerEditDialog /> -->

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ProfileTopPanel from '@/components/common/ProfileTopPanel';
// import ManagerEditDialog from '@/components/managers/ManagerEditDialog';
import AddLicensesDialog from '@/components/admins/AddLicensesDialog.vue';
import ClientProfileCardCounters from '@/components/clients/profile/ProfileCardCounters';
import ProfileCardCalc from '@/components/admins/profile/ProfileCardCalc';

export default {
  name: 'ProfileAdmin',
  components: {
    // ManagerEditDialog,
    AddLicensesDialog,
    ProfileTopPanel,
    ClientProfileCardCounters,
    ProfileCardCalc
  },
  props: ['userId'],
  provide() {
    return {
      userType: 'Admin',
      userId: this.userId,
    };
  },
  data() {
    return {
      dialogAddLicencesShow: false,
    };
  },
  computed: {
    ...mapGetters({
      admin: 'users/admins/admin',
      loading: 'users/admins/loading'
    }),
    breadcrumbs() {
      if (!this.admin) return [];
      return this.isProfile ? [
        { title: 'Личный кабинет', url: '/' },
      ] : [
        { title: 'Администраторы', url: '/admins/' },
        { title: `${this.admin.last_name} ${this.admin.first_name}`, url: `/admins/${this.admin.id}` },
      ];
    },
  },
  methods: {
    ...mapActions({
      loadAdmin: 'users/admins/loadAdmin',
      // setDialogEditShow: 'users/admins/setDialogEditShow'
    }),
    ...mapMutations({
      // setEditedManager: 'users/managers/setEditedManager'
    }),
    onEdit() {
      // this.setEditedManager({ ...this.manager });
      // this.setDialogEditShow(true);
    },
    onDelete() {
    },
    reload() {
      this.loadAdmin(this.userId);
    }
  },
  created() {
    this.loadAdmin(this.userId);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
</style>

import api from '@/common/api';
import { getToken, saveToken, destroyToken } from '@/common/jwt.service';
import { cloneDeep } from 'lodash';

const initialState = () => ({
  initialized: false,
  loading: false,
  errors: [],
  user: null,
  userPerms: [],
  token: getToken(),
});

export default {
  namespaced: true,
  state: cloneDeep(initialState()),
  actions: {
    async login({ commit, dispatch }, { username, password }) {
      const data = { username, password };
      commit('setLoading', true);
      commit('setError', []);
      try {
        const response = await api.post('/users/login', data);
        commit('setToken', response.token);
        commit('users/managers/reset', {}, { root: true });
        commit('users/candidates/reset', {}, { root: true });
        commit('users/partners/reset', {}, { root: true });
        commit('analytics/reset', {}, { root: true });
        await dispatch('loadMe');
      } catch (err) {
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },

    async logout({ getters, commit }) {
      commit('setError', []);
      if (getters.loggedIn) {
        commit('setLoading', true);
        try {
          await api.post('/users/logout');
        } catch (err) {
          commit('setError', Object.entries(err.data));
        }
        commit('setLoading', false);
      }
      commit('logOut');
    },

    async loadMe({ state, commit }) {
      if (state.token) {
        commit('setLoading', true);
        commit('setError', []);
        try {
          const response = await api.get('/users/get-me');
          commit('setUser', response);
          // const permsall = await api.get('/permissions/all/');
          // console.log(permsall);
          const groups = await api.get('/permissions/get-my/');
          const perms = groups.flatMap(el => el.groups).flatMap(el => el.permissions).map((el) => el.codename);
          commit('setUserPerms', perms);
        } catch (err) {
          commit('setError', Object.entries(err.data));
          commit('logOut');
          throw err;
        } finally {
          commit('setLoading', false);
        }
      }
    },

    async loadMyPerms({ state, commit }) {
      if (state.token) {
        try {
          const groups = await api.get('/permissions/get-my/');
          const perms = groups.flatMap(el => el.groups).flatMap(el => el.permissions).map((el) => el.codename);
          commit('setUserPerms', perms);
        } catch (err) {
          console.log('ERROR!');
        }
      }
    },

    async register({ commit }, credentials) {
      try {
        const response = await api.post('users', { user: credentials });
        commit('setUser', response.data.user);
      } catch (err) {
        commit('setError', err.data.errors);
      }
    },

    async updateUser({ commit }, payload) {
      const {
        email, username, password,
      } = payload;
      const user = {
        email,
        username,
      };
      if (password) {
        user.password = password;
      }

      try {
        const response = await api.put('user', user);
        commit('setUser', response.data.user);
      } catch (err) {
        commit('setError', err.data.errors);
        throw err;
      }
    },

    async resetPassword({ commit }, email) {
      commit('setLoading', true);
      commit('setError', []);
      try {
        await api.post('/users/reset-password/', { email });
      } catch (err) {
        commit('setError', err.data);
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },

    async updatePassword({ commit }, data) {
      commit('setLoading', true);
      commit('setError', []);
      try {
        await api.post('/users/reset-password/confirm/', data);
      } catch (err) {
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },

    clearError({ commit }) {
      commit('setError', []);
    },
  },
  mutations: {
    setInitialized(state) {
      state.initialized = true;
    },
    setLoading(state, data) {
      state.loading = data;
    },
    setError(state, error) {
      state.errors = error;
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserPerms(state, perms) {
      state.userPerms = perms;
    },
    setToken(state, token) {
      state.token = token;
      saveToken(token);
    },
    logOut(state) {
      state.user = null;
      state.token = '';
      destroyToken();
    },
  },
  getters: {
    initialized: (state) => state.initialized,
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    token: (state) => state.token,
    user: (state) => state.user,
    loggedIn: (state) => !!state.user,
    perms: (state) => state.perms,
    // isAllowed: () => () => true,
    isAllowed: (state) => (perm) => {
      if (typeof perm === 'string') {
        // console.log(perm, state.userPerms);
        return state.userPerms.includes(perm);
      }
      if (typeof perm === 'object') {
        for (const p of perm) {
          if (state.userPerms.includes(p)) {
            return true;
          }
        }
      }
      return false;
    },
    userRole: (state) => {
      if (!state.user) {
        return 'Не авторизован';
      }
      switch (state.user.user_type) {
        case 'Admin': return 'Администратор';
        case 'Manager': return 'Менеджер';
        case 'Candidate': return 'Кандидат';
        default: return state.user.user_type;
      }
    }
  },
};

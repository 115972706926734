<template>
  <div class="d-flex">
    <v-btn color="primary" outlined @click="$emit('downloadPdf', !removeLogo)" :loading="loading"> Скачать PDF-отчет </v-btn>
    <v-menu v-if="$store.getters['auth/isAllowed']('download_without_logo')"
      offset-y min-width="212px" left :nudge-top="0" :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" outlined class="px-0 icon drop-down-btn" v-bind="attrs" v-on="on">
          <v-icon dark>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-checkbox v-model="removeLogo" label="Убрать логотип"></v-checkbox>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean
  },
  data() {
    return {
      removeLogo: false
    };
  }
};
</script>

<style scoped>
.drop-down-btn {
  min-width: 40px !important;
  color: white !important;
}
</style>

import api from '@/common/api';
import Vue from 'vue';
// import { cloneDeep } from 'lodash';

/* eslint-disable */



const initialState = () => ({
  profs: [],
  chapters: [],
  reportSetup: {},
  complianceSetup: {
    method: 'ranges',
    chapters: [],
    professiograms: [],
  },
  tabsSetup: [],
  calculating: false,
  loading: false,
  savingReport: false,
  deletingReport: false,
  calcError: '',
});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    profs: (state) => state.profs,
    chapters: (state) => state.chapters,
    chapterProfs: (state) => (chapterId) => state.profs.filter((el) => el.chapters.find((ch) => ch.id==chapterId)),
    complianceSetup: (state) => state.complianceSetup,
    tabsSetup: (state) => state.tabsSetup,
    calculating: (state) => state.calculating,
    loading: (state) => state.loading,
    savingReport: (state) => state.savingReport,
    deletingReport: (state) => state.deletingReport,
    isChapterAdded: (state) => (chapterId) => state.complianceSetup.chapters.find((ch) => ch.id==chapterId),
    isProfAdded: (state) => (id) => state.complianceSetup.professiograms.find((ch) => ch.id==id),
    calcError: (state) => state.calcError,
  },
  mutations: {
    setProfs(state, items) {
      state.profs = items;
    },
    setChapters(state, chapters) {
      state.chapters = chapters
    },
    setComplianceSetup(state, report) {
      state.complianceSetup = report
    },
    setTabsSetup(state, tabsSetup) {
      state.tabsSetup = tabsSetup
    },
    setReportSetup(state, report) {
      state.reportSetup = report
    },
    delProfFromReport(state, id) {
      state.complianceSetup.professiograms = state.complianceSetup.professiograms.filter(el => el.id !== id)
    },
    delChapterFromReport(state, id) {
      state.complianceSetup.chapters = state.complianceSetup.chapters.filter(el => el.id !== id)
    },
    addProfToReport(state, item) {
      state.complianceSetup.professiograms.push({ name: item.name, id: item.id })
    },
    addChapterToReport(state, item) {
      state.complianceSetup.chapters.push({ name: item.name, id: item.id })
    },
    setCalculating(state, calculating) {
      state.calculating = calculating;
    },
    setLoading(state, loaing) {
      state.loading = loaing;
    },
    setSavingReport(state, savingReport) {
      state.savingReport = savingReport;
    },
    setDeletingReport(state, deletingReport) {
      state.deletingReport = deletingReport;
    },
    setError(state, error) {
      state.calcError = error;
    },
  
  },
  actions: {
    async loadProfs({ commit, state, getters }) {
      try {
        commit('setLoading', true);
        const profs = await api.get('/professiogram/all/');
        profs.sort( (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) )
        commit('setProfs', profs);
        const chapters = await api.get('/professiogram/chapter/');
        chapters.forEach(el => {
          Vue.set(el, 'children', getters['chapterProfs'](el.id))
        })
        commit('setChapters', chapters);
      } catch (err) {
        // commit('loadFailure', Object.entries(err.data));
      }
      commit('setLoading', false);

    },

    async loadComplianceSetup({ commit, state, dispatch }, {candidate}) {
      await dispatch('loadProfs')

      commit('setLoading', true);
      let reportSetup = null
      try {
        reportSetup = await api.get(`/professiogram/candidates/${candidate.id}/get-report-setup/`);
      } catch (e) {
        reportSetup = null
      }

      let complianceSrc = reportSetup?.pg_setup?.compliance

      const complianceRet = {
        main_professiogram: complianceSrc?.main_professiogram,
        professiograms: [],
        chapters:  [],
        method: 'ranges',
      }

      if (!complianceRet.main_professiogram) {
        const defProf = state.profs.find(el => el.name===candidate.position)
        if (defProf)
          complianceRet.main_professiogram = defProf.id
      }
      const professiograms = []
      if (complianceSrc?.professiograms)
        complianceSrc.professiograms.forEach(item => {
          const p = state.profs.find(el => el.id===item)
          if (p)
            professiograms.push( {id: p.id, name: p.name } )
        })
      complianceRet.professiograms = professiograms

      const chapters = []
      if (complianceSrc?.chapters)
        complianceSrc.chapters.forEach(item => {
          const p = state.chapters.find(el => el.id===item)
          if (p)
            chapters.push( {id: p.id, name: p.name } )
        })
      complianceRet.chapters = chapters

      complianceRet.method = complianceSrc?.method ? complianceSrc.method : 'ranges'
      commit('setLoading', false);
      commit('setComplianceSetup', complianceRet)
      commit('setReportSetup', reportSetup)
    },

    async calcCompliance({ commit, rootGetters, state, dispatch }, {idCanidate, saveOnly} ) {

      const compliance = {
        main_professiogram: state.complianceSetup.main_professiogram,
        professiograms: state.complianceSetup.professiograms.map(el => el.id),
        chapters: state.complianceSetup.chapters.map(el => el.id),
        // method: state.complianceSetup.method,
        method: 'ranges',
      }

      const reportSetup = {
        candidate: idCanidate,
        pg_setup: {
          compliance
        }
      }
      if (state.reportSetup?.id)
        reportSetup.id = state.reportSetup.id

      commit('setCalculating', true)
      commit('setError', '')
      try{
        if (reportSetup.id)
          await api.patch(`/professiogram/report-setup/${reportSetup.id}/`, reportSetup);
        else {
          const resp = await api.post(`/professiogram/report-setup/`, reportSetup);
          commit('setReportSetup', resp)
        }
        // if (!saveOnly) {
          const resp = await api.post(`/professiogram/candidates/${idCanidate}/get-compliances/`, { compliances: compliance });

          commit('users/candidate/setCandidateComplianceResult', resp.compliances, { root: true })
          const complianceTab = rootGetters['profile/tabs/tabs'].find((el) => el.component==='ComplianceReport')
          if (complianceTab)
            commit('profile/tabs/setSelectedTab', complianceTab, { root: true })
        // }
        return resp
      }
      catch(e) {
        commit('users/candidate/setCandidateComplianceResult', {}, { root: true })
        console.log("ERROR !", e)
        commit('setError', e.data?.error)
        return false
      }
      finally {
        commit('setCalculating', false)
      }
    },

    // async loadReportSetup({ commit, state, dispatch }, {candidate}) {
    //   try {
    //     const reportSetup = await api.get(`/professiogram/candidates/${candidate.id}/get-report-setup/`);
    //     commit('setReportSetup', reportSetup)
    //   } catch (e) {
    //   }
    // },

    async loadReportTabs({ commit, rootGetters }, candidate) {
      let reportSetup = null
      try {
        reportSetup = await api.get(`/professiogram/candidates/${candidate.id}/get-report-setup/`);
        commit('setReportSetup', reportSetup)
      } catch (e) {
      }
      let allCheckBoxes = rootGetters['profile/tabs/allCheckBoxes'];
      allCheckBoxes = allCheckBoxes.filter(t => rootGetters['auth/isAllowed'](t.permission));
      allCheckBoxes = allCheckBoxes.map(el => el.checkField)
      allCheckBoxes = [...allCheckBoxes, "all_tabs", "send_to_candidate"]

      const arrChecked = allCheckBoxes.reduce((acc, cur)=>{
        if (!reportSetup || !reportSetup.setup ||reportSetup.setup[cur]) {
            acc.push(cur)
        }
        return acc
      }, [])

      commit('setTabsSetup', arrChecked);
      return arrChecked;
    },


    async delReportSetup({ commit, rootGetters, state, dispatch } ) {
      commit('setDeletingReport', true)
      try{
        await api.delete(`/professiogram/report-setup/${state.reportSetup.id}/`);
      }
      catch(e) {
        console.log("ERROR !")
        return false
      }

      let reportSetup = null
      const complianceRet = {
        main_professiogram: null,
        professiograms: [],
        chapters:  [],
        method: 'ranges',
      }
      commit('setComplianceSetup', complianceRet)
      commit('setReportSetup', reportSetup)
      commit('users/candidate/setCandidateComplianceResult', {}, { root: true })
      commit('setDeletingReport', false)
    },

    async saveReportSetup({ commit, rootGetters, state, dispatch }, {candidate, arrChecked} ) {

      let allCheckBoxes = rootGetters['profile/tabs/allCheckBoxes'];
      allCheckBoxes = allCheckBoxes.map(el => el.checkField)
      allCheckBoxes = [...allCheckBoxes, "all_tabs", "send_to_candidate"]
      const setup = {}
      allCheckBoxes.forEach(key => {
        setup[key] = arrChecked.includes(key)
      })

      const reportSetup = {
        candidate: candidate.id,
        setup
      }
      if (state.reportSetup?.id)
        reportSetup.id = state.reportSetup.id

      commit('setSavingReport', true)
      try{
        if (reportSetup.id)
          await api.patch(`/professiogram/report-setup/${reportSetup.id}/`, reportSetup);
        else {
          const resp = await api.post(`/professiogram/report-setup/`, reportSetup);
          commit('setReportSetup', resp)
        }
        return true
      }
      catch(e) {
        console.log("ERROR !")
        return false
      }
      finally {
        commit('setSavingReport', false)
      }
    },


  },
};

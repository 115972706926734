var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1250px","fullscreen":_vm.isFullScreen},model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[_c('v-card',{ref:"refDialog",staticClass:"_pa-sm-8"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Расчет соответствия")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isFullScreen = !_vm.isFullScreen}}},[_c('v-icon',[_vm._v("mdi-window-maximize")])],1),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isDialogVisible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),(_vm.loading.positions || _vm.loading.profs || _vm.loading.loadResults)?_c('div',{staticClass:"h-100 my-7 d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"size":25,"color":"primary","indeterminate":""}})],1):_c('div',{ref:"setupCard",staticClass:"mx-4"},[_c('div',{staticClass:"mt-3 d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"_w-100 mb-3",attrs:{"_v-if":"$store.getters['auth/isAllowed']('download_testresultscompliencereport')","color":"primary","disabled":!_vm.candidates2show.length,"loading":_vm.loading.excel},on:{"click":function($event){return _vm.toExcel()}}},[_vm._v(" Выгрузка в Excel (полная) ")])],1)]),_c('v-data-table',{staticClass:"px-0 _px-sm-5 elevation-1",attrs:{"headers":_vm.candidates2show.length ? _vm.allHeaders : [],"items":_vm.candidates2show,"show-select":"","sort-by":"id","sort-desc":"","must-sort":"","loading":_vm.loading.loadingResult,"loading-text":"Идет загрузка... Пожалуйста подождите","mobile-breakpoint":300,"items-per-page":-1,"height":_vm.tableHeight,"fixed-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex _justify-space-between align-center"},[(item.error)?_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"error","dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-alert-circle-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_vm._e(),_c('router-link',{staticClass:"text-primary mr-2",attrs:{"to":{ name: 'candidates-id', params: { id: item.id } }}},[(item.last_name || item.first_name)?[_vm._v(_vm._s(item.last_name)+" "+_vm._s(item.first_name))]:[_vm._v(_vm._s(item.id))]],2)],1)]}},{key:"item.test_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.test_date).format('DD.MM.YYYY'))+" ")]}},{key:"item.compliances",fn:function(ref){
var item = ref.item;
return [_c('div',{},[(!item.compliances || !item.compliances.length)?_c('span'):_c('v-menu',{ref:"menuCompliance",attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"comp-width-col font-xs",attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"comp-table-btn"},[_c('div',[_vm._v(_vm._s(item.compliances[0].professiogram_name))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"comp-circle"},[_vm._v(_vm._s(Math.round(item.compliances[0].compliance*100))+" ")]),(attrs['aria-expanded'])?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")])],1)])])]}}],null,true)},[_c('v-list',_vm._l((item.compliances.slice(0, 10)),function(comp,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-sm"},[_vm._v(_vm._s(comp.professiogram_name))])],1),_c('v-list-item-action',{staticClass:"comp-circle"},[_vm._v(" "+_vm._s(Math.round(comp.compliance*100))+" ")])],1)}),1)],1)],1)]}},{key:"item.prof_interests",fn:function(ref){
var item = ref.item;
return [_c('div',{},[(!item.prof_interests || !item.prof_interests.length)?_c('span'):_c('v-menu',{ref:"menuProfInterests",attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"comp-width-col font-xs",attrs:{"elevation":"0"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"comp-table-btn interest"},[_c('div',[_vm._v(_vm._s(item.prof_interests[0].title))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"comp-circle interest"},[_vm._v(_vm._s(item.prof_interests[0].points)+" ")]),(attrs['aria-expanded'])?_c('v-icon',[_vm._v("mdi-chevron-down")]):_c('v-icon',[_vm._v("mdi-chevron-up")])],1)])])]}}],null,true)},[_c('v-list',_vm._l((item.prof_interests.slice(0, 10)),function(comp,i){return _c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-sm"},[_vm._v(_vm._s(comp.title))])],1),_c('v-list-item-action',{staticClass:"comp-circle interest"},[_vm._v(" "+_vm._s(comp.points)+" ")])],1)}),1)],1)],1)]}},{key:"no-data",fn:function(){return [_vm._v(" Кандидатов не найдено. ")]},proxy:true}]),model:{value:(_vm.candidatesForExport),callback:function ($$v) {_vm.candidatesForExport=$$v},expression:"candidatesForExport"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
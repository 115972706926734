<template>
  <div>
    <div v-if="loading.client" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </div>

    <BackBtn />
    <BreadCrumbs :items="breadcrumbs" />

    <ProfileTopPanel v-if="client"
      userTypeName="Корпоративный клиент"
      :name="client.last_name + ' ' + client.first_name"
      :city="client.city"
      :phone="client.phone"
      :email="client.email"
      :company="client.company"
      permssionEdit="edit_corpclient"
      permssionDelete="delete_corpclient"
      whoDelete="корпоративного клиента"
      @delete="onDelete()" @edit="onEdit()"
    >
      <div class="profile-header-item" style="margin-top:0">
        <v-switch v-if="!isProfile && $store.getters['auth/isAllowed']('edit_corpclient')"
          :input-value="client.send_mail" @change="changeSendMail" @loading="loading.editMail"
          class="v-input--reverse profile-switch ml-4" hide-details :color="client.send_mail ? 'success' : 'error'"
        >
          <div slot="label" :class="client.send_mail ? 'success--text' : 'error--text'">Почта</div>
        </v-switch>
      </div>

    </ProfileTopPanel>
    <div class="row">
      <div class="col-12 col-md-6">
        <ClientProfileCardRef class="mb-5" />
        <ClientProfileCardCodesUsed class="mb-5" />
        <ClientProfileCardCalc class="mb-5" />
        <ClientProfileCardManagers v-if="client" :user="client" />
      </div>
      <div class="col-12 col-md-6">
        <ClientProfileCardCounters class="mb-5"
          testType="MMPI"
          v-if="client && ( $store.getters['auth/isAllowed']('view_stockamount_corpclient') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="client"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('corp_client') &&
            ( $store.getters['auth/isAllowed']('add_stockamount_corpclient') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
        <ClientProfileCardCounters class="mb-5"
          testType="MINIMULT"
          v-if="client && ( $store.getters['auth/isAllowed']('view_stockamount_corpclient') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="client"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('corp_client') &&
            ( $store.getters['auth/isAllowed']('add_stockamount_corpclient') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />
        <ClientProfileCardCounters class="mb-5"
          testType="CONSTRUCTOR"
          v-if="client && ( $store.getters['auth/isAllowed']('view_stockamount_corpclient') || $store.getters['auth/isAllowed']('view_stockamount') )"
          :user="client"
          @add="dialogAddLicencesShow=true"
          :enableAdd="$store.getters['users/counters/getEndpoint']('corp_client') &&
            ( $store.getters['auth/isAllowed']('add_stockamount_corpclient') || $store.getters['auth/isAllowed']('add_stockamount') )"
        />

      </div>
    </div>
    <AddLicensesDialog v-model="dialogAddLicencesShow" :toId="+userId" toType='corp_client' @success="reload()" />
    <ClientEditDialog />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ProfileTopPanel from '@/components/common/ProfileTopPanel';
import ClientEditDialog from '@/components/clients/ClientEditDialog';
import AddLicensesDialog from '@/components/admins/AddLicensesDialog.vue';
import ClientProfileCardRef from '@/components/clients/profile/ProfileCardRef';
import ClientProfileCardCodesUsed from '@/components/clients/profile/ProfileCardCodesUsed';
import ClientProfileCardCounters from '@/components/clients/profile/ProfileCardCounters';
import ClientProfileCardManagers from '@/components/clients/profile/ProfileCardManagers';
import ClientProfileCardCalc from '@/components/clients/profile/ProfileCardCalc';

export default {
  name: 'ProfileClient',
  components: {
    ClientEditDialog,
    AddLicensesDialog,
    ClientProfileCardRef,
    ClientProfileCardCodesUsed,
    ProfileTopPanel,
    ClientProfileCardCounters,
    ClientProfileCardManagers,
    ClientProfileCardCalc,
  },
  props: ['userId'],
  provide() {
    return {
      userType: 'CorpClient',
      userId: this.userId,
    };
  },
  inject: {
    isProfile: {
      default: false
    }
  },
  data() {
    return {
      dialogAddLicencesShow: false,
    };
  },
  computed: {
    ...mapGetters({
      client: 'users/clients/client',
      loading: 'users/clients/loading',
    }),
    breadcrumbs() {
      if (!this.client) return [];
      return this.isProfile ? [
        { title: 'Личный кабинет', url: '/' },
      ] : [
        { title: 'Корпоративные клиенты', url: '/clients/' },
        { title: `${this.client.last_name} ${this.client.first_name}`, url: `/clients/${this.client.id}` },
      ];
    },
  },
  methods: {
    ...mapActions({
      loadClient: 'users/clients/loadClient',
      setDialogEditShow: 'users/clients/setDialogEditShow',
      setClientSendMail: 'users/clients/setClientSendMail',
    }),
    ...mapMutations({
      setEditedClient: 'users/clients/setEditedClient',
    }),
    onEdit() {
      this.setEditedClient({ ...this.client });
      this.setDialogEditShow(true);
    },
    onDelete() {
    },
    reload() {
      this.loadClient(this.userId);
    },
    changeSendMail(value) {
      this.setClientSendMail({ send_mail: !!value, id: this.client.id });
    },

  },
  created() {
    this.loadClient(this.userId);
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
</style>

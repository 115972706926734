<template>
    <ProfileCard title="Реферальная программа" style="background: #E6F5FF" link="/refprogram">
      <div>
        Отправьте ссылку коллегам - по ссылке они регистрируются на сайте, оплачивают один из пакетов
        и вы оба получаете <span style="color:#18A0FB">бонусные тесты в подарок</span>
      </div>
    </ProfileCard>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ProfileRefClient',
  components: {
  },
  props: {
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations({
    }),

  },
};
</script>

<style lang="scss" scored>
@import "src/assets/styles/variables";

</style>

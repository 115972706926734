import api from '@/common/api';

const initialState = () => ({
  initialized: false,
  loading: false,
  errors: [],
  groups: null,
  perms: [],
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadAll({ commit }) {
      commit('setLoading', true);
      try {
        let response = await api.get('/permissions/permission/');
        commit('setPerms', response.map((el) => ({ ...el, title: `${el.id} ${el.name} (${el.codename})` })));
        response = await api.get('/permissions/group/');
        commit('setGroups', response);
      } catch (err) {
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },

    async saveGroup({ state, commit, dispatch }, { ind, name, permissions }) {
      commit('setLoading', true);
      const group = state.groups[ind];
      if (!name) {
        name = group.name;
      }
      if (!permissions) {
        permissions = group.permissions;
      }
      try {
        const response = await api.put(`/permissions/group/${group.id}/`, { name, permissions });
        commit('setGroup', { ind, group: response });
        dispatch('auth/loadMyPerms', {}, { root: true });
      } catch (err) {
        commit('setError', Object.entries(err.data));
        throw err;
      } finally {
        commit('setLoading', false);
      }
    },
    clearError({ commit }) {
      commit('setError', []);
    },
  },
  mutations: {
    setLoading(state, data) {
      state.loading = data;
    },
    setError(state, error) {
      state.errors = error;
    },
    setPerms(state, perms) {
      state.perms = perms;
    },
    setGroups(state, groups) {
      state.groups = groups;
    },
    setGroup(state, { ind, group }) {
      state.groups[ind] = group;
    },
  },
  getters: {
    initialized: (state) => state.initialized,
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    perms: (state) => state.perms,
  },
};

import api from '@/common/api';
import Vue from 'vue';

const initialState = () => ({
  loading: {
    load: false,
    edit: false,
    create: false,
    delete: false,
    loadResults: false,
    admin: false,
  },
  errors: {
    load: [],
    create: [],
    delete: [],
    admin: [],
    loadResults: [],
  },
  count: 0,
  admins: [],
  admin: null,
  selected: [],
  filter: {
    page: 1,
    name: '',
    email: '',
    position: '',
    company: '',
    testing_status: [],
    from_date_joined: null,
    to_date_joined: null,
  },
  editedAdmin: null,
  dialogCreateShow: false,
  dialogDeleteShow: false,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadAdmins({ commit, getters }) {
      commit('loadAdminsRequest');
      try {
        const filter = {};
        Object.keys(getters.filter).forEach((key) => {
          if (key === 'page') return;
          if (Array.isArray(getters.filter[key])) {
            if (getters.filter[key].length) {
              filter[key] = getters.filter[key];
            }
            return;
          }
          if (getters.filter[key]) {
            filter[key] = getters.filter[key];
          }
        });

        // const response = await api.post(`/users/admins/filter/?page=${getters.filter.page}`, filter);
        // commit('setAdmins', response.results);
        // commit('setCount', response.count);
        const response = await api.get('/users/admins/');
        if (response.length) {
          response.sort((a, b) => a.last_name.toLowerCase() > b.last_name.toLowerCase());
        }
        commit('setAdmins', response);
        commit('setCount', response.length);
        commit('loadAdminsSuccess');
      } catch (err) {
        commit('loadAdminsFailure', Object.entries(err.data));
        throw err;
      }
    },
    async createAdmin({ commit, dispatch, getters }, data) {
      commit('createAdminRequest');
      try {
        await api.post('/users/admins/', data);
        commit('createAdminSuccess');
        if (getters.filter.page === 1) {
          dispatch('loadAdmins');
        }
      } catch (err) {
        commit('createAdminFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadAdmin({ commit }, id) {
      commit('loadAdminRequest');
      let admin = null;
      try {
        admin = await api.get(`/users/admins/${id}/`);
      } catch (err) {
        commit('loadAdminFailure', Object.entries(err.data));
        throw err;
      }
      commit('loadAdminSuccess', { admin });
    },
    async saveAdmin({ commit, dispatch }, data) {
      commit('editAdminRequest');
      try {
        console.log(data);
        await api.patch(`/users/admins/${data.id}/`, data);
        commit('editAdminSuccess');
        dispatch('loadMAdmins');
        dispatch('loadAdmin', data.id);
      } catch (err) {
        commit('editAdminFailure', Object.entries(err.data));
        throw err;
      }
    },

    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('loadAdmins');
    },
    async setFilterName({ commit, dispatch }, name) {
      commit('setFilterName', name);
      commit('setFilterPage', 1);
      await dispatch('loadAdmins');
    },
    async setFilterEmail({ commit, dispatch }, email) {
      commit('setFilterEmail', email);
      commit('setFilterPage', 1);
      await dispatch('loadAdmins');
    },

    async setFilterJoinedDatesRange({ commit, dispatch }, dates) {
      commit('setFilterJoinedDatesRange', dates);
      commit('setFilterPage', 1);
      await dispatch('loadAdmins');
    },
    async setFilterPosition({ commit, dispatch }, position) {
      commit('setFilterPosition', position);
      commit('setFilterPage', 1);
      await dispatch('loadAdmins');
    },
    async setFilterCompany({ commit, dispatch }, payload) {
      commit('setFilterCompany', payload);
      commit('setFilterPage', 1);
      await dispatch('loadAdmins');
    },
    async setFilterTestingStatus({ commit }, statuses) {
      commit('setFilterTestingStatus', statuses);
    },
    async setFilterAll({ commit, dispatch }, params) {
      commit('setFilterPage', parseInt(params.page, 10) || 1);
      commit('setFilterName', params.name ?? '');
      commit('setFilterEmail', params.email ?? '');
      commit('setFilterPosition', params.position ?? '');
      commit('setFilterCompany', params.company ?? '');
      commit('setFilterTestingStatus', params.testingStatus ?? []);
      await dispatch('loadAdmins');
    },

    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
    setDialogCreateShow({ commit }, bool) {
      if (!bool) commit('setEditedAdmin', null);
      commit('setDialogCreateShow', bool);
    },
    setDialogDeleteShow({ commit }, bool) {
      if (!bool) commit('setEditedAdmin', null);
      commit('setDialogDeleteShow', bool);
    },
    deleteAdminDialog({ commit }, admin) {
      commit('setEditedAdmin', { ...admin });
      commit('setDialogDeleteShow', true);
    },

  },
  mutations: {
    clearError(state) {
      state.errors = initialState().errors;
    },
    setCount(state, count) {
      state.count = count;
    },
    setAdmins(state, admins) {
      state.admins = admins.map((c) => ({ ...c, loading: {}, errors: {} }));
    },
    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },
    setFilterName(state, name) {
      state.filter.name = name;
    },

    setFilterJoinedDatesRange(state, dates) {
      // eslint-disable-next-line prefer-destructuring
      state.filter.from_date_joined = dates[0];
      // eslint-disable-next-line prefer-destructuring
      state.filter.to_date_joined = dates[1];
    },
    setFilterEmail(state, email) {
      state.filter.email = email;
    },
    setFilterPosition(state, position) {
      state.filter.position = position;
    },
    setFilterCompany(state, payload) {
      state.filter.company = payload;
    },
    setFilterTestingStatus(state, testingStatus) {
      state.filter.testing_status = testingStatus;
    },
    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.admins.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    clearSelected(state) {
      state.selected = [];
    },
    setEditedAdmin(state, admin) {
      state.editedAdmin = admin;
    },
    setDialogCreateShow(state, bool) {
      console.log('setDialogCreateShow', bool);
      state.dialogCreateShow = bool;
    },
    setDialogDeleteShow(state, bool) {
      state.dialogDeleteShow = bool;
    },

    loadAdminsRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadAdminsSuccess(state) {
      state.loading.load = false;
    },
    loadAdminsFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    createAdminRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createAdminSuccess(state) {
      state.loading.create = false;
    },
    createAdminFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    deleteAdminsRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deleteAdminsSuccess(state) {
      state.loading.delete = false;
    },
    deleteAdminsFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },

    loadAdminRequest(state) {
      state.loading.admin = true;
      state.errors.admin = [];
    },
    loadAdminSuccess(state, { admin, counters }) {
      Vue.set(admin, 'counters', counters);
      state.admin = admin;
      state.loading.admin = false;
    },
    loadAdminFailure(state, error) {
      state.errors.admin = error;
      state.loading.admin = false;
    },

    editAdminRequest(state) {
      state.loading.edit = true;
      state.errors.edit = [];
    },
    editAdminSuccess(state) {
      state.loading.edit = false;
    },
    editAdminFailure(state, error) {
      state.errors.edit = error;
      state.loading.edit = false;
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    filter: (state) => state.filter,
    admins: (state) => state.admins,
    admin: (state) => state.admin,
    selected: (state) => state.selected,
    editedAdmin: (state) => state.editedAdmin,
    dialogCreateShow: (state) => state.dialogCreateShow,
    dialogDeleteShow: (state) => state.dialogDeleteShow,
  },
};

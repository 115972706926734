import api from '@/common/api';
import { cloneDeep } from 'lodash';

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    partner: false,
    edit: false,
    delete: false,
    loadResults: false,
    editMail: false,
  },
  errors: {
    load: [],
    edit: [],
    create: [],
    delete: [],
    loadResults: [],
    partner: [],
  },
  count: 0,
  partners: [],
  partner: null,
  selected: [],
  filter: {
    page: 1,
    name: '',
    email: '',
    position: '',
    company: '',
    from_date_joined: null,
    to_date_joined: null,
  },
  editedPartner: null,
  dialogEditShow: false,
  dialogCreateShow: false,
  dialogDeleteShow: false,
});

export default {
  namespaced: true,
  state: cloneDeep(initialState()),
  actions: {
    async loadPartners({ commit, getters }) {
      commit('loadPartnersRequest');
      try {
        /**
         *
         * Проверяю если значение фильтра пустое, то не добавляю его в параметры запроса,
         * иначе валится бэк
         */
        const filter = {};
        Object.keys(getters.filter).forEach((key) => {
          if (key === 'page') return;
          if (Array.isArray(getters.filter[key])) {
            if (getters.filter[key].length) {
              filter[key] = getters.filter[key];
            }
            return;
          }
          if (getters.filter[key]) {
            filter[key] = getters.filter[key];
          }
        });

        // const response = await api.post(`/users/partners/filter/?page=${getters.filter.page}`, filter);
        // commit('setPartners', response.results);
        // commit('setCount', response.count);
        const response = await api.get('/users/partners/');
        commit('setPartners', response);
        commit('setCount', response.length);
        commit('loadPartnersSuccess');
      } catch (err) {
        commit('loadPartnersFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadPartner({ commit }, id) {
      commit('loadPartnerRequest');
      try {
        const response = await api.get(`/users/partners/${id}/`);
        commit('loadPartnerSuccess', response);
      } catch (err) {
        commit('loadPartnerFailure', Object.entries(err.data));
        throw err;
      }
    },
    async createPartner({ commit, dispatch, getters }, data) {
      commit('createPartnerRequest');
      try {
        await api.post('/users/partners/', data);
        commit('createPartnerSuccess');
        if (getters.filter.page === 1) {
          dispatch('loadPartners');
        }
      } catch (err) {
        commit('createPartnerFailure', Object.entries(err.data));
        throw err;
      }
    },
    async savePartner({ commit, dispatch }, data) {
      commit('editPartnerRequest');
      try {
        console.log(data);
        await api.patch(`/users/partners/${data.id}/`, data);
        commit('editPartnerSuccess');
        dispatch('loadPartners');
        dispatch('loadPartner', data.id);
      } catch (err) {
        commit('editPartnerFailure', Object.entries(err.data));
        throw err;
      }
    },
    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('loadPartners');
    },
    async setFilterName({ commit, dispatch }, name) {
      commit('setFilterName', name);
      commit('setFilterPage', 1);
      await dispatch('loadPartners');
    },
    async setFilterEmail({ commit, dispatch }, email) {
      commit('setFilterEmail', email);
      commit('setFilterPage', 1);
      await dispatch('loadPartners');
    },
    /**
     * @param {any} context
     * @param {string[]} dates
     * */
    async setFilterJoinedDatesRange({ commit, dispatch }, dates) {
      commit('setFilterJoinedDatesRange', dates);
      commit('setFilterPage', 1);
      await dispatch('loadPartners');
    },
    async setFilterPosition({ commit, dispatch }, position) {
      commit('setFilterPosition', position);
      commit('setFilterPage', 1);
      await dispatch('loadPartners');
    },
    async setFilterCompany({ commit, dispatch }, payload) {
      commit('setFilterCompany', payload);
      commit('setFilterPage', 1);
      await dispatch('loadPartners');
    },
    async setFilterAll({ commit, dispatch }, params) {
      commit('setFilterPage', parseInt(params.page, 10) || 1);
      commit('setFilterName', params.name ?? '');
      commit('setFilterEmail', params.email ?? '');
      commit('setFilterPosition', params.position ?? '');
      commit('setFilterCompany', params.company ?? '');
      await dispatch('loadPartners');
    },

    async editPartnerComment({ commit }, { id, text }) {
      commit('editPartnerCommentRequest', id);
      const data = {
        partner: id,
        text,
      };
      try {
        const response = await api.post('/users/partners/comment/', data);
        commit('editPartnerCommentSuccess', response);
      } catch (err) {
        commit('editPartnerCommentFailure', { id, error: Object.entries(err.data) });
        throw err;
      }
    },

    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
    setDialogCreateShow({ commit }, bool) {
      if (!bool) commit('setEditedPartner', null);
      commit('setDialogCreateShow', bool);
    },
    setDialogDeleteShow({ commit }, bool) {
      if (!bool) commit('setEditedPartner', null);
      commit('setDialogDeleteShow', bool);
    },
    setDialogEditShow({ commit }, bool) {
      if (!bool) commit('setEditedPartner', null);
      commit('setDialogEditShow', bool);
    },
    deletePartnerDialog({ commit }, partner) {
      commit('setEditedPartner', { ...partner });
      commit('setDialogDeleteShow', true);
    },
    async setPartnerSendMail({ commit }, data) {
      commit('editMailRequest');
      try {
        const response = await api.patch(`/users/partners/${data.id}/`, data);
        commit('editMailSuccess');
        commit('setSendMail', response);
      } catch (err) {
        commit('editMailFailure', Object.entries(err.data));
        throw err;
      }
    },

  },
  mutations: {
    clearError(state) {
      state.errors = initialState().errors;
    },
    setCount(state, count) {
      state.count = count;
    },
    setPartners(state, partners) {
      state.partners = partners.map((c) => ({ ...c, loading: {}, errors: {} }));
    },
    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },
    setFilterName(state, name) {
      state.filter.name = name;
    },
    /**
     * @param {object} state
     * @param {string[]} dates
     * */
    setFilterJoinedDatesRange(state, dates) {
      // eslint-disable-next-line prefer-destructuring
      state.filter.from_date_joined = dates[0];
      // eslint-disable-next-line prefer-destructuring
      state.filter.to_date_joined = dates[1];
    },
    setFilterEmail(state, email) {
      state.filter.email = email;
    },
    setFilterPosition(state, position) {
      state.filter.position = position;
    },
    setFilterCompany(state, payload) {
      state.filter.company = payload;
    },
    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.partners.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    clearSelected(state) {
      state.selected = [];
    },
    setEditedPartner(state, partner) {
      state.editedPartner = partner;
    },
    setDialogEditShow(state, bool) {
      state.dialogEditShow = bool;
    },
    setDialogCreateShow(state, bool) {
      console.log('setDialogCreateShow', bool);
      state.dialogCreateShow = bool;
    },
    setDialogDeleteShow(state, bool) {
      state.dialogDeleteShow = bool;
    },

    loadPartnersRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadPartnersSuccess(state) {
      state.loading.load = false;
    },
    loadPartnersFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    createPartnerRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createPartnerSuccess(state) {
      state.loading.create = false;
    },
    createPartnerFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    editPartnerRequest(state) {
      state.loading.edit = true;
      state.errors.edit = [];
    },
    editPartnerSuccess(state) {
      state.loading.edit = false;
    },
    editPartnerFailure(state, error) {
      state.errors.edit = error;
      state.loading.edit = false;
    },

    deletePartnersRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deletePartnersSuccess(state) {
      state.loading.delete = false;
    },
    deletePartnersFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },

    loadPartnersResultsRequest(state) {
      state.loading.loadResults = true;
      state.errors.loadResults = [];
    },
    loadPartnersResultsSuccess(state) {
      state.loading.loadResults = false;
    },
    loadPartnersResultsFailure(state, error) {
      state.errors.loadResults = error;
      state.loading.loadResults = false;
    },

    editPartnerCommentRequest(state, id) {
      const partner = state.partners.find((c) => c.id === id);
      if (partner) {
        partner.errors.editComment = [];
        partner.loading.editComment = true;
      }
    },
    editPartnerCommentSuccess(state, newPartner) {
      let partner = state.partners.find((c) => c.id === newPartner.id);
      if (partner) {
        partner.loading.editComment = false;
        partner = Object.assign(partner, newPartner);
      }
    },
    editPartnerCommentFailure(state, { id, error }) {
      const partner = state.partners.find((c) => c.id === id);
      if (partner) {
        partner.errors.editComment = error;
        partner.loading.editComment = false;
      }
    },

    loadPartnerRequest(state) {
      state.loading.partner = true;
      state.errors.partner = [];
    },
    loadPartnerSuccess(state, partner) {
      state.partner = partner;
      state.loading.partner = false;
    },
    loadPartnerFailure(state, error) {
      state.errors.partner = error;
      state.loading.partner = false;
    },

    setSendMail(state, data) {
      state.partner.send_mail = data.send_mail;
    },
    editMailRequest(state) {
      state.loading.editMail = true;
      state.errors.edit = [];
    },
    editMailSuccess(state) {
      state.loading.editMail = false;
    },
    editMailFailure(state, error) {
      state.errors.edit = error;
      state.loading.editMail = false;
    },
    reset(state) {
      Object.assign(state, cloneDeep(initialState()));
    }

  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    filter: (state) => state.filter,
    partners: (state) => state.partners,
    partner: (state) => state.partner,
    selected: (state) => state.selected,
    editedPartner: (state) => state.editedPartner,
    dialogCreateShow: (state) => state.dialogCreateShow,
    dialogDeleteShow: (state) => state.dialogDeleteShow,
    dialogEditShow: (state) => state.dialogEditShow,
  },
};

<template>
  <div>
    <div v-if="results && generalInfo" class="">
      <div class="font-weight-600 mr-4">Статус тестирования</div>
      <div v-if="results && generalInfo" class="d-flex align-sm-center flex-column flex-sm-row">
        <div class="badge font-xs mt-2 mt-sm-0 mr-4" :class="testingStatusBadgeClass(generalInfo.test_status_code)">
          {{generalInfo.test_status_title}}
        </div>
        <div class="mt-2 d-flex items-center mt-sm-0 mr-4">
          <v-icon color="primary" class="mr-2" small>mdi-clock-outline</v-icon>
          <span class="mr-4">{{generalInfo.time}}</span>
          <template v-if="generalInfo.test_date && generalInfo.test_date!='None'">
            <v-icon color="primary" class="mr-2" small>mdi-calendar-clock</v-icon>
            <span >{{$moment(generalInfo.test_date).format('DD.MM.YYYY')}}</span>
          </template>
        </div>

        <div class="ml-auto">
          <v-btn v-if="generalInfo.token" class="mx-2 light-btn" outlined fab small :to="`/test/${generalInfo.token}`" target="_blank">
            <img src="@/assets/images/icons/link.svg" alt="Ссылка на тест">
          </v-btn>
          <v-btn v-else class="mx-2 light-btn" outlined fab small >
            <img src="@/assets/images/icons/link-disabled.svg" alt="Ссылка на тест">
          </v-btn>
        </div>
      </div>
    </div>

    <div v-if="generalInfo && generalInfo.tests" class="mt-2 _mt-sm-8">
      <div v-for="test in generalInfo.tests" :key="test.id" class="test-item" :class="{disabled: test.answers_count!== test.questions_count}">
            <div style="width:45%">
              <div class="font-weight-600">{{test.title}}</div>
              <div class="test-time">
                <v-icon class="icon icon-sm mr-1">mdi-clock-outline</v-icon><span class="mr-2">{{test.time}}</span>
                <template v-if="generalInfo.test_date && generalInfo.test_date!='None'">
                  <v-icon class="mr-2" small>mdi-calendar-clock</v-icon>
                  <span >{{$moment(generalInfo.test_date).format('DD.MM.YYYY')}}</span>
                </template>
              </div>
            </div>

            <div class="d-flex" style="width:20%">
              <div class="font-sm _mr-8">
                {{test.answers_count}} / {{ test.questions_count }}
                <div v-if="hasAnswersStatistic(test)">
                  (<span class="success--text">{{test.correct_answers_count}}</span> / <span class="error--text">{{test.answers_count-test.correct_answers_count}}</span>)
                </div>
              </div>
            </div>

            <div v-if="!isProfile" class="pt-0" style="width:35%">
                <div class="text-gray body-2 font-weight-400">{{test.status}}</div>
                <div class="d-flex">
                  <v-tooltip top v-if="$store.getters['auth/isAllowed']('change_testingsession') && results.session &&
                    (test.status_code === TESTING_STATUSES.IN_PROGRESS || test.status_code === TESTING_STATUSES.NOT_COMPLETED)">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-on="on" class="d-inline-block">
                        <v-btn color="primary" class="px-0 mr-2 icon" outlined v-bind="attrs" @click="deleteSession(test)"
                          :loading="removingTest === test.test_code"
                        >
                          <v-icon color="primary">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>Удалить незаконченный тест </span>
                  </v-tooltip>

                  <v-btn v-if="test.status_code === TESTING_STATUSES.NOT_SEND && isTestCodeAllowed(test.test_code)"
                        color="primary" @click="send(test)"
                        :loading="loadingSession.createSession" :disabled="loading.createSession"
                        class="py-1 px-2" outlined>Отправить тест</v-btn>
                  <v-btn v-else-if="test.status_code === TESTING_STATUSES.NOT_COMPLETED && isTestCodeAllowed(test.test_code)"
                        color="primary" @click="send(test)"
                        :loading="loadingSession.createSession" :disabled="loading.createSession"
                        class="py-1 px-2" outlined>Отправить повторно
                  </v-btn>
                </div>
            </div>
      </div>
    </div>
    <MessageDialog v-model="showSessionError" :text="strSessionError" />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { TESTING_STATUSES, TESTING_STATUSES_TITLES } from '@/common/constants/testingStatuses';
import MessageDialog from '@/components/ui/MessageDialog';

export default {
  name: 'ProfileData',
  components: {
    MessageDialog
  },
  props: {
  },
  data: () => ({
    TESTING_STATUSES,
    TESTING_STATUSES_TITLES,
    removingTest: false,
    showSessionError: false,
    strSessionError: ''
  }),
  computed: {
    ...mapGetters({
      loading: 'users/candidate/loading',
      results: 'users/candidate/results',
      isTestCodeAllowed: 'tests/isTestCodeAllowed',
      loadingSession: 'testing/session/loading',
      errorsTestingSession: 'testing/session/errors',
    }),
    isProfile() {
      return this.$route.name === 'profile';
    },
    generalInfo() {
      return this.results?.general_info;
    },
  },
  methods: {
    ...mapActions({
      createTestingSession: 'testing/session/createTestingSession',
      deleteUnusedSession: 'users/candidate/deleteUnusedSession',
      loadCandidateResults: 'users/candidate/loadCandidateResults',
    }),
    ...mapMutations({
    }),
    testingStatusBadgeClass(status) {
      switch (status) {
        case TESTING_STATUSES.COMPLETED: return 'badge-success';
        case TESTING_STATUSES.NOT_COMPLETED: return 'badge-primary';
        case TESTING_STATUSES.IN_PROGRESS: return 'badge-warning';
        case TESTING_STATUSES.NOT_SEND: return 'badge-error';
        case TESTING_STATUSES.NOT_STARTED: return '';
        default: return 'd-none';
      }
    },
    hasAnswersStatistic(test) {
      return test.test_code.toLowerCase().includes('logic');
    },
    async deleteSession(test) {
      const response = await this.deleteUnusedSession({ id: this.results.session, test: test.test_code });
      if (response && response.candidate_testing_status) {
        this.generalInfo.test_status_code = response.candidate_testing_status;
        test.answers_count = 0;
        test.correct_answers_count = 0;
        test.questions_count = 0;
        test.status_code = TESTING_STATUSES.NOT_SEND;
        test.status = TESTING_STATUSES_TITLES.NOT_SEND;
        test.time = '00:00:00';
      }
    },
    send(test) {
      const data = {
        tests: [test.test_code],
        candidate: this.$route.params.id,
      };
      this.createTestingSession(data).then(() => {
        this.loadCandidateResults(this.$route.params.id);
        this.$toast.success('Тест отправлен', '', { position: 'topRight' });
      }).catch(() => {
        // this.$toast.error('Ошибка при отправке теста', '', { position: 'topRight' });
        this.showSessionError = true;
        this.strSessionError = this.errorsTestingSession.createSession
          && this.errorsTestingSession.createSession.length
          && this.errorsTestingSession.createSession[0].length > 1 ? this.errorsTestingSession.createSession[0][1] : 'Произошла ошибка';
      });
    },

  },
};
</script>

<style lang="scss">
@import "src/assets/styles/variables";
.test-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 4px 10px 10px 4px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05), 2px 0px 4px rgba(0, 0, 0, 0.05), -2px 0px 2px rgba(3, 3, 15, 0.05);
  border-radius: 4px;
}

.test-time {
  display: flex;
  align-items: center;
  color: #888;
  font-size: 13px;
}

</style>

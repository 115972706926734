import api from '@/common/api';
/* eslint-disable */

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    delete: false,
  },
  errors: {
    load: [],
    create: [],
    delete: [],
  },
  count: 0,
  items: [],
  filter: {
    page: 1,
    name: '',
  },

});

export default {
  namespaced: true,
  state: initialState,
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    filter: (state) => state.filter,
    items: (state) => state.items,
    filteredItems: (state) => {
      if (!state.filter.name) return state.items;
      const searchName = state.filter.name.toLowerCase();
      return state.items.filter((el) => el.name.toLowerCase().includes(searchName));
    },
    isChapterVisible: state => (chapter, user, checkRepresentatives=true) => {
      if (user.user_type==="Admin")
        return true
      if (!chapter.is_active)
        return false

      if (chapter.clients.includes(user.id))
        return true
      if (checkRepresentatives && chapter.representatives.includes(user.id))
        return true
      // еще надо скрывать, если юзера нет в списках, но это делает бек

      if (user.user_type==="Manager") {
          const customerId = user.customer ? user.customer.id : 0;
          if (chapter.clients.includes(customerId))
            return true
          if (checkRepresentatives && chapter.representatives.includes(customerId))
            return true
      }

      return false
    }
  },
  mutations: {
    loadRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadSuccess(state) {
      state.loading.load = false;
    },
    loadFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    createRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createSuccess(state) {
      state.loading.create = false;
    },
    createFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    deleteRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deleteSuccess(state) {
      state.loading.delete = false;
    },
    deleteFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },

    setCount(state, count) {
      state.count = count;
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setItems(state, items) {
      state.items = items;
    },
    add(state, item) {
      state.items.push(item);
    },
    setFilterPage(state, page) {
      state.filter.page = parseInt(page, 10);
    },
    setFilterName(state, name) {
      state.filter.name = name;
    },
    deleteItem(state, id) {
      const ind = state.items.findIndex((el) => el.id === id);
      if (ind >= 0) state.items.splice(ind, 1);
    },
  },
  actions: {
    async load({ commit }) {
      commit('loadRequest');
      try {
        const response = await api.get('/professiogram/chapter/');
        const items = response.sort((a, b) => a.name.localeCompare(b.name));

        commit('setItems', items);
        commit('setCount', items.length);
        commit('loadSuccess');
      } catch (err) {
        commit('loadFailure', Object.entries(err.data));
      }
    },
    async saveChapter({ commit /* state, dispatch */ }, data) {
      commit('createRequest');
      try {
        let chapter;
        if (!data.id) {
          chapter = await api.post('/professiogram/chapter/', data);
        } else {
          chapter = await api.patch(`/professiogram/chapter/${data.id}/`, data);
        }
        // const chapter = { id: state.items.length + 1, ...data };
        commit('add', chapter);
        commit('createSuccess');
        return chapter;
        // if (getters.filter.page === 1) {
        //   dispatch('load');
        // }
      } catch (err) {
        commit('createFailure', Object.entries(err.data));
      }
    },
    async setFilterPage({ commit, dispatch }, page) {
      commit('setFilterPage', page);
      await dispatch('load');
    },
    async setFilterName({ commit, dispatch }, name) {
      commit('setFilterName', name);
      commit('setFilterPage', 1);
      await dispatch('load');
    },
    async setFilterAll({ commit, dispatch }, params) {
      params = params || {};
      commit('setFilterPage', parseInt(params.page, 10) || 1);
      commit('setFilterName', params.name ?? '');
      await dispatch('load');
    },

    clearError({ commit }) {
      commit('clearError');
    },
    // setSelected({ commit }, id) {
    //   commit('setSelected', id);
    // },
    // allSelected({ commit }) {
    //   commit('allSelected');
    // },
    // clearSelected({ commit }) {
    //   commit('clearSelected');
    // },
    async delete({ commit }, ids) {
      commit('deleteRequest');
      try {
        const promises = ids.map((id) => api.delete(`/professiogram/chapter/${id}/`));
        await Promise.all(promises);
        for (const id of ids) {
          commit('deleteItem', id);
        }

        commit('deleteSuccess');
      } catch (err) {
        commit('deleteFailure', Object.entries(err.data));
      }
    },

  },
};

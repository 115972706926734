/* eslint-disable */
import api from '@/common/api';
import Vue from 'vue';

const initialState = () => ({
  loading: {
    add: false,
  },
  errors: {
    add: [],
  },
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async addLicenses({ commit, getters }, {amount, toType, toId}) {
      const url = getters['getEndpoint'](toType);
      if (!url) {
        console.log(toType);
        return { error: `Начисление тестов для пользователя с ролью ${toType} через фронт не поддерживается` };
      }

      commit('addLicensesRequest');
      const params = { amount }
      params[toType] = +toId

      try {
        const response = await api.post(url, params);

        let getStockUrl = '';
        switch (toType) {
          case 'candidate': getStockUrl = `/users/candidates/${toId}/get-stock-balance/`; break;
          case 'admin': getStockUrl = `/users/admins/${toId}/get-stock-balance/`; break;
          case 'corp_client': getStockUrl = `/users/corp_clients/${toId}/get-stock-balance/`; break;
          case 'manager': getStockUrl = `/users/managers/${toId}/get-stock-balance/`; break;
          case 'partner': getStockUrl = `/users/partners/${toId}/get-stock-balance/`; break;
        }
        console.log(getStockUrl);
        if (getStockUrl) {
          const responseCounter = await api.get(getStockUrl);
          response.stock_balance = responseCounter && responseCounter.stock_balance ? responseCounter.stock_balance : 'Ошибка'
        }

        commit('addLicensesSuccess');
        return response;
      } catch (err) {
        console.log(err)
        commit('addLicensesFailure', Object.entries(err.data));
        return err.data
      }
    },
    clearError({ commit }) {
      commit('clearError');
    },
  },
  mutations: {
    addLicensesRequest(state) {
      state.loading.add = true;
      state.errors.add = [];
    },
    addLicensesSuccess(state) {
      state.loading.add = false;
    },
    addLicensesFailure(state, error) {
      state.errors.add = error;
      state.loading.add = false;
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    getEndpoint: (state, getters, rootState, rootGetters) => (toType) => {
      const user = rootGetters['auth/user'];
      const whoType = user.user_type ? user.user_type.toLowerCase() : ''

      // /counters/admin-to-partner/
      // /counters/corpclient-to-manager/
      // /counters/partner-to-corpclient/
      // /counters/partner-to-manager/
      if (whoType === 'admin' && toType==='partner') return '/counters/admin-to-partner/'
      if (whoType === 'admin' && toType==='manager') return '/counters/transfer-to-manager/'
      if (whoType === 'admin' && toType==='corp_client') return '/counters/transfer-to-corpclient/'
      if (whoType === 'corpclient' && toType==='manager') return '/counters/transfer-to-manager/'
      if (whoType === 'partner' && toType==='corp_client') return '/counters/transfer-to-corpclient/'
      if (whoType === 'partner' && toType==='manager') return '/counters/transfer-to-manager/'

      return false
    }
  },
};

<template>
  <div>
    <h2 v-html="question" class="question__title mb-10"
        :class="{'question__title-font-default': question.length > 150}" />

    <div class="row my-3 align-center" v-for="(answer, ind) in testData.answer_options" :key="answer.id">
      <div class="col-md-6 font-sm notranslate">
        {{ind+1}}. {{answer.title}}
      </div>
      <div class="col-md-6">
        <div class="numbers-buttons" :class="{'numbers-buttons__outlined': answer.points || answer.hoverPoints}">
          <v-btn class="pa-0 numbers-buttons__item" v-for="val in answer.values" :key="val"
                 :class="{active: checkActiveClass(answer, val)}"
                 @click="onClickAnswer(answer, val)"
                 @mouseover="onMouseoverAnswer(answer, val)"
                 @mouseleave="onMouseleaveAnswer(answer)"
                 :color="answer.points || answer.hoverPoints ? 'primary': ''"
                 :outlined="!!answer.points || !!answer.hoverPoints"
                 :elevation="0">
            {{val}}
          </v-btn>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-light font-sm text-right notranslate">
        Осталось {{testData.question.remaining_points}}
        {{$declOfNum(testData.question.remaining_points, 'балл', 'балла', 'баллов')}}
      </div>
    </div>
    <div class="row mt-6">
      <div class="col-md-6 mx-auto">
        <v-btn class="w-100 btn-disabled-primary notranslate" @click="nextStep"
               :elevation="0"
               :disabled="testData.question.remaining_points>0 || loading"
               color="primary"
               large>
           Далее
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProfessionalInterests',
  computed: {
    ...mapGetters({
      testData: 'testing/testing/testData',
      loading: 'testing/testing/loading',
    }),
    question() {
      return `<span>${this.testData.question.question.replace(/\n/g, '<br>')}</span>`;
    },
  },
  methods: {
    ...mapActions({
      nextStep: 'testing/testing/nextStep',
      setAnswer: 'testing/testing/setAnswer',
      sendPointsAnswer: 'testing/testing/sendPointsAnswer',
    }),
    onClickAnswer(answer, value) {
      const remainingValue = this.testData.question.remaining_points;
      const dataAnswer = { ...answer };
      if (dataAnswer.points === value) {
        // если нажали на то же значение, что и уже выбрано, установить значение 0
        dataAnswer.points = 0;
      } else {
        dataAnswer.points = value > dataAnswer.points + remainingValue ? dataAnswer.points + remainingValue : value;
      }
      if (answer.points !== dataAnswer.points) {
        this.sendPointsAnswer(dataAnswer);
      }
    },
    onMouseoverAnswer(answer, value) {
      const remainingValue = this.testData.question.remaining_points;
      const dataAnswer = { ...answer };
      dataAnswer.hoverPoints = value > dataAnswer.points + remainingValue ? dataAnswer.points + remainingValue : value;
      this.setAnswer(dataAnswer);
    },
    onMouseleaveAnswer(answer) {
      this.setAnswer({ ...answer, hoverPoints: answer.points });
    },
    checkActiveClass(answer, val) {
      if (answer.hoverPoints) {
        return val <= answer.hoverPoints;
      }
      return val <= answer.points;
    },
  },
};
</script>

<template>
    <div class="test-from-editor">
        <!-- {{testData}} -->
        <h2 class="question__title mb-6" :class="{'question__compact': question.length>200}" v-html="question"></h2>
        <div>
            <div class="row">
                <div class="col-md-6 py-2" v-for="answer in testData.answer_options" :key="answer.id">
                    <v-btn class="w-100 justify-start answer-button" @click="onClickAnswer(answer)" :elevation="0" :disabled="loading"
                        :color="(selectedAnswer && selectedAnswer.id === answer.id) ? 'primary' : ''"
                        :outlined="selectedAnswer && selectedAnswer.id === answer.id" large>

                        {{ answer.text }}
                    </v-btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'TestSimpleQuestion',
    computed: {
        ...mapGetters({
            testData: 'testing/testing/testData',
            loading: 'testing/testing/loading',
            selectedAnswer: 'testing/testing/selectedAnswer',
        }),
        question() {
            return `<span>${this.testData.question.text}<span>`;
        },
    },
    methods: {
        ...mapActions({
            sendAnswer: 'testing/testing/sendAnswer',
            setSelectedAnswer: 'testing/testing/setSelectedAnswer',
        }),
        onClickAnswer(answer) {
            this.setSelectedAnswer(answer);
            this.sendAnswer();
        },
    },
};
</script>

<style lang="scss">
</style>

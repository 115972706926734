<template>
  <div class="page">
    <div v-if="!user" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="50" color="primary" indeterminate/>
    </div>

    <template v-else-if="user.user_type === CANDIDATE">
      <ProfileCandidate :userId="user.id" />
    </template>

    <template v-else-if="user.user_type === CORPCLIENT">
      <ProfileClient :userId="user.id" />
    </template>

    <template v-else-if="user.user_type === PARTNER">
      <ProfilePartner :userId="user.id" />
    </template>

    <template v-else-if="user.user_type === MANAGER">
      <ProfileManager :userId="user.id" />
    </template>

    <template v-else-if="user.user_type === ADMIN">
      <ProfileAdmin :userId="user.id" />
    </template>

    <!-- <div v-if="selectedTab && user.user_type === CANDIDATE" class="profile-board mt-8 pt-2">
      <ProfileTabs />
      <component v-bind:is="selectedTab.component" />
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  CANDIDATE, CORPCLIENT, PARTNER, MANAGER, ADMIN
} from '@/common/constants/userTypes';
import ProfileCandidate from '@/components/candidates/profile/ProfileCandidate';
import ProfileClient from '@/components/clients/profile/ProfileClient';
import ProfilePartner from '@/components/partners/profile/ProfilePartner';
import ProfileManager from '@/components/managers/profile/ProfileManager';
import ProfileAdmin from '@/components/admins/profile/ProfileAdmin';

export default {
  name: 'Profile',
  components: {
    ProfileCandidate, ProfileClient, ProfilePartner, ProfileManager, ProfileAdmin
  },
  provide: {
    isProfile: true,
  },
  data: () => ({
    CANDIDATE, CORPCLIENT, PARTNER, MANAGER, ADMIN
  }),
  computed: {
    ...mapGetters({
      selectedTab: 'profile/tabs/selectedTab',
      user: 'auth/user',
    }),
  },
  methods: {
    ...mapActions({
      loadCandidateResults: 'users/candidate/loadCandidateResults',
      resetStateCandidate: 'users/candidate/resetState',
      resetStateTabs: 'profile/tabs/resetState',
    }),
  },
  created() {
    if (this.user?.user_type === CANDIDATE) {
      this.loadCandidateResults(this.user.id);
    }
  },
  beforeDestroy() {
    this.resetStateCandidate();
    this.resetStateTabs();
  },
};
</script>

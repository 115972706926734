import api from '@/common/api';

const initialState = () => ({
  loading: {
    load: false,
    create: false,
    edit: false,
    loadResults: false,
    client: false,
    editMail: false,
  },
  errors: {
    load: [],
    create: [],
    loadResults: [],
    client: [],
    edit: [],
  },
  count: 0,
  clients: [],
  client: null,
  selected: [],
  editedClient: null,
  dialogCreateShow: false,
  dialogEditShow: false,
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadClients({ commit }) {
      commit('loadClientsRequest');
      try {
        const response = await api.get('/users/corp_clients/');
        commit('setClients', response);
        commit('setCount', response.length);
        commit('loadClientsSuccess');
      } catch (err) {
        commit('loadClientsFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadClientsResults({ commit, getters }) {
      commit('loadClientsResultsRequest');
      const data = {
        candidates: getters.selected,
      };
      try {
        const response = await api.post('/users/candidates/get-test-result/', data);
        commit('loadClientsResultsSuccess');
        return response;
      } catch (err) {
        commit('loadClientsResultsFailure', Object.entries(err.data));
        throw err;
      }
    },
    async createClient({ commit, dispatch }, data) {
      commit('createClientRequest');
      if (!data.promocode) {
        delete data.promocode;
      }
      try {
        if (data.withManager) {
          data.use_default_promocode = false;
          const response = await api.post('/users/corp_clients/create-with-manager/', data);
          if (response.id && data.promocode) {
            await api.post(`/users/corp_clients/${response.id}/promocodes/`, { promocode: data.promocode });
          }
        } else {
          await api.post('/users/corp_clients/', data);
        }
        commit('createClientSuccess');
        dispatch('loadClients');
      } catch (err) {
        commit('createClientFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadClient({ commit }, id) {
      commit('loadClientRequest');
      try {
        const response = await api.get(`/users/corp_clients/${id}/`);
        commit('loadClientSuccess', response);
      } catch (err) {
        commit('loadClientFailure', Object.entries(err.data));
        throw err;
      }
    },
    async saveClient({ commit, dispatch }, data) {
      commit('editClientRequest');
      try {
        await api.patch(`/users/corp_clients/${data.id}/`, data);
        commit('editClientSuccess');
        dispatch('loadClients');
        dispatch('loadClient', data.id);
      } catch (err) {
        commit('editClientFailure', Object.entries(err.data));
        throw err;
      }
    },
    clearError({ commit }) {
      commit('clearError');
    },
    setSelected({ commit }, id) {
      commit('setSelected', id);
    },
    allSelected({ commit }) {
      commit('allSelected');
    },
    clearSelected({ commit }) {
      commit('clearSelected');
    },
    setDialogCreateShow({ commit }, bool) {
      if (!bool) commit('setEditedClient', null);
      commit('setDialogCreateShow', bool);
    },
    setDialogEditShow({ commit }, bool) {
      if (!bool) commit('setEditedClient', null);
      commit('setDialogEditShow', bool);
    },
    async setClientSendMail({ commit }, data) {
      commit('editMailRequest');
      try {
        const response = await api.patch(`/users/corp_clients/${data.id}/`, data);
        commit('editMailSuccess');
        commit('setSendMail', response);
      } catch (err) {
        commit('editMailFailure', Object.entries(err.data));
        throw err;
      }
    },

  },
  mutations: {
    loadClientsRequest(state) {
      state.loading.load = true;
      state.errors.load = [];
    },
    loadClientsSuccess(state) {
      state.loading.load = false;
    },
    loadClientsFailure(state, error) {
      state.errors.load = error;
      state.loading.load = false;
    },

    loadClientsResultsRequest(state) {
      state.loading.loadResults = true;
      state.errors.loadResults = [];
    },
    loadClientsResultsSuccess(state) {
      state.loading.loadResults = false;
    },
    loadClientsResultsFailure(state, error) {
      state.errors.loadResults = error;
      state.loading.loadResults = false;
    },

    createClientRequest(state) {
      state.loading.create = true;
      state.errors.create = [];
    },
    createClientSuccess(state) {
      state.loading.create = false;
    },
    createClientFailure(state, error) {
      state.errors.create = error;
      state.loading.create = false;
    },

    loadClientRequest(state) {
      state.loading.client = true;
      state.errors.client = [];
    },
    loadClientSuccess(state, client) {
      state.client = client;
      state.loading.client = false;
    },
    loadClientFailure(state, error) {
      state.errors.client = error;
      state.loading.client = false;
    },

    editClientRequest(state) {
      state.loading.edit = true;
      state.errors.edit = [];
    },
    editClientSuccess(state) {
      state.loading.edit = false;
    },
    editClientFailure(state, error) {
      state.errors.edit = error;
      state.loading.edit = false;
    },

    setCount(state, count) {
      state.count = count;
    },
    clearSelected(state) {
      state.selected = [];
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setClients(state, clients) {
      state.clients = clients;
    },
    setSelected(state, id) {
      if (id || id === 0) {
        const itemFound = state.selected.some((item) => item === id);
        if (itemFound) {
          state.selected = state.selected.filter((item) => item !== id);
        } else {
          state.selected.push(id);
        }
      } else {
        state.selected = [];
      }
    },
    allSelected(state) {
      state.clients.forEach((c) => {
        if (!state.selected.some((id) => c.id === id)) {
          state.selected.push(c.id);
        }
      });
    },
    setEditedClient(state, client) {
      state.editedClient = client;
    },
    setDialogCreateShow(state, bool) {
      state.dialogCreateShow = bool;
    },
    setDialogEditShow(state, bool) {
      state.dialogEditShow = bool;
    },

    setSendMail(state, data) {
      state.client.send_mail = data.send_mail;
    },
    editMailRequest(state) {
      state.loading.editMail = true;
      state.errors.edit = [];
    },
    editMailSuccess(state) {
      state.loading.editMail = false;
    },
    editMailFailure(state, error) {
      state.errors.edit = error;
      state.loading.editMail = false;
    },

  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    count: (state) => state.count,
    clients: (state) => state.clients,
    client: (state) => state.client,
    selected: (state) => state.selected,
    editedClient: (state) => state.editedClient,
    dialogCreateShow: (state) => state.dialogCreateShow,
    dialogEditShow: (state) => state.dialogEditShow,

  },
};

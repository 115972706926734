import profs from './profs';
import chapters from './chapters';
import compets from './compets';
import interests from './interests';
import bulkcalc from './bulkcalc';

export default {
  namespaced: true,
  modules: {
    chapters,
    profs,
    compets,
    interests,
    bulkcalc,
  },
};

<template>
  <ProfileCard title="Счетчики менеджеров" link="/managers">

    <!-- <template #extraTitle>
      <v-btn fab class="mb-2" small text _outlined @click="$emit('add')" v-if="enableAdd">
        <v-icon >mdi-pencil-outline</v-icon>
      </v-btn>
    </template> -->
    <div v-if="!managers || !managers.length" class="h-100 d-flex justify-center align-center">
      <v-progress-circular :size="30" color="primary" indeterminate/>
    </div>
    <div class="counters-table" v-if="filteredManagers && filteredManagers.length">
        <div class="counters-table-header counters-table-row">
          <div class="counters-table-col0">Менеджер</div>
          <div class="counters-table-col1">Осталось тестов</div>
        </div>
        <div v-for="m of filteredManagers" :key="m.id" class="counters-table-row">
          <div class="counters-table-col0">{{ m.first_name + ' ' + m.last_name }} </div>
          <div class="counters-table-col1">{{ m.stock_balance ? m.stock_balance : '0' }}</div>
        </div>
      </div>
    </ProfileCard>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ProfileCardManagers',
  components: {
  },
  props: {
    user: Object,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      loading: 'users/managers/loading',
      managers: 'users/managers/managers',
    }),
    filteredManagers() {
      return this.managers.filter(el => el.customer && el.customer.id === this.user.id)
        .sort((a, b) => b.stock_balance - a.stock_balance)
        .slice(0, 3);
    }
  },
  methods: {
    ...mapActions({
      loadManagers: 'users/managers/loadManagers',
    }),
    ...mapMutations({
    }),
  },
  created() {
    this.loadManagers();
  },

};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";

.counters-table {
  width: 100%;
  max-width: 700px;
  // font-size: 13px;
  margin: 0 10px 0px 0px;

  .counters-table-header {
    font-weight: 600;
    padding: 10px 0 !important;
  }
  .counters-table-row {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 8px 0;
    border-bottom: 1px solid #ccc;
  }

  .counters-table-row:last-child {
    border-bottom: none;
  }

  .counters-table-col0 {
    width: 70%;
    // font-weight: 600;
  }

  .counters-table-col1 {
    width: 30%;
    text-align: right;
  }

}

</style>

<template>
  <div>
    <div class="profile-board mb-5">
      <div class="profile-header-first-row">
        <div class="profile-header-row">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" color="primary" class="mr-2 mt-1">mdi-account-circle</v-icon>
            </template>
            <span>{{userTypeName}}</span>
          </v-tooltip>

          <h2 class="page-title mr-8 mt-1">{{name}}</h2>
          <div class="profile-header-item">
            <slot></slot>
          </div>
        </div>
        <div v-if="!isProfile">
          <v-btn v-if="!permssionDelete || $store.getters['auth/isAllowed'](permssionDelete)" class="mx-2 light-btn" outlined fab small @click="dialogDeleteShow=true">
            <img src="@/assets/images/profile/trash.svg">
          </v-btn>
          <v-btn v-if="!permssionEdit || $store.getters['auth/isAllowed'](permssionEdit)" class="mx-2 light-btn" outlined fab small @click="$emit('edit')">
            <img src="@/assets/images/profile/edit.svg">
          </v-btn>

        </div>
      </div>

      <div class="profile-header-row">
        <div v-if="age" class="profile-header-item">
          <img src="@/assets/images/profile/calendar.svg">{{age}}
        </div>
        <div v-if="city" class="profile-header-item">
          <img src="@/assets/images/profile/map.svg">{{city}}
        </div>
        <div v-if="phone" class="profile-header-item">
          <img src="@/assets/images/profile/phone.svg">{{phone | VueMaskFilter('+#-(###)-###-##-##')}}
        </div>
        <div v-if="email" class="profile-header-item">
          <img src="@/assets/images/profile/mail.svg">{{email}}
        </div>
        <div v-if="position" class="profile-header-item">
          <img src="@/assets/images/profile/suitcase.svg">{{position}}
        </div>
        <div v-if="manager" class="profile-header-item">
          <img src="@/assets/images/profile/user.svg">{{manager}}
        </div>
        <div v-if="company" class="profile-header-item">
          <img src="@/assets/images/profile/suitcase.svg">{{company}}
        </div>

      </div>
    </div>

    <!-- <div class="row mt-4">
      <div class="col">
      <v-avatar color="primary" size="40">
        {{ user && user.first_name ? user.first_name[0] : ''}}{{ user && user.last_name ? user.last_name[0] : ''}}
      </v-avatar>
      </div>
      <div class="col">
        <div v-if="isProfile" class="_font-weight-600 _secondary--text">
          {{$store.getters['auth/userRole']}}
        </div>
      </div>
    </div> -->

    <v-dialog v-model="dialogDeleteShow" max-width="600px">
      <v-card class="pa-8 pa-sm-16">
        <h2>Удалить?</h2>
        <div class="mt-6">Вы уверены, что хотите удалить {{whoDelete}}?<br>Действие не может быть отменено.</div>
        <div class="mt-12 d-flex justify-center">
          <v-btn @click="dialogDeleteShow = false" class="px-10" large outlined text>Отмена</v-btn>
          <v-btn @click="onDelete()" class="ml-4 px-10" color="error" large >Удалить</v-btn>
        </div>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { VueMaskFilter, VueMaskDirective } from 'v-mask';

export default {
  name: 'ProfileData',
  components: {
  },
  props: {
    name: String,
    age: String,
    city: String,
    phone: String,
    email: String,
    position: String,
    company: String,
    manager: String,
    permssionEdit: String,
    permssionDelete: String,
    whoDelete: String,
    userTypeName: String,
  },
  directives: {
    mask: VueMaskDirective,
  },
  filters: {
    VueMaskFilter,
  },
  data: () => ({
    dialogDeleteShow: false,
  }),
  computed: {
    isProfile() {
      return this.$route.name === 'profile';
    },
  },
  methods: {
    async onDelete() {
      this.$emit('delete');
      this.dialogDeleteShow = false;
    },
    onEdit() {
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/styles/variables";
</style>

<template>
    <div class="profile-board profile-card">
      <div class="profile-search-top">
        <div class="profile-search-title">
          Поиск промокода
        </div>
        <div v-if="loading.search" class="h-100 d-flex justify-center align-center">
          <v-progress-circular :size="30" color="primary" indeterminate/>
        </div>
        <div v-else class="profile-search-actions">
          <v-text-field
            v-model="code"
            outlined rounded dense hide-details
            label="Поиск"
            append-icon="mdi-magnify"
            @click:append="search()"
            @keyup.enter="search()"
          ></v-text-field>
        </div>
      </div>
      <MessageDialog v-model="showMessage" :text="message" centerText />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MessageDialog from '@/components/ui/MessageDialog';

export default {
  name: 'ProfileCard',
  components: {
    MessageDialog
  },
  props: {
  },
  data: () => ({
    code: '',
    showMessage: false,
    message: '',
  }),
  computed: {
    ...mapGetters({
      loading: 'profile/refs/loading',
    }),
  },
  methods: {
    ...mapActions({
      searchCode: 'profile/refs/search'
    }),
    async search() {
      const ret = await this.searchCode(this.code);
      console.log(ret);
      if (ret && ret.id) {
        let status = 'действующий';
        if (ret.status === 'expired') status = 'истёкший';
        if (ret.status === 'pending') status = 'ожидающий';
        this.message = `Промокод ${ret.promocode} ${status}. <br>Партнер ${ret.managed_by.first_name} ${ret.managed_by.last_name}`;
      } else {
        console.log(ret.promocode);
        this.message = (ret.promocode ? ret.promocode[0] : 0) || 'Промокод не найден';
      }
      this.showMessage = true;
    }
  },
};
</script>

<style lang="scss" scored>
@import "src/assets/styles/variables";

.profile-search-title {
  font-weight: 600;
  font-size: 20px;
  flex-grow: 1;
  line-height: 134.4%;
  color: #25325f;
}

.profile-search-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.profile-search-actions {
    display: flex;
    align-items: center;

}

</style>

/* eslint-disable */

export const Q_TYPES = {
  QUESTON_RADIO: 'radio',
  QUESTON_LIST: 'dropdown',
  QUESTON_CHECKBOX: 'checkbox',
  QUESTON_NUMBER: 'number',
  QUESTON_TEXT: 'text',
  QUESTON_TEXTAREA: 'textarea',
  TEXT_MESSAGE: 'static_element',
}

export const Q_TYPES_NAMES = [
  {
    type: Q_TYPES.QUESTON_RADIO,
    name: 'Закрытый вопрос с вариантами ответа',
    newTemplate: "Вопрос ",
  },
  {
    type: Q_TYPES.QUESTON_LIST,
    name: 'Выбор из списка',
    newTemplate: "Вопрос ",
  },
  // {
  //   type: Q_TYPES.QUESTON_CHECKBOX,
  //   name: 'Выбор нескольких вариантов',
  //   newTemplate: "Вопрос ",
  // },
  // {
  //   type: Q_TYPES.QUESTON_NUMBER,
  //   name: 'Ввод числа',
  //   newTemplate: "Вопрос ",
  // },
  // {
  //   type: Q_TYPES.QUESTON_TEXT,
  //   name: 'Ввод строки',
  //   newTemplate: "Вопрос ",
  // },
  // {
  //   type: Q_TYPES.QUESTON_TEXTAREA,
  //   name: 'Ввод текста',
  //   newTemplate: "Вопрос ",
  // },
  {
    name: 'Текстовое сообщение',
    type: Q_TYPES.TEXT_MESSAGE,
    newTemplate: "Сообщение ",
  },
]

export function getQuestionName(type) {
  const questionType = Q_TYPES_NAMES.find(el => el.type == type)
  return questionType ? questionType.name : 'Тип не найден'
}



import api from '@/common/api';
/* eslint-disable */

const initialState = () => ({
  instrumentSets: [],
  instruments: [],
  processors: [],
  blocks: [],
  questions: [],
  choices: [],
  intervals: [],

  loading: {
    load: false,
    add: false,
    del: false,
    save: false,
    loadItem: false,
  },
  errors: {
    load: [],
    add: [],
    save: [],
    del: [],
  },
});


export default {
  namespaced: true,
  state: initialState,
  getters: {
    instrumentSets: (state) => state.instrumentSets,
    instruments: (state) => state.instruments,
    processors: (state) => state.processors,
    blocks: (state) => state.blocks,
    questions: (state) => state.questions,
    choices: (state) => state.choices,
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    intervals: (state) => state.intervals,
    // intervals: (state) => (block_id) => state.intervals.filter(el => el.block === block_id),
  },
  mutations: {
    setProcessors(state, processors) {
        state.processors = processors;
    },

    setInstrumentSets(state, instrumentSets) {
      state.instrumentSets = instrumentSets;
    },
    addInstrumentSet(state, item) {
        state.instrumentSets.push(item);
    },
    replaceInstrumentSet(state, {id, item}) {
        const ind = state.instrumentSets.findIndex(el => el.id === id)
        if (ind>=0) 
            state.instrumentSets.splice(ind, 1, item);
    },
    delInstrumentSet(state, id) {
        const ind = state.instrumentSets.findIndex(el => el.id === id)
        if (ind>=0) 
            state.instrumentSets.splice(ind, 1);
    },

    setInstruments(state, instruments) {
      state.instruments = instruments;
    },
    addInstrument(state, item) {
        state.instruments.push(item);
    },
    replaceInstrument(state, {id, item}) {
        const ind = state.instruments.findIndex(el => el.id === id)
        if (ind>=0) 
            state.instruments.splice(ind, 1, item);
    },
    delInstrument(state, id) {
        const ind = state.instruments.findIndex(el => el.id === id)
        if (ind>=0) 
            state.instruments.splice(ind, 1);
    },

    setBlocks(state, blocks) {
        state.blocks = blocks;
    },
    addBlock(state, item) {
        state.blocks.push(item);
    },
    replaceBlock(state, {id, item}) {
        const ind = state.blocks.findIndex(el => el.id === id)
        if (ind>=0) 
            state.blocks.splice(ind, 1, item);
    },
    delBlock(state, id) {
        const ind = state.blocks.findIndex(el => el.id === id)
        console.log(id, ind)
        if (ind>=0) 
            state.blocks.splice(ind, 1);
    },

    setQuestions(state, questions) {
        state.questions = questions;
    },
    addQuestion(state, item) {
        state.questions.push(item);
    },
    replaceQuestion(state, {id, item}) {
        const ind = state.questions.findIndex(el => el.id === id)
        if (ind>=0) 
            state.questions.splice(ind, 1, item);
    },
    delQuestion(state, id) {
        const ind = state.questions.findIndex(el => el.id === id)
        if (ind>=0) 
            state.questions.splice(ind, 1);
    },

    setChoices(state, choices) {
      state.choices = choices;
    },
    addChoice(state, item) {
      state.choices.push(item);
    },
    replaceChoice(state, {id, item}) {
      const ind = state.choices.findIndex(el => el.id === id)
      if (ind>=0) 
          state.choices.splice(ind, 1, item);
    },
    delChoice(state, id) {
      const ind = state.choices.findIndex(el => el.id === id)
      if (ind>=0) 
          state.choices.splice(ind, 1);
    },

    setIntervals(state, intervals) {
      state.intervals = intervals;
    },
    addInterval(state, item) {
        state.intervals.push(item);
    },
    replaceInterval(state, {id, item}) {
        const ind = state.intervals.findIndex(el => el.id === id)
        if (ind>=0) 
            state.intervals.splice(ind, 1, item);
    },
    delInterval(state, id) {
        const ind = state.intervals.findIndex(el => el.id === id)
        if (ind>=0) 
            state.intervals.splice(ind, 1);
    },

    startLoading(state, field) {
        state.loading[field] = true;
    },
    startLoadingId(state, { field, id }) {
        state.loading[field] = id;
    },
    endLoading(state, field) {
        state.loading[field] = false;
    },
    clearError(state) {
      state.errors = initialState().errors;
    },
    setError(state, {type, errors}) {
      state.errors[type] = errors;
    },

  },
  actions: {

    async loadProcessors({ commit, state, getters, rootGetters }, ) {
        commit('setProcessors', []);
        commit('startLoading', 'load');
        try {
          const processors = await api.get('/constructor/processors/');
          commit('setProcessors', processors);
        } catch (err) {
            console.log(err);
            commit('setError', { type: 'del', errors: err.data });
        } finally {
          commit('endLoading', 'load');
        }
    },

    // INSTRUMENT SETS 
    async loadInstrumentSets({ commit, state, getters, rootGetters }, ) {
        commit('setInstrumentSets', []);
        commit('startLoading', 'load');
        try {
          const instruments = await api.get('/constructor/instrument_sets/');
          instruments.sort( (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) )
          commit('setInstrumentSets', instruments ?? []);
        } catch (err) {
          console.log(err);
          commit('setError', { type: 'del', errors: err.data });
        } finally {
          commit('endLoading', 'load');
        }
    },
  
    async saveInstrumentSet({ commit, state, dispatch }, instrumentSet) {
        commit('startLoadingId', {field: 'save', id: instrumentSet.id} );
        // commit('setError', { type: 'save', errors: [] });
        try {
          let response = await api.patch(`/constructor/instrument_sets/${instrumentSet.id}/`, instrumentSet)
          if (response)
            commit('replaceInstrumentSet', {id: instrumentSet.id, item: response});
        return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'save', errors: err.data });
        } finally {
          commit('endLoading', 'save');
        }
    },

    async addInstrumentSet({ commit, state, dispatch }, instrumentSet) {
      commit('startLoading', 'add');
      commit('setError', { type: 'add', errors: [] });
      try {
        let response = await api.post(`/constructor/instrument_sets/`, instrumentSet)
        if (response)
          commit('addInstrumentSet', response);
      //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
        return response;
      } catch (err) {
        console.log(err.data);
        commit('setError', { type: 'add', errors: err.data });
      } finally {
        commit('endLoading', 'add');
      }
    },
  
    async delInstrumentSet({ commit, state, dispatch }, instrumentSet) {
        commit('startLoadingId', {field: 'del', id: instrumentSet.id} );
        // commit('setError', { type: 'edit', errors: [] });
        try {
        await api.delete(`/constructor/instrument_sets/${instrumentSet.id}/`)
        commit('delInstrumentSet', instrumentSet.id);
        //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
        } catch (err) {
        console.log(err);
        commit('setError', { type: 'del', errors: err.data });
        } finally {
        commit('endLoading', 'del');
        }
    },


    // INSTRUMENTS 
    async loadInstruments({ commit, state, getters, rootGetters }, ) {
      commit('setInstruments', []);
      commit('startLoading', 'load');
      try {
        const instruments = await api.get('/constructor/instruments/');
        instruments.sort( (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) )
        commit('setInstruments', instruments ?? []);
      } catch (err) {
        console.log(err);
        commit('setError', { type: 'del', errors: err.data });
      } finally {
        commit('endLoading', 'load');
      }
    },

    async loadInstrument({ commit, state, getters, rootGetters }, instrument) {
        commit('setBlocks', []);
        commit('startLoadingId', {field: 'loadItem', id: instrument.id} );
        try {
          const response = await api.get(`/constructor/instruments/${instrument.id}/`);
          if (response) {
            commit('replaceInstrument', {id: instrument.id, item: response});
            commit('setBlocks', response.blocks ?? []);
          }
          return response;
        } catch (err) {
            console.log(err);
            commit('setError', { type: 'del', errors: err.data });
        } finally {
            commit('endLoading', 'loadItem');
        }
    },

    async saveInstrument({ commit, state, dispatch }, instrument) {
        commit('startLoadingId', {field: 'save', id: instrument.id} );
        // commit('setError', { type: 'save', errors: [] });
        try {
          let response = await api.patch(`/constructor/instruments/${instrument.id}/`, instrument)
          if (response)
              commit('replaceInstrument', {id: instrument.id, item: response});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'save', errors: err.data });
        } finally {
          commit('endLoading', 'save');
        }
    },

    async addInstrument({ commit, state, dispatch }, {description}) {
        commit('startLoading', 'add');
        const instrument = {
            name: 'Опросник '+(state.instruments.length+1),
            description,
            type: "questionnaire",
            is_random_ordered: false,
            is_included_in_report: true,
        }        
        // commit('setError', { type: 'add', errors: [] });
        try {
          let response = await api.post(`/constructor/instruments/`, instrument)
          if (response)
              commit('addInstrument', response);
          //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'add', errors: err.data });
        } finally {
          commit('endLoading', 'add');
        }
    },
    
    async delInstrument({ commit, state, dispatch }, instrument) {
        commit('startLoadingId', {field: 'del', id: instrument.id} );
        // commit('setError', { type: 'edit', errors: [] });
        try {
          await api.delete(`/constructor/instruments/${instrument.id}/`)
          commit('delInstrument', instrument.id);
          //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
        } catch (err) {
          console.log(err);
          commit('setError', { type: 'del', errors: err.data });
        } finally {
          commit('endLoading', 'del');
        }
    },
  
    // BLOCKS
    async loadAllBlocks({ commit, state, getters, rootGetters }, ) {
        commit('setBlocks', []);
        commit('startLoading', 'load');
        try {
          const blocks = await api.get('/constructor/blocks/');
          commit('setBlocks', blocks);
        } catch (err) {
            console.log(err);
            commit('setError', { type: 'del', errors: err.data });
        } finally {
            commit('endLoading', 'load');
        }
    },
  
    async loadBlock({ commit, state, getters, rootGetters }, block) {
        commit('setQuestions', []);
        commit('startLoadingId', {field: 'loadItem', id: block.id} );
        try {
          const response = await api.get(`/constructor/blocks/${block.id}/`);
          if (response) {
            commit('replaceBlock', {id: block.id, item: response});
            commit('setQuestions', response.questions ?? []);
          }
          return response
        } catch (err) {
            console.log(err);
            commit('setError', { type: 'del', errors: err.data });
        } finally {
            commit('endLoading', 'loadItem');
        }
    },

    async saveBlock({ commit, state, dispatch }, block) {
        commit('startLoadingId', {field: 'save', id: block.id} );
        // commit('setError', { type: 'save', errors: [] });
        try {
          let response = await api.patch(`/constructor/blocks/${block.id}/`, block)
          if (response)
              commit('replaceBlock', {id: block.id, item: response});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'save', errors: err.data });
        } finally {
          commit('endLoading', 'save');
        }
    },

    async addBlock({ commit, state, dispatch }, {instrument}) {
        commit('startLoading', 'add');
        const block = {
            title: 'Блок вопросов '+(state.blocks.length+1),
            // processor: {
            //     name: "string",
            //     description: "string",
            //     function: "sum"
            // },
            description: "",
            order: 0,
            is_random_ordered: false,
            is_answer_random_ordered: false,
            instrument: instrument.id,
            // show_condition: 0
        }        
        // commit('setError', { type: 'add', errors: [] });
        try {
          let response = await api.post(`/constructor/blocks/`, block)
          if (response)
              commit('addBlock', response);
          //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'add', errors: err.data });
        } finally {
          commit('endLoading', 'add');
        }
    },
    
    async delBlock({ commit, state, dispatch }, block) {
        commit('startLoadingId', {field: 'del', id: block.id} );
        // commit('setError', { type: 'edit', errors: [] });
        try {
          await api.delete(`/constructor/blocks/${block.id}/`)
          commit('delBlock', block.id);
        } catch (err) {
          console.log(err);
          commit('setError', { type: 'del', errors: err.data });
        } finally {
          commit('endLoading', 'del');
        }
    },

    // QUESTIONS
    async loadQuestion({ commit, state, getters, rootGetters }, question) {
        commit('setChoices', []);
        commit('startLoadingId', {field: 'loadItem', id: question.id} );
        try {
          const response = await api.get(`/constructor/questions/${question.id}/`);
          if (response) {
            commit('replaceQuestion', {id: question.id, item: response});
            commit('setChoices', response.choices ?? []);
          }
          return response
        } catch (err) {
            console.log(err);
            commit('setError', { type: 'del', errors: err.data });
        } finally {
            commit('endLoading', 'loadItem');
        }
    },
    async saveQuestion({ commit, state, dispatch }, question) {
        commit('startLoadingId', {field: 'save', id: question.id} );
        // commit('setError', { type: 'save', errors: [] });
        try {
          let response = await api.patch(`/constructor/questions/${question.id}/`, question)
          if (response)
              commit('replaceQuestion', {id: question.id, item: response});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'save', errors: err.data });
        } finally {
          commit('endLoading', 'save');
        }
    },

    async addQuestion({ commit, state, dispatch }, {block, type, newTemplate}) {
        commit('startLoading', 'add');
        const question = {
            text: newTemplate+(state.questions.length+1),
            order: 0,
            type,
            choices_number_to_select: 0,
            block: block.id,
            // "show_condition": 0            
        }        
        // commit('setError', { type: 'add', errors: [] });
        try {
          let response = await api.post(`/constructor/questions/`, question)
          if (response)
              commit('addQuestion', response);
          //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'add', errors: err.data });
        } finally {
          commit('endLoading', 'add');
        }
    },
    
    async delQuestion({ commit, state, dispatch }, question) {
        commit('startLoadingId', {field: 'del', id: question.id} );
        // commit('setError', { type: 'edit', errors: [] });
        try {
          await api.delete(`/constructor/questions/${question.id}/`)
          commit('delQuestion', question.id);
        } catch (err) {
          console.log(err);
          commit('setError', { type: 'del', errors: err.data });
        } finally {
          commit('endLoading', 'del');
        }
    },

    // CHOICES
    async saveChoice({ commit, state, dispatch }, choice) {
        commit('startLoadingId', {field: 'save', id: choice.id} );
        // commit('setError', { type: 'save', errors: [] });
        try {
          let response = await api.patch(`/constructor/choices/${choice.id}/`, choice)
          if (response)
              commit('replaceChoice', {id: choice.id, item: response});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'save', errors: err.data });
        } finally {
          commit('endLoading', 'save');
        }
    },

    async addChoice({ commit, state, dispatch }, {question}) {
        commit('startLoading', 'add');
        const choice = {
            text: "Ответ "+(state.choices.length+1),
            order: 0,
            points: 0,
            question: question.id,
        }        
        // commit('setError', { type: 'add', errors: [] });
        try {
          let response = await api.post(`/constructor/choices/`, choice)
          if (response)
              commit('addChoice', response);
          //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
          return response;
        } catch (err) {
          console.log(err.data);
          commit('setError', { type: 'add', errors: err.data });
        } finally {
          commit('endLoading', 'add');
        }
    },
    
    async delChoice({ commit, state, dispatch }, choice) {
        commit('startLoadingId', {field: 'del', id: choice.id} );
        // commit('setError', { type: 'edit', errors: [] });
        try {
          await api.delete(`/constructor/choices/${choice.id}/`)
          commit('delChoice', choice.id);
        } catch (err) {
          console.log(err);
          commit('setError', { type: 'del', errors: err.data });
        } finally {
          commit('endLoading', 'del');
        }
    },


    // INTERVALS
    async loadIntervals({ commit, state, getters, rootGetters }, ) {
      commit('setIntervals', []);
      commit('startLoading', 'load');
      try {
        const intervals = await api.get('/constructor/scale_intervals/');
        commit('setIntervals', intervals ?? []);
      } catch (err) {
        console.log(err);
        commit('setError', { type: 'load', errors: err.data });
      } finally {
        commit('endLoading', 'load');
      }
  },

  async saveInterval({ commit, state, dispatch }, interval) {
      commit('startLoadingId', {field: 'save', id: interval.id} );
      // commit('setError', { type: 'save', errors: [] });
      try {
        let response = await api.patch(`/constructor/scale_intervals/${interval.id}/`, interval)
        if (response)
          commit('replaceInterval', {id: interval.id, item: response});
        return response;
      } catch (err) {
        console.log(err.data);
        commit('setError', { type: 'save', errors: err.data });
      } finally {
        commit('endLoading', 'save');
      }
  },

  async addInterval({ commit, state, dispatch }, interval) {
    commit('startLoading', 'add');
    commit('setError', { type: 'add', errors: [] });
    try {
      let response = await api.post(`/constructor/scale_intervals/`, interval)
      if (response)
        commit('addInterval', response);
      return response;
    } catch (err) {
      console.log(err.data);
      commit('setError', { type: 'add', errors: err.data });
    } finally {
      commit('endLoading', 'add');
    }
  },

  async delInterval({ commit, state, dispatch }, interval) {
      commit('startLoadingId', {field: 'del', id: interval.id} );
      // commit('setError', { type: 'edit', errors: [] });
      try {
        await api.delete(`/constructor/scale_intervals/${interval.id}/`)
        commit('delInterval', interval.id);
        //   dispatch('loadCodes', {id: state.user_id, user_type: state.user_type});
      } catch (err) {
        console.log(err);
        commit('setError', { type: 'del', errors: err.data });
      } finally {
        commit('endLoading', 'del');
      }
  },


  },

};

import api from '@/common/api';
import Vue from 'vue';
import psychoTypesMocked from '@/store/psychotypes.js';

const initialState = () => ({
  loading: {
    candidate: false,
    results: false,
    delete: false,
    edit: false,
    loadPdf: false,
    pdf: {
      index: false,
      short: false,
      constructor: false,
      psycho: false,
      reco: false,
      checklist: false
    },
    recomendations: false,
    checkList: false,
    recomendationsRefresh: false,
    checkListRefresh: false,
    removingTest: false,
    editMail: false,
    calcCompliance: false,
  },
  errors: {
    candidate: [],
    results: [],
    delete: [],
    edit: [],
    loadPdf: [],
    recomendations: [],
    checkList: false,
  },
  candidate: null,
  results: null,
  resultsPsycho: null,
  recomendations: null,
  checkList: null,
  psychoTypes: psychoTypesMocked,
  removingTest: '',
});

export default {
  namespaced: true,
  state: initialState,
  actions: {
    async loadCandidate({ commit }, id) {
      commit('loadCandidateRequest');
      try {
        const response = await api.get(`/users/candidates/${id}/`);
        commit('loadCandidateSuccess', response);
        return response;
      } catch (err) {
        commit('loadCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async loadCandidateCompliance({ commit }, id) {
      try {
        commit('setCalculating', true);
        const reportSetup = await api.get(`/professiogram/candidates/${id}/get-report-setup/`);
        if (reportSetup && reportSetup.pg_setup) {
          const resp = await api.post(`/professiogram/candidates/${id}/get-compliances/`, {
            compliances: reportSetup?.pg_setup?.compliance
          });
          commit('users/candidate/setCandidateComplianceResult', resp.compliances, { root: true });
        }
      } catch (err) {
        console.log(err);
      }
      commit('setCalculating', false);
    },
    async loadCandidateResults({ commit, dispatch }, id) {
      commit('loadCandidateResultsRequest');
      try {
        const response = await api.get(`/users/candidates/${id}/get-test-result/`);
        // временно, пока бек не присылает посчитанный Compliance
        dispatch('loadCandidateCompliance', id);
        commit('loadCandidateResultsSuccess', response);
        dispatch('profile/tabs/setTabs', response, { root: true });
      } catch (err) {
        commit('loadCandidateResultsFailure', Object.entries(err.data));
      }

      try {
        const responsePsycho = await api.get(`/users/candidates/${id}/get-psychotest-result/`);
        commit('loadCandidatePsychoResultsSuccess', responsePsycho);
      } catch (err) {
        console.log(err);
      }

      try {
        let arrPS = await api.get('/psychotypes/get-psychotype/');
        arrPS = arrPS.map((el) => ({ ...el, code: el.code_name }));
        commit('loadCandidatePsychoTypesResultsSuccess', arrPS);
      } catch (err) {
        console.log(err);
      }
    },

    async loadCandidateCheckList({ commit }, payload) {
      commit('loadCandidateCheckListRequest');
      try {
        const response = await api.post('/recommendations/get-for-factors/', payload);
        commit('loadCandidateCheckListSuccess', response);
        return response;
      } catch (err) {
        commit('loadCandidateCheckListFailure', Object.entries(err.data));
        // throw err;
      }
    },

    async loadCandidateRecomendations({ commit }, payload) {
      commit('loadCandidateRecomendationsRequest');
      try {
        const response = await api.post('/recommendations/get-for-psychotype/', payload);
        console.log(response);
        commit('loadCandidateRecomendationsSuccess', response);
        return response;
      } catch (err) {
        commit('loadCandidateRecomendationsFailure', Object.entries(err.data));
        // throw err;
      }
    },

    async refreshCandidateRecomendations({ commit }, payload) {
      commit('refreshCandidateRecomendationsRequest');
      try {
        const response = await api.post('/recommendations/refresh-for-psychotype/', payload);
        commit('refreshCandidateRecomendationsSuccess', response);
        return response;
      } catch (err) {
        commit('refreshCandidateRecomendationsFailure', err.data);
        // throw err;
      }
    },

    async refreshCandidateCheckList({ commit }, payload) {
      commit('refreshCandidateCheckListRequest');
      try {
        const response = await api.post('/recommendations/refresh-for-factors/', payload);
        commit('refreshCandidateCheckListSuccess', response);
        return response;
      } catch (err) {
        commit('refreshCandidateCheckListFailure', err.data);
        // throw err;
      }
    },

    async deleteCandidate({ getters, commit }) {
      commit('deleteCandidateRequest');
      try {
        await api.delete(`/users/candidates/${getters.candidate.id}/`);
        commit('deleteCandidateSuccess');
      } catch (err) {
        commit('deleteCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },
    async editCandidate({ commit }) {
      commit('editCandidateRequest');
      try {
        // await api.delete(`/users/candidates/${getters.candidate.id}/`);
        commit('editCandidateSuccess');
      } catch (err) {
        commit('editCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },

    async editRecomendation({ commit }, payload) {
      commit('editCandidateRequest');
      try {
        const response = await api.patch(`/recommendations/recommendations/update/${payload.id}`, { title: payload.title, text: payload.text });
        commit('editCandidateSuccess');
        return response;
      } catch (err) {
        commit('editCandidateFailure', Object.entries(err.data));
      }
    },

    async editCheckList({ commit }, payload) {
      commit('editCandidateRequest');
      try {
        const response = await api.patch(`/recommendations/checklist/update/${payload.id}`, { title: payload.title, text: payload.text });
        commit('editCandidateSuccess');
        return response;
      } catch (err) {
        commit('editCandidateFailure', Object.entries(err.data));
      }
    },

    async setCandidateActive({ commit }, data) {
      commit('editCandidateRequest');
      try {
        const response = await api.patch(`/users/candidates/${data.id}/`, data);
        commit('editCandidateSuccess');
        commit('setCandidateActive', response);
      } catch (err) {
        commit('editCandidateFailure', Object.entries(err.data));
        throw err;
      }
    },

    async setCandidateSendMail({ commit }, data) {
      commit('editCandidateMailRequest');
      try {
        const response = await api.patch(`/users/candidates/${data.id}/`, data);
        commit('editCandidateMailSuccess');
        commit('setCandidateSendMail', response);
      } catch (err) {
        commit('editCandidateMailFailure', Object.entries(err.data));
        throw err;
      }
    },

    async deleteUnusedSession({ commit }, { id, test }) {
      commit('deleteSessionRequest');
      try {
        const response = await api.patch(`/testing-session/${id}/test-removing/`, { tests: [test] });
        commit('deleteSessionSuccess');
        return response;
      } catch (err) {
        commit('deleteSessionFailure', Object.entries(err.data));
        throw err;
      }
    },

    async loadCandidatePdf({ commit }, data) {
      commit('loadCandidatePdfRequest', data.report_type);
      try {
        const response = await api.post('/users/candidates/download-pdf/', data);
        commit('loadCandidatePdfSuccess', data.report_type);
        return response;
      } catch (err) {
        commit('loadCandidatePdfFailure', { type: data.report_type, errors: Object.entries(err.data) });
        throw err;
      }
    },

    async loadCandidatePsychoPdf({ commit }, data) {
      commit('loadCandidatePdfRequest', 'psycho');
      try {
        const response = await api.post('/users/candidates/download-psycho-pdf/', data);
        commit('loadCandidatePdfSuccess', 'psycho');
        return response;
      } catch (err) {
        commit('loadCandidatePdfFailure', { type: 'psycho', errors: Object.entries(err.data) });
        throw err;
      }
    },

    async loadCandidateRecomendationsPdf({ commit }, data) {
      commit('loadCandidatePdfRequest', 'reco');
      try {
        const response = await api.post('/users/candidates/download-recommendations-pdf/', data);
        commit('loadCandidatePdfSuccess', 'reco');
        return response;
      } catch (err) {
        commit('loadCandidatePdfFailure', { type: 'reco', errors: Object.entries(err.data) });
        throw err;
      }
    },

    async loadCandidateCheckListPdf({ commit }, data) {
      commit('loadCandidatePdfRequest', 'checklist');
      try {
        const response = await api.post('/users/candidates/download-checklist-pdf/', data);
        commit('loadCandidatePdfSuccess', 'checklist');
        return response;
      } catch (err) {
        commit('loadCandidatePdfFailure', { type: 'checklist', errors: Object.entries(err.data) });
        throw err;
      }
    },

    resetState({ commit }) {
      commit('resetState');
    },
  },
  mutations: {
    setCandidateActive(state, data) {
      state.candidate.is_active = data.is_active;
    },
    setCandidateSendMail(state, data) {
      state.candidate.send_mail = data.send_mail;
    },
    loadCandidateRequest(state) {
      state.loading.candidate = true;
      state.errors.candidate = [];
    },
    loadCandidateSuccess(state, candidate) {
      state.candidate = candidate;
      state.loading.candidate = false;
    },
    setCandidateComplianceResult(state, compliance) {
      console.log(compliance);
      Vue.set(state.results, 'compliances', compliance);
    },
    loadCandidateFailure(state, error) {
      state.errors.candidate = error;
      state.loading.candidate = false;
    },
    loadCandidateResultsRequest(state) {
      state.results = [];
      state.loading.results = true;
      state.errors.results = [];
    },
    loadCandidateResultsSuccess(state, results) {
      state.results = results;
      state.loading.results = false;
    },
    loadCandidatePsychoResultsSuccess(state, resultsPsycho) {
      state.resultsPsycho = resultsPsycho;
    },
    loadCandidatePsychoTypesResultsSuccess(state, arrPS) {
      state.psychoTypes = arrPS;
    },
    loadCandidateResultsFailure(state, error) {
      state.errors.results = error;
      state.loading.results = false;
    },

    deleteSessionRequest(state, test) {
      state.loading.removingTest = true;
      state.removingTest = test;
    },
    deleteSessionSuccess(state) {
      state.loading.removingTest = false;
      state.removingTest = '';
    },
    deleteSessionFailure(state) {
      state.loading.removingTest = false;
      state.removingTest = '';
    },

    loadCandidateRecomendationsRequest(state) {
      state.recomendations = [];
      state.loading.recomendations = true;
      state.errors.recomendations = [];
    },
    loadCandidateRecomendationsSuccess(state, response) {
      state.recomendations = response;
      state.loading.recomendations = false;
    },
    loadCandidateRecomendationsFailure(state, error) {
      state.errors.recomendations = error;
      state.loading.recomendations = false;
    },

    refreshCandidateRecomendationsRequest(state) {
      state.loading.recomendationsRefresh = true;
      state.errors.recomendations = [];
    },
    refreshCandidateRecomendationsSuccess(state, response) {
      state.recomendations = response;
      state.loading.recomendationsRefresh = false;
    },
    refreshCandidateRecomendationsFailure(state, error) {
      state.errors.recomendations = error;
      state.loading.recomendationsRefresh = false;
    },

    loadCandidateCheckListRequest(state) {
      state.checkList = [];
      state.loading.checkList = true;
      state.errors.checkList = [];
    },
    loadCandidateCheckListSuccess(state, response) {
      state.checkList = response;
      state.loading.checkList = false;
    },
    loadCandidateCheckListFailure(state, error) {
      state.errors.checkList = error;
      state.loading.checkList = false;
    },

    refreshCandidateCheckListRequest(state) {
      state.loading.checkListRefresh = true;
      state.errors.checkList = [];
    },
    refreshCandidateCheckListSuccess(state, response) {
      state.checkList = response;
      state.loading.checkListRefresh = false;
    },
    refreshCandidateCheckListFailure(state, error) {
      state.errors.checkList = error;
      state.loading.checkListRefresh = false;
    },

    deleteCandidateRequest(state) {
      state.loading.delete = true;
      state.errors.delete = [];
    },
    deleteCandidateSuccess(state) {
      state.loading.delete = false;
      state.candidate = null;
      state.results = null;
    },
    deleteCandidateFailure(state, error) {
      state.errors.delete = error;
      state.loading.delete = false;
    },

    editCandidateRequest(state) {
      state.loading.edit = true;
      state.errors.edit = [];
    },
    editCandidateSuccess(state) {
      state.loading.edit = false;
    },
    editCandidateFailure(state, error) {
      state.errors.edit = error;
      state.loading.edit = false;
    },

    editCandidateMailRequest(state) {
      state.loading.editMail = true;
      state.errors.edit = [];
    },
    editCandidateMailSuccess(state) {
      state.loading.editMail = false;
    },
    editCandidateMailFailure(state, error) {
      state.errors.edit = error;
      state.loading.editMail = false;
    },

    loadCandidatePdfRequest(state, type) {
      state.loading.pdf[type] = true;
      state.errors.loadPdf = [];
    },
    loadCandidatePdfSuccess(state, type) {
      state.loading.pdf[type] = false;
    },
    loadCandidatePdfFailure(state, { errors, type }) {
      state.errors.loadPdf = errors;
      state.loading.pdf[type] = false;
    },
    setCalculating(state, calculating) {
      state.loading.calcCompliance = calculating;
    },

    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach((key) => { state[key] = initial[key]; });
    },
  },
  getters: {
    loading: (state) => state.loading,
    errors: (state) => state.errors,
    candidate: (state) => state.candidate,
    results: (state) => state.results,
    resultsPsycho: (state) => state.resultsPsycho,
    psychoTypes: (state) => state.psychoTypes,
    recomendations: (state) => state.recomendations,
    checkList: (state) => state.checkList,
    removingTest: (state) => state.removingTest,
  },
};

import Vue from 'vue';
import Ripple from 'vuetify/lib/directives/ripple';
import VueIziToast from 'vue-izitoast';
// import VueAwesomeSwiper from 'vue-awesome-swiper';
// import VueYandexMetrika from 'vue-yandex-metrika';

import BackBtn from '@/components/common/BackBtn';
import ProfileCard from '@/components/common/ProfileCard';
import BreadCrumbs from '@/components/ui/BreadCrumbs';

import App from './App.vue';
import router from './router';
import store from './store';

import './plugins/moment';
import './plugins/declOfNum';
import './plugins/vuelidate';
import './plugins/filters';
import './plugins/vue-meta';
import vuetify from './plugins/vuetify';
import '@/assets/styles/main.scss';

// import 'swiper/css/swiper.css';
// eslint-disable-next-line
import 'izitoast/dist/css/iziToast.min.css';

Vue.component('BackBtn', BackBtn);
Vue.component('ProfileCard', ProfileCard);
Vue.component('BreadCrumbs', BreadCrumbs);

Vue.use(vuetify, {
  directives: {
    Ripple,
  },
});

// Vue.use(VueAwesomeSwiper);
Vue.use(VueIziToast);
// Vue.use(VueYandexMetrika, {
//   id: 91467607,
//   router,
//   env: process.env.NODE_ENV
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

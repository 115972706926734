<template>
  <div class="access">
    <BackBtn />
    <v-icon color="red" class="my-8" x-large>mdi-security</v-icon>
    <h3>У вас нет прав на просмотр этой страницы</h3>
  </div>
</template>

<script>
export default {
  name: 'access',
  mounted() {
    // window.location = '/welcome/';
  },
};
</script>

<style scoped>
.access {
  /* display: flex; */
  margin-top: 50px;
  align-items: center;
  text-align: center;
}
</style>
